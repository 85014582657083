import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {LS_ASIDE_NAV} from '../../Constants/LSConstants';

interface IAsideState {
  isOpen :boolean;
}

const initialState :IAsideState = {
  isOpen: !!localStorage.getItem(LS_ASIDE_NAV) ? localStorage.getItem(LS_ASIDE_NAV) === 'true' : true
};

export const asideSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    toggleAside(state, action :PayloadAction<boolean>) {
      state.isOpen = action.payload;
      localStorage.setItem(LS_ASIDE_NAV, String(action.payload));
    },
  }
});

export const asideActions = asideSlice.actions;
export const asideReducer = asideSlice.reducer;