import React from 'react';
import {BasicOrderFormRow, BasicOrderFormRowLabel, BasicOrderFormRowTitle} from '../../../../../Styles/newOrdersBasics';
import HelpButton from '../../../../../Components/HelpButton';
import {ApplicationSelector} from '../../../../../../../Components/Selectors';
import {useAppSelector} from '../../../../../../../Hooks/redux/useAppSelector';
import {BasicRedAccentSpan} from '../../../../../../../Theme/basicElements';

interface IApplicationSelectorRowProps {
  handleSetOrderApp: (app: number) => void;
}

const ApplicationSelectorRow: React.FC<IApplicationSelectorRowProps> = ({handleSetOrderApp}) => {
  const {app, activeScenario} = useAppSelector(state => state.newOrderSlice);

  return (
    <BasicOrderFormRow>
      <BasicOrderFormRowLabel>
        <BasicOrderFormRowTitle>Application <BasicRedAccentSpan>*</BasicRedAccentSpan></BasicOrderFormRowTitle>
        <HelpButton popupText={'Select an application'}/>
      </BasicOrderFormRowLabel>
      <ApplicationSelector
        size={'sm'}
        placeholder={'Application'}
        selectedApplication={app}
        handleSelectApplication={handleSetOrderApp}
        disabled={activeScenario === 'promote' || activeScenario === 'duplicate' || activeScenario === 'extend'}
      />
    </BasicOrderFormRow>
  );
};

export default ApplicationSelectorRow;