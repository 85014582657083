import React, {useCallback, useEffect, useRef, useState} from 'react';
import {SelectBlock, SelectItem, SelectList, StyledSelectInput, StyledSelectInputLabel} from '../Styles';
import {useGetAppsQuery} from '../../../Store/APIs/Apps.api';
import useDebounce from '../../../Hooks/useDebounce/useDebounce';
import ClearInputButton from '../../../UI/ClearInputButton';
import ApplicationStoreIcon from '../../../UI/icons/ApplicationStoreIcon';
import {throwNewToastError} from '../../../Utils/MainUtils';

interface IApplicationSelectorProps {
  size: 'sm' | 'lg' | 'md';
  placeholder: string;
  selectedApplication: number;
  handleSelectApplication: (appId: number) => void;
  disabled: boolean;
  inError?: boolean;
}

const ApplicationSelector: React.FC<IApplicationSelectorProps> = ({
                                                                    size,
                                                                    placeholder,
                                                                    selectedApplication,
                                                                    handleSelectApplication,
                                                                    disabled,
                                                                    inError
                                                                  }) => {
  const selectRef = useRef<HTMLInputElement>(null);
  const optionsListRef = useRef<HTMLDivElement>(null);
  const selectedItemRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [openOptionsList, setOpenOptionsList] = useState(false);
  const [hoveredApplication, setHoveredApplication] = useState(0);
  const [searchFieldValue, setSearchFieldValue] = useState('');

  const debouncedSearchValue = useDebounce(searchFieldValue, 500);

  const [listIsFocused, setListIsFocused] = useState(false);


  // todo оптимизировать
  const {data: allApplicationsList = []} = useGetAppsQuery({
    limit: 999,
    offset: 0,
    search: null
  });

  const {data: filteredApplicationsList = []} = useGetAppsQuery({
    limit: 999,
    offset: 0,
    search: debouncedSearchValue
  });

  useEffect(() => {
    if (allApplicationsList.length && selectedApplication) {
      const currentApp = allApplicationsList.find((app) => app.id === selectedApplication);

      currentApp && setSearchFieldValue(currentApp.name);
    }
  }, [selectedApplication, allApplicationsList]);

  useEffect(() => {
    if (!selectedApplication) {
      setSearchFieldValue('');
    }
  }, [selectedApplication]);

  useEffect(() => {
    if (optionsListRef.current && selectedItemRef.current) {
      const parentRect = optionsListRef.current.getBoundingClientRect();
      const childrenRect = selectedItemRef.current.getBoundingClientRect();

      if (childrenRect.bottom > parentRect.bottom) {
        // Если элемент находится ниже видимой области списка, прокручиваем вниз
        optionsListRef.current.scrollTop += childrenRect.bottom - parentRect.bottom;
      } else if (childrenRect.top < parentRect.top) {
        // Если элемент находится выше видимой области списка, прокручиваем вверх
        optionsListRef.current.scrollTop -= parentRect.top - childrenRect.top;
      }
    }
  }, [hoveredApplication]);

  useEffect(() => {
    const handleCloseList = (event: MouseEvent) => {
      const {target} = event;
      if (target instanceof Node && !selectRef.current?.contains(target)) {
        setOpenOptionsList(false);
      }
    };

    window.addEventListener('click', handleCloseList);

    return () => window.removeEventListener('click', handleCloseList);
  }, []);

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === 'ArrowUp') {
      event.preventDefault();
      if (hoveredApplication === 0) {
        setHoveredApplication(filteredApplicationsList.length - 1);
      } else {
        setHoveredApplication((prevIndex) => prevIndex - 1);
      }
    }
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      if (hoveredApplication === filteredApplicationsList.length - 1) {
        setHoveredApplication(0);
      } else {
        setHoveredApplication((prevIndex) => prevIndex + 1);
      }
    }
    if (event.key === 'Enter') {
      event.preventDefault();
      if (!filteredApplicationsList[hoveredApplication].name.includes('banned')) {
        handleSelectApplication(filteredApplicationsList[hoveredApplication].id);
      } else {
        throwNewToastError('Application has been banned');
      }
      setHoveredApplication(0);
      setOpenOptionsList(false);
      handleDropInputFocus();
    }
  }, [filteredApplicationsList, handleSelectApplication, hoveredApplication]);

  useEffect(() => {
    if (openOptionsList) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };

  }, [openOptionsList, handleKeyDown]);

  const handleClearInputField = () => {
    handleSelectApplication(0);
    setSearchFieldValue('');
  };

  const handleChangeSearchFieldValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFieldValue(event.target.value);

    if (event.target.value === '') {
      handleSelectApplication(0);
    }
  };

  const handleDropInputFocus = () => {
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  return (
    <SelectBlock data-size={size} ref={selectRef}>
      <StyledSelectInputLabel data-list-is-open={openOptionsList}>
        <StyledSelectInput
          ref={inputRef}
          value={searchFieldValue}
          placeholder={placeholder}
          onChange={handleChangeSearchFieldValue}
          data-in-error={inError}
          disabled={disabled}
          data-list-is-open={openOptionsList && filteredApplicationsList.length > 0}
          onMouseDown={() => {
            setListIsFocused(!listIsFocused);
            setOpenOptionsList(!openOptionsList);
          }}
          onFocus={() => {
            setListIsFocused(true);
            setOpenOptionsList(true);
          }}
          onBlur={() => {
            setListIsFocused(false);
            setOpenOptionsList(false);
          }}
        />
        {(searchFieldValue && !disabled) && <ClearInputButton onClick={handleClearInputField} fieldType={'select'}/>}
      </StyledSelectInputLabel>
      {!!(openOptionsList && filteredApplicationsList.length) &&
        <SelectList data-is-focused={listIsFocused} ref={optionsListRef}>
          {filteredApplicationsList.map((application, index) => (
            <SelectItem
              ref={(hoveredApplication === index) ? selectedItemRef : null}
              data-active={hoveredApplication === index}
              key={application.id}
              onMouseDown={() => {
                if (!application.name.includes('banned')) {
                  handleDropInputFocus();
                  setOpenOptionsList(false);
                  handleSelectApplication(application.id);
                } else {
                  throwNewToastError('Application has been banned');
                }
              }}
            >
              <ApplicationStoreIcon store={application.url.includes('apple') ? 'apple' : 'google'}/>
              {application.name}
            </SelectItem>
          ))}
        </SelectList>
      }
    </SelectBlock>
  );
};

export default ApplicationSelector;