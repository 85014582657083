import styled from 'styled-components';
import {IThemeProvider} from '../../../../../../Theme/defaultTheme';
import {BasicContentSpan, BasicSmallSpan} from '../../../../../../Theme/basicElements';

interface IPaymentCardButtonProps {
  ['data-is-selected']: boolean;
}

export const PaymentCardButton = styled.button<IThemeProvider & IPaymentCardButtonProps>`
  width: min-content;
  padding: 30px 17px;
  border: 2px solid transparent;
  background-color: ${props => props.theme.colors.primary_blue};

  border-radius: ${props => props.theme.format.main_md_border_radius};

  &[data-is-selected='true'] {
    border: 2px solid ${props => props.theme.colors.brand_blue};
  }
`;

export const PaymentCardContent = styled.div`
  display: grid;
  grid-template-columns: min-content max-content;
  grid-template-rows: 1fr 1fr;
  column-gap: 8px;

  align-items: end;
`;

export const PaymentCardIcon = styled.div`
  grid-column: 1/2;
  grid-row: 1/3;
`;

export const PaymentCardCurrency = styled(BasicContentSpan)<IThemeProvider>`
  color: ${props => props.theme.colors.black_60};
  text-align: left;
  font-weight: ${props => props.theme.font_style.font_weight.bold};
`;

export const PaymentCardTitle = styled(BasicSmallSpan)`
  color: ${props => props.theme.colors.black};
  text-align: left;
`;

