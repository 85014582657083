import styled from 'styled-components';
import {IThemeProvider} from '../../../../Theme/defaultTheme';

export const StyledUserButton = styled.button<IThemeProvider>`
  color: ${props => props.theme.colors.brand_blue};
  border: 0;
  background-color: transparent;
  cursor: pointer;
  position: relative;
  display: block;

  &:hover {
    color: ${props => props.theme.colors.brand_hover};
  }
`;

export const PopupButtonBlock = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const PopupButtonIcon = styled.span`
  width: 24px;
  height: 24px;
`;

export const LogOutButton = styled.a`
  width: max-content;
`;