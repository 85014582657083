import React from 'react';
import {TableButton} from './styled';

interface IButtonProps {
  onClick: () => void;
  role: string;
  title?: string;
}

const ClearButton: React.FC<IButtonProps> = React.memo(({onClick, role, title}) => {
  return (
    <TableButton onClick={onClick} data-role={role} title={title}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
        <path d="M7 14.9199H12" stroke="currentColor" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round"
              strokeLinejoin="round"/>
        <path d="M7 6.87988L3.25 3.12988" stroke="currentColor" strokeWidth="0.8" strokeMiterlimit="10"
              strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.95996 3.16992L3.20996 6.91992" stroke="currentColor" strokeWidth="0.8" strokeMiterlimit="10"
              strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7 10.9199H15" stroke="currentColor" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round"
              strokeLinejoin="round"/>
        <path d="M10 2.91992H16C19.33 3.09992 21 4.32992 21 8.90992V16.9199" stroke="currentColor" strokeWidth="0.8"
              strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3 9.92969V16.8997C3 20.9097 4 22.9197 9 22.9197H12C12.17 22.9197 14.84 22.9197 15 22.9197"
              stroke="currentColor" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round"
              strokeLinejoin="round"/>
        <path d="M21 16.9199L15 22.9199V19.9199C15 17.9199 16 16.9199 18 16.9199H21Z" stroke="currentColor"
              strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </TableButton>
  );
});

export default ClearButton;