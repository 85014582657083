import moment from 'moment';

export const DATE_FORMAT: string = 'YYYY-MM-DD';

export const getFormattedDate = (date: Date): string => {
  return moment(date).format(DATE_FORMAT);
};

export const getDateWithoutGTMOffset = (date: Date): Date => {
  const offsetMinutes = moment(date).utcOffset();

  const newDate = new Date(date);

  return new Date(newDate.setMinutes(date.getMinutes() - offsetMinutes));
};

export const getOneDayAgo = (dateString: string): string => {
  let dateParts = dateString.split('-');
  let year = parseInt(dateParts[0]);
  let month = parseInt(dateParts[1]) - 1;
  let day = parseInt(dateParts[2]);

  let date = new Date(year, month, day);
  date.setDate(date.getDate() - 1);

  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
};

export const getOneDayAhead = (dateString: string): string => {
  let dateParts = dateString.split('-');
  let year = parseInt(dateParts[0]);
  let month = parseInt(dateParts[1]) - 1;
  let day = parseInt(dateParts[2]);

  let date = new Date(year, month, day);
  date.setDate(date.getDate() + 1);

  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
};

export const getOneMonthAgo = (date: string): string => {
  const previousDate = new Date(date);
  previousDate.setMonth(previousDate.getMonth() - 1);
  return moment(previousDate).format('YYYY-MM-DD');
};

export const getOneWeekAgo = (date: string): string => {
  const previousDate = new Date(date);
  previousDate.setDate(previousDate.getDate() - 7);
  return moment(previousDate).format('YYYY-MM-DD');
};

export const getDatesBetween = (startDate: string, stopDate: string): string[] => {
  let dates: string[] = [];
  const currentStartDate = new Date(new Date(startDate).setHours(0, 0, 0, 0));
  const currentStopDate = new Date(new Date(stopDate).setHours(0, 0, 0, 0));

  while (currentStartDate <= currentStopDate) {
    dates = [...dates, moment(currentStartDate).format(DATE_FORMAT)];
    currentStartDate.setDate(currentStartDate.getDate() + 1);
  }

  return dates;
};

export const checkThePastDate = (date: string) => {
  const checkingDate = new Date(new Date(date).setHours(0, 0, 0, 0));
  const currentDate = new Date(new Date().setHours(0, 0, 0, 0));

  return checkingDate >= currentDate;
};

export const checkCurrencyTime = (dateStart: string) => {
  // Преобразование строки в дату
  const date = new Date(dateStart);
  // Получение текущей даты в UTC
  const now = new Date();
  // Преобразование текущей даты в UTC
  now.setMinutes(now.getMinutes() - now.getTimezoneOffset());

  // Проверка, является ли переданная дата сегодняшней
  const isToday = date.toDateString() === now.toDateString();

  // Если дата сегодняшняя, проверяем, не закончился ли рабочий день до 15:00 по UTC
  if (isToday) {
    // Установка времени до 15:00 по UTC
    now.setUTCHours(15, 0, 0, 0);

    // Сравнение текущего времени с временем окончания рабочего дня
    return now.getTime() > Date.now();
  }

  // Если дата не сегодняшняя, возвращаем false, так как рабочий день не может закончиться
  return true;
};