import React, {forwardRef, useState} from 'react';
import BasicCheckboxButton from '../../../../../../../UI/BasicCheckboxButton';
import {
  DelayAttentionButtonBlock,
  DelayAttentionModalText,
  DelayAttentionModalTitle,
  DelayAttentionModalWrapper,
  DelayCheckboxLabel,
  DelayCheckboxSpan
} from './styled';
import BasicButton from '../../../../../../../UI/BasicButton';
import {setDataToStorage} from '../../../../../../../Utils/localStorageHelpers';

interface DelayAttentionModalProps {
  onClose: () => void;
}

const DelayAttentionModal = forwardRef(({onClose}: DelayAttentionModalProps, ref: React.ForwardedRef<HTMLDivElement>) => {
  const [dontShowAgain, setDontShowAgain] = useState(false);

  const handleSubmit = () => {
    if (dontShowAgain) {
      setDataToStorage('disable_delay_modal', dontShowAgain);
    }

    onClose();
  };

  return (
    <DelayAttentionModalWrapper ref={ref} tabIndex={-1}>
      <DelayAttentionModalTitle>Delayed start</DelayAttentionModalTitle>
      <DelayAttentionModalText>
        The campaign will be launched in all the countries specified in the order at the same time, according to the
        time provided by you in your time zone.
      </DelayAttentionModalText>
      <DelayCheckboxLabel>
        <BasicCheckboxButton
          name={'dont show'}
          checked={dontShowAgain}
          onChange={(event) => setDontShowAgain(event.target.checked)}/>
        <DelayCheckboxSpan>Don't show it again</DelayCheckboxSpan>
      </DelayCheckboxLabel>
      <DelayAttentionButtonBlock>
        <BasicButton onClick={handleSubmit} size={'lg'} text={'OK'} variant={'blue'}/>
      </DelayAttentionButtonBlock>
    </DelayAttentionModalWrapper>
  );
});

export default DelayAttentionModal;