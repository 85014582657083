import styled from 'styled-components';

import balanceIcon from '../../../../Images/svg/header-balance-icon.svg';
import {IThemeProvider} from '../../../../Theme/defaultTheme';

export const BalanceBlock = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;

  &::before {
    content: '';
    width: 24px;
    height: 24px;
    background-image: url(${balanceIcon});
    background-repeat: no-repeat;
    background-size: contain;
  }
`;

export const BalanceSpan = styled.span<IThemeProvider>`
  font-family: ${props => props.theme.font_style.font_family};
  font-size: ${props => props.theme.font_sizes.title};
  font-weight: ${props => props.theme.font_style.font_weight.bold};
  color: ${props => props.theme.colors.brand_blue};
`;

export const AddBalanceButton = styled.button<IThemeProvider>`
  width: 20px;
  height: 20px;
  background-color: ${props => props.theme.colors.secondary_green_b};
  border: 0;
  border-radius: 5px;
  position: relative;
  cursor: pointer;

  &::after {
    content: '';
    width: 10px;
    height: 1px;
    background-color: ${props => props.theme.colors.white};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }

  &::before {
    content: '';
    width: 1px;
    height: 10px;
    background-color: ${props => props.theme.colors.white};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }

  &:hover {
    background-color: ${props => props.theme.colors.secondary_green_a};
  }
`;