import React, {useState} from 'react';
import {TasksTableButtonsList, TasksTableButtonsRow} from './styled';
import RemoveRowButton from './Components/RemoveRowButton';
import RatioButton from './Components/RatioButton';
import ClearButton from './Components/ClearButton';
import {ICountryModel, IKeyword} from '../../../../../Models/CountryModel';
import {
  checkLengthOfKeywords,
  getDatesListFromCountry, getFirstCountryKeywordValue,
  getKeysFromCountry
} from '../../../../../Helpers/CreateOrderHelpers';
import {Modal} from '@mui/material';
import {getOneDayAgo} from '../../../../../../../Utils/DateUtils';
import {throwNewToastError} from '../../../../../../../Utils/MainUtils';
import TasksRatioModal from '../../Modals/TasksRatioModal';

interface ICountryTasksButtonsBlockProps {
  country: ICountryModel;
  handleSetCountryKeywords: (keywords: IKeyword) => void;
  orderType: string;
}

const CountryTasksButtonsBlock: React.FC<ICountryTasksButtonsBlockProps> = ({
                                                                              country,
                                                                              handleSetCountryKeywords,
                                                                              orderType
                                                                            }) => {
  const [ratioModalIsOpen, setRatioModalIsOpen] = useState(false);
  const [ratioKeyForModal, setRatioKeyForModal] = useState<string | null>(null);

  const handleSetRatio = (type: string, valueRatio: number | null, ratioKey: string | null) => {
    //функция обработки управления строками и всей таблицей
    let newCountry: ICountryModel = JSON.parse(JSON.stringify(country));
    let newKeywords: IKeyword = {};
    let shouldBreak = false;

    if (type === 'deleteRow' && ratioKey) {
      if (getKeysFromCountry(newCountry).length > 1) {
        getDatesListFromCountry(newCountry).forEach((date) => {
          delete newCountry.keywords[date][ratioKey];
        });
      } else {
        newCountry.keywords = {};
      }
    } else if (type === 'clearRow' && ratioKey) {
      getDatesListFromCountry(newCountry).forEach((date) => {
        newCountry.keywords[date][ratioKey] = 0;
      });
    } else if (type === 'clearAll') {
      for (const date of Object.keys(newCountry.keywords)) {
        for (let key of Object.keys(newCountry.keywords[date])) {
          newCountry.keywords[date][key] = 0;
        }
      }
    } else if (type === 'autofill') {
      if (valueRatio) {
        for (const date of Object.keys(newCountry.keywords)) {
          for (let key of Object.keys(newCountry.keywords[date])) {
            newCountry.keywords[date][key] = valueRatio;
          }
        }
        setRatioModalIsOpen(false);
        setRatioKeyForModal(null);
      } else {
        throwNewToastError('Please enter a value');
      }
    } else if (type === 'percent' || type === 'amount' || type === 'deviation') {
      if (valueRatio) {
        for (const date of Object.keys(newCountry.keywords)) {
          let dateAgo = getOneDayAgo(date);
          newKeywords[date] = {};

          for (const keyword of Object.keys(newCountry.keywords[date])) {
            if (keyword === ratioKey || ratioKey === null) {
              if (newCountry.keywords[dateAgo] !== undefined) {
                if (type === 'percent') {
                  const newValue = Number((Number(newCountry.keywords[dateAgo][keyword]) * ((100 + Number(valueRatio)) / 100)).toFixed(0));

                  if (newValue > 0) {
                    newCountry.keywords[date][keyword] = newValue;
                  } else {
                    newCountry.keywords[date][keyword] = 0;
                  }
                } else if (type === 'amount') {
                  const newValue = Number(Number(newCountry.keywords[dateAgo][keyword]) + Number(valueRatio));

                  if (newValue > 0) {
                    newCountry.keywords[date][keyword] = newValue;
                  } else {
                    newCountry.keywords[date][keyword] = 0;
                  }
                } else if (type === 'deviation') {
                  if (getFirstCountryKeywordValue(newCountry, keyword) > Math.abs(Number(valueRatio))) {
                    newCountry.keywords[date][keyword] = (getFirstCountryKeywordValue(newCountry, keyword)) + (Math.floor(Math.random() * (Number(valueRatio) * 2)) - Number(valueRatio));
                  } else {
                    throwNewToastError('The deviation value must be less than the minimum key value');
                    shouldBreak = true;
                    break;
                  }
                }
              }
            }
          }
          if (shouldBreak) {
            break; // Прерываем внешний цикл, если флаг установлен
          }
        }
        setRatioModalIsOpen(false);
        setRatioKeyForModal(null);
      } else {
        throwNewToastError('Please enter a value');
      }
    }
    handleSetCountryKeywords(newCountry.keywords);
  };

  const handleToggleRatioModal = (ratioKey: string | null) => {
    setRatioModalIsOpen(!ratioModalIsOpen);
    setRatioKeyForModal(ratioKey);
  };

  const handleCloseRatioModal = () => {
    setRatioModalIsOpen(!ratioModalIsOpen);
    setRatioKeyForModal(null);
  };

  return (
    <TasksTableButtonsList>
      <TasksTableButtonsRow>
        {orderType !== 'install_bp' &&
          <>
            <ClearButton
              onClick={() => handleSetRatio('clearAll', 0, null)}
              role={'globalClear'}
              title={'Clear the number of installations in all cells'}
            />
            <RatioButton
              onClick={() => handleToggleRatioModal(null)}
              role={'globalRatio'}
              title={'Cell autofill settings for all keywords'}/>
          </>
        }
      </TasksTableButtonsRow>
      {checkLengthOfKeywords(country) && getKeysFromCountry(country).map((key) =>
        <TasksTableButtonsRow key={key}>
          {orderType === 'install' &&
            <RemoveRowButton onClick={() => handleSetRatio('deleteRow', null, key)} title={'Delete keyword'}/>}
          <ClearButton
            onClick={() => handleSetRatio('clearRow', null, key)}
            role={'clearRow'}
            title={'Clear row'}
          />
          <RatioButton
            onClick={() => handleToggleRatioModal(key)}
            role={'rowRatio'}
            title={'Ratio. To use this setting, specify the number of installations on the first day of the campaign for this keyword.'}
          />
        </TasksTableButtonsRow>
      )}
      <Modal open={ratioModalIsOpen} onClose={handleCloseRatioModal}>
        <TasksRatioModal handleSetRatio={handleSetRatio} ratioKey={ratioKeyForModal} onClose={handleCloseRatioModal}/>
      </Modal>
    </TasksTableButtonsList>
  );
};

export default CountryTasksButtonsBlock;