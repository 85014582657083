import styled from 'styled-components';
import {IThemeProvider} from '../../../../Theme/defaultTheme';

interface IStatusButton {
  'data-button-active': boolean;
}

export const StatusList = styled.ul<IThemeProvider>`
  list-style-type: none;
  padding: 0;
  margin: 0;

  display: flex;
  gap: ${props => props.theme.format.main_lg_gap};
`;

export const StatusButton = styled.button<IThemeProvider & IStatusButton>`
  display: block;
  background-color: transparent;
  border: 0;
  width: max-content;

  font-family: ${props => props.theme.font_style.font_family};
  font-weight: ${props => props.theme.font_style.font_weight.regular};
  font-size: ${props => props.theme.font_sizes.content};

  color: ${props => props.theme.colors.grey};
  cursor: pointer;

  &[data-button-active='true'] {
    border-bottom: ${props => `2px solid ${props.theme.colors.brand_blue}`};
    color: ${props => props.theme.colors.black}
  }

  &:hover {
    color: ${props => props.theme.colors.black};
  }
`;