import React from 'react';
import {
  ContentBlock,
  ContentItem,
  ContentList,
  ContentListBlock,
  ContentListItem,
  ContentText,
  ContentTitle
} from './styled';
import {BasicContentSpan} from '../../../Theme/basicElements';

const PrivacyContent = () => {
  return (
    <ContentBlock>
      <BasicContentSpan>Update Date: April 02, 2024</BasicContentSpan>

      <ContentItem>
        <ContentText>
          These terms ("Terms", "Terms and Conditions") govern your interaction with the app.asofast.io website
          ("Service"). Please carefully review these Terms before using the Service. Using the Service and accessing it
          implies your agreement to abide by these Terms of Use. These Terms apply to all visitors, users, resellers,
          and other individuals who access or use the Service. If you disagree with any provision of the terms, you
          should refrain from accessing the Service.
        </ContentText>
      </ContentItem>

      <ContentItem>
        <ContentTitle>Company</ContentTitle>
        <ContentText>The Service is operated, contracts are concluded, and payments are accepted on behalf of the
          MarketSoft OÜ company, registered in Estonia. All rules set forth on the website apply to this legal entity.
          MarketSoft OÜ takes full responsibility for the terms set forth on our site. Customers are required to comply
          with these rules and provisions when using our service. MarketSoft OÜ operates in strict compliance with
          Estonian law.</ContentText>
      </ContentItem>

      <ContentItem>
        <ContentTitle>Service Description</ContentTitle>
        <ContentText>The service provides services for installing and improving the rating of mobile applications from
          the Google Play and App Store by launching relevant orders.</ContentText>
        <ContentText>By using the Service, you independently place, create, edit, and launch orders. By using the
          Service, you acknowledge that you assume all responsibility and risks associated with creating, placing,
          editing, and launching an order.</ContentText>
      </ContentItem>

      <ContentItem>
        <ContentTitle>User Rights and Obligations</ContentTitle>
        <ContentText>When you create an account with us, you must provide us with accurate, complete, and up-to-date
          information at all times, and verify by confirming your email address when registering using
          one.</ContentText>
        <ContentText>Failure to do so constitutes a breach of the Terms, which may result in the immediate termination
          of your account on our Service. You are responsible for safeguarding the password you use to access the
          Service, as well as for any activities or actions under your password, whether your password is with our
          Service or a third-party service. You agree not to disclose your password to any third party. You must notify
          us immediately upon becoming aware of any breach of security or unauthorized use of your
          account.</ContentText>
        <ContentListBlock>
          <ContentText>By using the Service, you are entitled to:</ContentText>
          <ContentList>
            <ContentListItem>confidentiality of personal information and data provided during a
              purchase</ContentListItem>
            <ContentListItem>receiving quality technical support regarding the use of the service</ContentListItem>
            <ContentListItem>receiving quality services</ContentListItem>
          </ContentList>

          <ContentText>User obligations:</ContentText>
          <ContentList>
            <ContentListItem>Complying with these terms and conditions set by the company</ContentListItem>
            <ContentListItem>Providing accurate and truthful information when making purchases</ContentListItem>
            <ContentListItem>Not using the Service for illegal purposes or infringing on the intellectual property
              rights
              of developers</ContentListItem>
            <ContentListItem>Timely and fully paying for used services according to established prices and
              conditions</ContentListItem>
          </ContentList>
          <ContentText>Prohibitions:</ContentText>
          <ContentList>
            <ContentListItem>Prohibition on reselling or further distribution of installations without the company's
              consent</ContentListItem>
            <ContentListItem>Prohibition on creating false accounts or other actions that disrupt the service's
              operation</ContentListItem>
            <ContentListItem>Users/clients are prohibited from using the Service to promote applications whose
              functionality is illegal, inhumane, oppresses groups of people based on any characteristic, or is aimed at
              fraudulent actions</ContentListItem>
          </ContentList>
        </ContentListBlock>
      </ContentItem>

      <ContentItem>
        <ContentTitle>Violations and Consequences</ContentTitle>
        <ContentText>In case of violations of the Service's usage rules, we reserve the right to take measures,
          including blocking the account or denying access to the Service.</ContentText>
        <ContentText>If you disagree with the measures taken, you can file a complaint with the technical support
          service.</ContentText>
        <ContentText>In case of violation of any of the provisions, all responsibility for such actions lies with the
          client/user of the Service.</ContentText>
      </ContentItem>

      <ContentItem>
        <ContentTitle>Intellectual Property</ContentTitle>
        <ContentText>The Service and its original content, features, and functionality are the exclusive property of
          app.asofast.io and its licensors and will remain so. Protected by copyright, trademark, and other laws of
          foreign countries, our trademarks and brand identity may not be used in connection with any product or service
          without prior written consent from app.asofast.io.</ContentText>
      </ContentItem>

      <ContentItem>
        <ContentTitle>Liability and Limitations</ContentTitle>
        <ContentText>The Service operates and provides services "as is" and "as available," without any express or
          implied warranties. All information and materials provided through the Service are provided on an "as is"
          basis without any warranties of any kind, express or implied. AsoFast does not make any warranties regarding
          the accuracy, reliability, or availability of the Service. By using the Service, you agree that using its
          services is at your own risk, and we are not liable for any losses, direct or indirect, arising from the use
          or inability to use our Service. We reserve the right to make changes, updates, and corrections to errors and
          inaccuracies without prior notice.</ContentText>
      </ContentItem>

      <ContentItem>
        <ContentTitle>Termination of Access</ContentTitle>
        <ContentText>We reserve the right to terminate your access to the Service at any time and at our discretion
          without prior notice if we find violations of the Terms of Use or other improper behavior.</ContentText>
        <ContentText>In case of such termination of access, you are obliged to immediately stop using the Service. We
          are not liable to you or third parties for loss of access to the Service in case of its termination for the
          aforementioned reasons. Resuming access to the Service can only be done after resolving the circumstances that
          caused the termination of access, at our discretion. If you wish to terminate access to the Service yourself,
          simply do not use the Service.</ContentText>
      </ContentItem>

      <ContentItem>
        <ContentTitle>Refund Policy</ContentTitle>
        <ContentText>We strive to ensure our users' satisfaction with our Service. In case of dissatisfaction with the
          quality of services or products provided through our platform, you have the right to request a refund. To be
          eligible for a refund, you must contact our support service and provide relevant information about the reasons
          for the refund. Refund conditions may vary depending on the specific situation and the type of service or
          product. The process of reviewing and approving a refund request will be carried out in accordance with the
          company's established policies.</ContentText>
        <ContentText>We issue refunds in the following cases:</ContentText>
        <ContentListBlock>
          <ContentList>
            <ContentListItem>a refund to the Client's balance for an unfulfilled order or part of an order, but only to
              the extent that the order was not completed;</ContentListItem>
            <ContentListItem>a refund to the Client's account, but not more than the actual amount on the
              balance.</ContentListItem>
            <ContentListItem>Refunds are made by the same method the client used to make the payment.</ContentListItem>
          </ContentList>
        </ContentListBlock>
        <ContentText>We do not issue refunds in cases where:</ContentText>
        <ContentListBlock>
          <ContentList>
            <ContentListItem>the actual result of using the Service differs from the expected one</ContentListItem>
            <ContentListItem>the order was completed in full</ContentListItem>
            <ContentListItem>the order contains errors and inaccuracies made by the Client</ContentListItem>
            <ContentListItem>We do not issue refunds for orders that were completed more than 2 weeks before your
              request to our support service.</ContentListItem>
          </ContentList>
        </ContentListBlock>
        <ContentText>We may issue a refund if it is not provided for by these rules, in individual cases at our
          discretion. We recommend visiting the Service's F.A.Q. section before placing an order or consulting with the
          support team to avoid balance loss and unsatisfactory results.</ContentText>
      </ContentItem>

      <ContentItem>
        <ContentTitle>Changes to the Terms of Use</ContentTitle>
        <ContentText>We reserve the right to make changes and updates to these Terms without prior notice. In case of
          planning significant changes or updates to these terms, we will do our best to notify you at least 30 days
          before the new terms take effect. The definition of significant changes remains at our discretion. By
          continuing to use our Service after such changes take effect, you automatically agree to all revised Terms. If
          you disagree with the new Terms, stop using the Service.</ContentText>
      </ContentItem>

      <ContentItem>
        <ContentTitle>Jurisdiction and Dispute Resolution</ContentTitle>
        <ContentText>In case of disputes, the parties undertake to do everything possible to resolve such disagreements
          amicably.</ContentText>
        <ContentText>In case of an impossibility to settle any disputes amicably, the parties have the right to go to
          court at the location of the defendant.</ContentText>
      </ContentItem>

      <ContentItem>
        <ContentTitle>Third Parties</ContentTitle>
        <ContentText>Since we use third-party services to expand our platform's functionality, please note that these
          services may have their own terms and privacy policies. By using our service, you agree that we are not
          responsible for the actions, content, or privacy practices of these third parties. Your interactions with
          these third-party services are solely between you and the third-party provider, and any information provided
          to them is subject to their terms and policy. We recommend reviewing the terms and privacy policy of any
          third-party services you access through our platform to understand how your information will be
          handled.</ContentText>
      </ContentItem>

      <ContentItem>
        <ContentTitle>Contact Us</ContentTitle>
        <ContentText>If you have any questions regarding these terms, you can contact us at
          support@asofast.io </ContentText>
      </ContentItem>

    </ContentBlock>
  );
};

export default PrivacyContent;