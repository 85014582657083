import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {API_URL} from '../../Constants/ApiConstants';
import {errorsActions} from '../Slices/errors.slice';
import {LS_USER_TOKEN} from '../../Constants/LSConstants';
import {authActions} from '../Slices/auth.slice';
import {clientDataActions} from '../Slices/clientData.slice';
import {IClientData} from '../../Models/ApiModels';

const {addError} = errorsActions;
const {setAuthenticated} = authActions;

const {setData} = clientDataActions;

interface IChangePasswordData {
  oldPassword: string;
  newPassword: string;
}

export const ClientDataApi = createApi({
  reducerPath: 'ClientDataApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL + '/client',
    prepareHeaders: headers => {
      const token: string | null = localStorage.getItem(LS_USER_TOKEN);

      if (token) headers.set('token', token);
    }
  }),
  tagTypes: ['ClientDataApi'],
  endpoints: (build) => ({
    getData: build.query<IClientData, void>({
      query: () => ({
        url: `/info`,
      }),
      providesTags: () => ['ClientDataApi'],
      async onQueryStarted(_, {queryFulfilled, dispatch}) {
        try {
          const response = await queryFulfilled;

          if (!response) {
            throw new Error(response);
          }

          dispatch(setData(response.data));
        } catch (error: any) {
          dispatch(addError(error.error));
          if (error.error.status === 401) {
            dispatch(setAuthenticated(false));
          }
        }
      }
    }),
    changePassword: build.mutation<any, IChangePasswordData>({
      query: ({oldPassword, newPassword}) => ({
        url: '/changePassword',
        method: 'put',
        body: {
          oldPassword,
          newPassword
        }
      })
    })
  })
});

export const {useGetDataQuery, useLazyGetDataQuery, useChangePasswordMutation} = ClientDataApi;