import styled from 'styled-components';
import {IThemeProvider} from '../../../../../../Theme/defaultTheme';

export const AppCard = styled.div<IThemeProvider>`
  width: 31%;
  min-width: 290px;
  min-height: 200px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  box-sizing: border-box;
  padding: ${props => props.theme.format.main_lg_padding} 30px;

  background-color: ${props => props.theme.colors.primary_light};
  border-radius: ${props => props.theme.format.main_lg_border_radius};

  @media (max-width: 1300px) {
    width: 47%;
  }

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const CardHeading = styled.div<IThemeProvider>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: ${props => props.theme.format.main_md_gap};
`;

export const CardContent = styled.div<IThemeProvider>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: ${props => props.theme.format.main_lg_gap};
`;

export const AppCardControl = styled.div`
  display: grid;
  grid-template-columns: min-content min-content;
  grid-template-rows: min-content min-content;
  width: 100%;
  justify-content: space-between;
  align-content: space-between;
`;

export const CardTitle = styled.h3<IThemeProvider>`
  margin: 0;
  padding: 0;

  font-family: ${props => props.theme.font_style.font_family};
  font-size: ${props => props.theme.font_sizes.large_content};
  font-weight: ${props => props.theme.font_style.font_weight.regular};
  color: ${props => props.theme.colors.black};
  line-height: ${props => props.theme.font_style.line_height};

  word-wrap: anywhere;
`;

export const AppIcon = styled.img<IThemeProvider>`
  width: 96px;
  height: 96px;
  object-fit: contain;
  border-radius: ${props => props.theme.format.main_lg_border_radius};
`;