import styled from 'styled-components';
import {IThemeProvider} from '../../../../Theme/defaultTheme';

export const ContactWrapper = styled.div<IThemeProvider>`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.format.main_md_gap};
`;

export const ContactItem = styled.a<IThemeProvider>`
  display: flex;
  gap: 16px;
  align-items: center;
  width: max-content;

  color: ${props => props.theme.colors.black_40};

  &:hover {
    color: ${props => props.theme.colors.brand_hover};

    & p {
      color: ${props => props.theme.colors.brand_hover};
    }
  }
`;

export const EmailIcon = styled.div<IThemeProvider>`
  width: 32px;
  height: 32px;
  padding: 4px;
  background-color: ${props => props.theme.colors.primary_purple};
  border-radius: 8px;
`;

export const TelegramIcon = styled.div<IThemeProvider>`
  width: 32px;
  height: 32px;
  padding: 4px;
  background-color: ${props => props.theme.colors.primary_purple};

  border-radius: 8px;
`;