import React from 'react';
import {
  RatesAdditionLabel,
  RatesAdditionRow,
  RatesAdditionTitle,
  RatesCheckboxesBlock, RatesCheckboxItem, RatesCheckboxTitle,
  RatesControlBlock
} from './styled';
import HelpButton from '../../../../../Components/HelpButton';
import BasicCheckboxButton from '../../../../../../../UI/BasicCheckboxButton';
import BasicButton from '../../../../../../../UI/BasicButton';
import {ICountryModel, IKeyword} from '../../../../../Models/CountryModel';
import {throwNewToastError} from '../../../../../../../Utils/MainUtils';
import {useAppSelector} from '../../../../../../../Hooks/redux/useAppSelector';
import {getDatesBetween} from '../../../../../../../Utils/DateUtils';
import {BasicRedAccentSpan} from '../../../../../../../Theme/basicElements';

interface ICountryRatesAdditionBlockProps {
  handleRemoveCurrentCountry: () => void;
  country: ICountryModel;
  handleSetCountryKeywords: (keywords: IKeyword) => void;
  countryIndex: number;
}

const CountryRatesAdditionBlock: React.FC<ICountryRatesAdditionBlockProps> = ({
                                                                                handleRemoveCurrentCountry,
                                                                                country,
                                                                                handleSetCountryKeywords,
                                                                                countryIndex
                                                                              }) => {
  const {dateStart, dateStop} = useAppSelector((state) => state.newOrderSlice);

  const handleChooseRateValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!dateStart || !dateStop) {
      throwNewToastError('Please select start and end date');
      return;
    }

    const dateArray = getDatesBetween(dateStart, dateStop);

    const newCountryRates: IKeyword = JSON.parse(JSON.stringify(country.keywords));

    if (event.target.checked) {
      dateArray.forEach(date => {
        if (!newCountryRates[date]) {
          newCountryRates[date] = {};
        }
        newCountryRates[date][event.target.value] = 0;
      });
    } else {
      dateArray.forEach(date => {
        if (newCountryRates[date]) {
          delete newCountryRates[date][event.target.value];
        }
      });
    }

    handleSetCountryKeywords(newCountryRates);
  };


  const getSelectedRates = (curKey: string) => {
    if (dateStart && country.keywords[dateStart]) {
      return Object.keys(country.keywords[dateStart]).includes(curKey);
    }
    return false;
  };

  return (
    <RatesControlBlock>
      <RatesAdditionRow>
        <RatesAdditionLabel>
          <RatesAdditionTitle>Rates <BasicRedAccentSpan>*</BasicRedAccentSpan></RatesAdditionTitle>
          <HelpButton popupText={'Select a rating'}/>
        </RatesAdditionLabel>
        <RatesCheckboxesBlock>
          <RatesCheckboxItem>
            <RatesCheckboxTitle>4-star</RatesCheckboxTitle>
            <BasicCheckboxButton
              id={`rate4star${countryIndex}`}
              name={'rate'}
              value={'4 star rates'}
              checked={getSelectedRates('4 star rates')}
              onChange={handleChooseRateValue}/>
          </RatesCheckboxItem>
          <RatesCheckboxItem>
            <RatesCheckboxTitle>5-star</RatesCheckboxTitle>
            <BasicCheckboxButton
              id={`rate5star${countryIndex}`}
              name={'rate'}
              value={'5 star rates'}
              checked={getSelectedRates('5 star rates')}
              onChange={handleChooseRateValue}/>
          </RatesCheckboxItem>
        </RatesCheckboxesBlock>
      </RatesAdditionRow>
      <BasicButton onClick={handleRemoveCurrentCountry} size={'md'} text={'Remove country'} variant={'red'}/>
    </RatesControlBlock>
  );
};

export default CountryRatesAdditionBlock;