import styled from 'styled-components';
import {BasicModalTitle, BasicModalWrapper} from '../../../../../../../Theme/modalsElements';
import {IThemeProvider} from '../../../../../../../Theme/defaultTheme';
import {BasicContentParagraph} from '../../../../../../../Theme/basicElements';

export const RatioModalWrapper = styled(BasicModalWrapper)`
  width: 350px;
  min-width: 350px;
`;

export const RatioModalTitle = styled(BasicModalTitle)`
  margin-bottom: 0;
`;

export const RatioModalWarning = styled(BasicContentParagraph)<IThemeProvider>`
  margin-bottom: 32px;
  color: ${props => props.theme.colors.black};

  & a {
    color: ${props => props.theme.colors.brand_blue};
    cursor: pointer;
  }
`;

export const RatioModalForm = styled.div<IThemeProvider>`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.format.main_lg_gap};
  margin-bottom: 48px;
`;

export const RatioModalRadioBlock = styled.div<IThemeProvider>`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.format.main_md_gap};
`;

export const RatioModalInputBlock = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
`;

export const RatioModalValueInput = styled.input<IThemeProvider>`
  width: 100%;
  background-color: transparent;
  padding: 16px;

  font-family: ${props => props.theme.font_style.font_family};
  font-size: ${props => props.theme.font_sizes.content};
  color: ${props => props.theme.colors.black};

  border-radius: ${props => props.theme.format.main_md_border_radius};
  border: 1px solid ${props => props.theme.colors.grey_semi};
`;

