import React, {forwardRef, useState} from 'react';
import {
  RatioModalForm,
  RatioModalInputBlock,
  RatioModalRadioBlock,
  RatioModalTitle,
  RatioModalValueInput, RatioModalWarning,
  RatioModalWrapper
} from './styled';
import BasicRadioRow from '../../../../../../../UI/BasicRadioRow';
import {BasicContentSpan} from '../../../../../../../Theme/basicElements';
import BasicButton from '../../../../../../../UI/BasicButton';
import {CloseButtonBlock} from '../../../../../../../Theme/modalsElements';
import RemoveCrossButton from '../../../../../../../UI/RemoveCrossButton';

interface ITasksRatioModalProps {
  handleSetRatio: (type: string, valueRatio: number | null, ratioKey: string | null) => void;
  ratioKey: string | null;
  onClose: () => void;
}

const TasksRatioModal = forwardRef(({
                                      handleSetRatio,
                                      ratioKey,
                                      onClose
                                    }: ITasksRatioModalProps, ref: React.ForwardedRef<HTMLDivElement>) => {
  const [ratioValue, setRatioValue] = useState<null | number>(null);
  const [ratioType, setRatioType] = useState('percent');

  const handleChangeRatioValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRatioValue(Number(e.target.value));
  };

  const handleChangeRatioType = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRatioType(e.target.value);
  };

  const handleSubmit = () => {
    handleSetRatio(ratioType, ratioValue, ratioKey);
  };

  return (
    <RatioModalWrapper ref={ref} tabIndex={-1}>
      <CloseButtonBlock>
        <RemoveCrossButton onClick={onClose}/>
      </CloseButtonBlock>
      <RatioModalTitle>{!ratioKey ? 'Global ratio' : 'Ratio'}</RatioModalTitle>
      <RatioModalWarning>
        Attention! Settings for advanced users! <a>How to use it</a>
      </RatioModalWarning>
      <RatioModalForm>
        <RatioModalRadioBlock>
          <BasicRadioRow
            name={'ratio'}
            value={'percent'}
            text={'Percent'}
            onChange={handleChangeRatioType}
            checked={ratioType === 'percent'}
            prompt={'Percent - increase the number of installations by "quantity" percent daily starting from the first day of the campaign.'}
          />
          <BasicRadioRow
            name={'ratio'}
            value={'amount'}
            text={'Fixed number'}
            onChange={handleChangeRatioType}
            checked={ratioType === 'amount'}
            prompt={'Fixed number - increase the number of installations by "quantity" daily starting from the first day of the campaign.'}
          />
          <BasicRadioRow
            name={'ratio'}
            value={'deviation'}
            text={'Deviation'}
            onChange={handleChangeRatioType}
            checked={ratioType === 'deviation'}
            prompt={'Deviation - change the number of installations up and down to "quantity" daily starting from the first day of the campaign.'}
          />
          {!ratioKey &&
            <BasicRadioRow
              name={'ratio'}
              value={'autofill'}
              onChange={handleChangeRatioType}
              text={'Equal'}
              checked={ratioType === 'autofill'}
              prompt={'Equal - fills the entire table with the specified "quantity" of  keywords/rates.'}
            />
          }
        </RatioModalRadioBlock>
        <RatioModalInputBlock>
          <RatioModalValueInput
            type={'number'}
            value={ratioValue ? ratioValue : ''}
            onChange={handleChangeRatioValue}
            placeholder={'Enter quantity'}
            onKeyDown={(event) => {
              const disallowedKeys = ['+', 'e', ',', '.'];

              if (disallowedKeys.includes(event.key)) {
                event.preventDefault(); // Отменяем ввод недопустимых символов
              }
            }}
          />
          <BasicContentSpan>daily</BasicContentSpan>
        </RatioModalInputBlock>
      </RatioModalForm>
      <BasicButton onClick={handleSubmit} size={'lg'} text={'Apply'} variant={'blue'}/>
    </RatioModalWrapper>
  );
});

export default TasksRatioModal;