export const getDataFromStorage = (key: string): null | string => {
  const item = localStorage.getItem(key);

  if (!item) {
    return null
  }

  return JSON.parse(item);
}

export const setDataToStorage = (key: string, data: any) => {
  const item = JSON.stringify(data);

  localStorage.setItem(key, item);
}