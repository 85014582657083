import React from 'react';
import {StyledButton} from './styled';

interface IOrderTableButtonProps {
  onClick: (() => void) | ((event: React.MouseEvent<HTMLButtonElement>) => void);
  role: 'cancel' | 'promote' | 'archive';
  disabled?: boolean;
  title?: string;
}

const ROLE_TITLES = {
  cancel: 'Cancel',
  promote: 'Re | Ex',
  archive: 'Archive'
};

const OrderTableButton: React.FC<IOrderTableButtonProps> = ({role, onClick, disabled, title}) => {
  return (
    <StyledButton data-role={role} onClick={onClick} disabled={disabled} title={title}>
      {ROLE_TITLES[role]}
    </StyledButton>
  );
};

export default OrderTableButton;