import React from 'react';
import {TableButton} from './styled';

interface IButtonProps {
  onClick: () => void;
  title?: string;
}

const RemoveRowButton: React.FC<IButtonProps> = React.memo(({onClick, title}) => {
  return (
    <TableButton onClick={onClick} title={title}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
        <path d="M8 12.9199H16" stroke="#5063C0" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
        <path
          d="M9 22.9199H15C20 22.9199 22 20.9199 22 15.9199V9.91992C22 4.91992 20 2.91992 15 2.91992H9C4 2.91992 2 4.91992 2 9.91992V15.9199C2 20.9199 4 22.9199 9 22.9199Z"
          stroke="currentColor" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </TableButton>
  );
});

export default RemoveRowButton;