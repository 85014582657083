import React, {useState} from 'react';
import {InputField, OptionBlock, OptionItem, OptionsList, SelectBlock} from './styled';
import {ORDERS_TYPES} from '../../../Pages/CreateCampaignPage/Constants/OrdersTypes';

interface IBasicSelectProps {
  selectedType: string | null;
  handleSelectItem: (newType: string | null, prevType: string | null) => void;
  placeholder: string;
  disabled?: boolean;
}

const TypeSelector: React.FC<IBasicSelectProps> = ({
                                                     selectedType,
                                                     handleSelectItem,
                                                     placeholder,
                                                     disabled
                                                   }) => {
  const [listIsOpen, setListIsOpen] = useState(false);
  const [listIsFocused, setListIsFocused] = useState(false);

  const handleOpenList = () => {
    setListIsOpen(true);
  };

  const handleCloseList = () => {
    setListIsOpen(false);
  };

  const getInputValue = (): string => {
    return selectedType ? ORDERS_TYPES.find(item => selectedType.includes(item.id))?.title || '' : '';
  };

  return (
    <SelectBlock data-list-is-open={listIsOpen}>
      <InputField
        disabled={disabled}
        type={'text'}
        placeholder={placeholder}
        readOnly
        onMouseDown={() => {
          setListIsFocused(!listIsFocused);
          setListIsOpen(!listIsOpen);
        }}
        onFocus={() => {
          setListIsFocused(true);
          handleOpenList();
        }}
        onBlur={() => {
          handleCloseList();
          setListIsFocused(false);
        }}
        value={getInputValue()}
        data-is-open={listIsOpen}
      />
      <OptionBlock data-is-open={listIsOpen} data-is-focused={listIsFocused}>
        <OptionsList>
          {ORDERS_TYPES && ORDERS_TYPES.map(item =>
            <OptionItem key={item.id}
                        onMouseDown={() => handleSelectItem(item.id, selectedType)}>{item.title}</OptionItem>
          )}
        </OptionsList>
      </OptionBlock>
    </SelectBlock>
  );
};

export default TypeSelector;