import styled from 'styled-components';
import {BasicOrderFormRow, BasicOrderFormRowLabel, BasicOrderFormRowTitle} from '../../../../../Styles/newOrdersBasics';
import {BasicContentSpan} from '../../../../../../../Theme/basicElements';
import {IThemeProvider} from '../../../../../../../Theme/defaultTheme';

export const CountryItemSelectorRow = styled(BasicOrderFormRow)``;

export const CountryItemDuplicateError = styled(BasicContentSpan)<IThemeProvider>`
  color: ${props => props.theme.colors.secondary_red};
  max-width: 100%;
`;

export const CountryItemSelectorRowLabel = styled(BasicOrderFormRowLabel)``;

export const CountryItemSelectorRowTitle = styled(BasicOrderFormRowTitle)``;