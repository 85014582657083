import React, {useState} from 'react';
import {AppCard, AppCardControl, AppIcon, CardContent, CardHeading, CardTitle} from './styled';
import {IAppItem} from '../../../../../../Models/ApiModels';
import {Modal} from '@mui/material';
import AppStatisticModal from '../../Modals/AppStatisticModal';
import BasicButton from '../../../../../../UI/BasicButton';
import RemoveCrossButton from '../../../../../../UI/RemoveCrossButton';
import AppCardStatusIndicator from '../AppCardStatusIndicator';
import DeleteAppModal from '../../Modals/DeleteAppModal';
import StatisticButton from '../../../../../../UI/StatisticButton';
import {useRemoveAppMutation} from '../../../../../../Store/APIs/Apps.api';
import {useNavigate} from 'react-router-dom';
import CustomLoader from '../../../../../../Components/Loaders/CustomLoader';
import BasicStoreIcon from '../../../../../../UI/icons/BasicStoreIcon';

interface IProps {
  appCard: IAppItem;
}

const MyAppCard = ({appCard}: IProps) => {
  const navigate = useNavigate();

  const [openStatisticModal, setOpenStatisticModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [removeApp, {isLoading: isRemoving}] = useRemoveAppMutation();

  const handleRemoveApp = () => {
    removeApp({
      id: appCard.id
    }).then(() => {
      setOpenDeleteModal(false);
      //todo Сделать отработку успешного удаления
    });
  };

  const handleToggleStatisticModal = () => {
    setOpenStatisticModal(!openStatisticModal);
  };

  const handleToggleDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  const handlePromoteApplication = () => {
    navigate(`create_campaign/promote/${appCard.id}`);
  };

  if (isRemoving) {
    return (
      <CustomLoader size={'lg'} loadingText={'Removing...'} backdrop={true}/>
    );
  }

  return (
    <AppCard>
      <CardHeading>
        <CardTitle>{appCard.name}</CardTitle>
        <RemoveCrossButton onClick={handleToggleDeleteModal} title={'Delete'}/>
      </CardHeading>
      <CardContent>
        <AppIcon src={appCard.icon} alt={appCard.name}/>
        <AppCardControl>
          <BasicStoreIcon
            store={appCard.url.includes('google') ? 'google' : 'apple'}
            href={appCard.url}
            target={'_blank'}/>
          <StatisticButton onClick={handleToggleStatisticModal}/>
          <BasicButton onClick={handlePromoteApplication} text={'Promote'} size={'sm'} variant={'blue'}
                       disabled={appCard.name.includes('banned')}/>
          <AppCardStatusIndicator status={appCard.status}/>
        </AppCardControl>
      </CardContent>
      <Modal open={openStatisticModal} onClose={handleToggleStatisticModal}>
        <AppStatisticModal appInfo={appCard} onClose={handleToggleStatisticModal}/>
      </Modal>
      <Modal open={openDeleteModal} onClose={handleToggleDeleteModal}>
        <DeleteAppModal onDelete={handleRemoveApp} onClose={handleToggleDeleteModal} appName={appCard.name}/>
      </Modal>
    </AppCard>
  );
};

export default MyAppCard;