import React, {useEffect, useState} from 'react';
import {
  ControlBlock, StatisticEmptyText,
  StatisticModal,
  StatisticModalTitle,
  StatisticTable,
  StatisticTableBlock, StatisticTBody, StatisticTd, StatisticTh,
  StatisticTHead, StatisticTitleWrapper, StatisticTr
} from './styled';
import {useGetAppStatisticQuery} from '../../../../../../Store/APIs/Apps.api';
import {getFormattedDate, getOneWeekAgo} from '../../../../../../Utils/DateUtils';
import {CloseButtonBlock} from '../../../../../../Theme/modalsElements';
import DateSelector from '../../../../../../Components/DateSelector';
import BasicButton from '../../../../../../UI/BasicButton';
import RemoveCrossButton from '../../../../../../UI/RemoveCrossButton';
import {IAppItem} from '../../../../../../Models/ApiModels';
import {Loader} from 'rsuite';
import BasicStoreIcon from '../../../../../../UI/icons/BasicStoreIcon';

import {utils, writeFile} from 'xlsx';
import moment from 'moment';

interface IStatisticProps {
  appInfo: IAppItem;
  onClose: () => void;
}

const AppStatisticModal = React.forwardRef<HTMLDivElement, IStatisticProps>(({appInfo, onClose}, ref) => {
  const [dateStart, setDateStart] = useState(getOneWeekAgo(getFormattedDate(new Date())));
  const [dateStop, setDateStop] = useState(getFormattedDate(new Date()));
  const [statDates, setStatDates] = useState<string[]>([]);

  const {data = [], isLoading} = useGetAppStatisticQuery({id: appInfo.id, dateStart, dateStop});

  useEffect(() => {
    if (data.length > 0) {
      const dateList = Object.keys(data[0]).filter(key => key !== 'key');

      setStatDates(dateList);
    }
  }, [data]);

  const handleSetDateStart = (date: string) => {
    setDateStart(date);
  };

  const handleSetDateStop = (date: string) => {
    setDateStop(date);
  };

  const handleExport = () => {
    if (data.length) {
      console.log(data);

      const headerRow = ['Keys', ...Object.keys(data[0]).filter((key) => key !== 'key')];
      const worksheetData = [headerRow];
      data.forEach(obj => {
        const row = [obj.key];
        Object.keys(obj).forEach((key) => {
          if (key !== 'key') {
            row.push(obj[key]);
          }
        });
        worksheetData.push(row);
      });

      const ws = utils.aoa_to_sheet(worksheetData);
      const wb = utils.book_new();

      utils.book_append_sheet(wb, ws, `Data`);

      writeFile(wb, `statistic_report_${appInfo.url.includes('apple') ? 'AppStore' : 'PlayMarket'}_${appInfo.name.replace(/ /g, '_')}_${moment(dateStart).format('YYYY-MM-DD')}_-_${moment(dateStop).format('YYYY-MM-DD')}.xlsx`);
    }
  };

  return (
    <StatisticModal ref={ref} tabIndex={-1}>
      <CloseButtonBlock>
        <RemoveCrossButton onClick={onClose}/>
      </CloseButtonBlock>
      <StatisticTitleWrapper>
        <BasicStoreIcon store={appInfo.url.includes('apple') ? 'apple' : 'google'}/>
        <StatisticModalTitle>{appInfo.name} statistics</StatisticModalTitle>
      </StatisticTitleWrapper>
      <ControlBlock>
        <DateSelector
          dateStart={dateStart}
          dateStop={dateStop}
          handleGetDateStop={handleSetDateStop}
          handleGetDateStart={handleSetDateStart}
          cleanable={false}
        />
        <BasicButton onClick={handleExport} size={'md'} text={'Export XLSX'} variant={'blue'} disabled={!data.length}/>
      </ControlBlock>
      <StatisticTableBlock>
        {!!data.length &&
          <StatisticTable>
            <StatisticTHead>
              <StatisticTr>
                <StatisticTh>Keys</StatisticTh>
                {statDates?.map(date => (
                  <StatisticTh key={date}>{date}</StatisticTh>
                ))}
              </StatisticTr>
            </StatisticTHead>
            <StatisticTBody>
              {data?.map(stat => (
                <StatisticTr key={stat.key}>
                  <StatisticTd data-role={'key'}>{stat.key}</StatisticTd>
                  {statDates?.map(date => (
                    <StatisticTd key={stat.key + date}>{stat[date]}</StatisticTd>
                  ))}
                </StatisticTr>
              ))}
            </StatisticTBody>
          </StatisticTable>
        }
        {!data.length &&
          <>
            {isLoading ?
              <Loader center size={'lg'}/> :
              <StatisticEmptyText>No data</StatisticEmptyText>
            }
          </>
        }
      </StatisticTableBlock>
    </StatisticModal>
  );
});

export default AppStatisticModal;