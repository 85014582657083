import React, {JSX} from 'react';
import {
  ForgotLogoBlock,
  ForgotPage,
  LogoIcon,
  LogoTitle
} from './styled';
import SendEmailWindow from './Modules/SendEmailWindow';
import ResetPasswordWindow from './Modules/ResetPasswordWindow';
import {Route, Routes, useNavigate} from 'react-router-dom';

interface IRoutes {
  path: string,
  element: JSX.Element
}

interface IForgotPasswordPageProps {
  captchaToken: string;
}

const ForgotPasswordPage: React.FC<IForgotPasswordPageProps> = ({captchaToken}) => {
  const navigate = useNavigate();

  const handleReturn = () => {
    navigate('/authorization');
  };

  const ROUTES: IRoutes[] = [
    {path: '/', element: <SendEmailWindow handleReturn={handleReturn} captchaToken={captchaToken}/>},
    {path: `/:resetKey`, element: <ResetPasswordWindow/>}
  ];

  return (
    <ForgotPage>
      <ForgotLogoBlock>
        <LogoIcon/>
        <LogoTitle/>
      </ForgotLogoBlock>
      <Routes>
        {ROUTES.map(route => <Route path={route.path} element={route.element} key={route.path}/>)}
      </Routes>
    </ForgotPage>
  );
};

export default ForgotPasswordPage;