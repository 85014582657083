import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {API_URL} from '../../Constants/ApiConstants';
import {errorsActions} from '../Slices/errors.slice';
import {LS_USER_TOKEN} from '../../Constants/LSConstants';
import {authActions} from '../Slices/auth.slice';

interface IUserAuthorizationData {
  username: string,
  password: string,
  captchaToken: string,
  referral?: string
}

interface IAuthorizationResponse {
  token: string;
}

interface ITelegramAuthPayload {
  hash: string;
  payload: string;
  referral?: string;
}

interface IResetPasswordPayload {
  login: string;
  token: string;
}

interface IGoogleAuthPayload {
  creditals: string;
  referral?: string;
}

interface IResetPasswordFinalPayload {
  resetKey: string;
  password: string;
}

interface IResetPasswordFinalResponse {
  status: 'ok';
}

const saveToken = (token: string) => {
  localStorage.setItem(LS_USER_TOKEN, token);
};

const {addError} = errorsActions;
const {setAuthenticated} = authActions;

export const AuthApi = createApi({
  reducerPath: 'AuthApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL
  }),
  endpoints: (build) => ({
    authorization: build.mutation<IAuthorizationResponse, IUserAuthorizationData>({
      query: ({username, password, captchaToken: token}) => ({
        url: '/auth',
        method: 'post',
        body: {
          login: username,
          password: password,
          token
        }
      }),
      async onQueryStarted(_, {queryFulfilled, dispatch}) {
        try {
          const response = await queryFulfilled;
          if ('data' in response) {
            saveToken(response.data.token);
          }
          dispatch(setAuthenticated(true));
        } catch (error: any) {
          dispatch(addError(error.error));
        }
      },
    }),
    registration: build.mutation<IAuthorizationResponse, IUserAuthorizationData>({
      query: ({username, password, captchaToken: token, referral}) => ({
        url: '/reg',
        method: 'post',
        body: {
          login: username,
          password: password,
          token,
          ...(referral && {referral: atob(referral)})
        }
      }),
      async onQueryStarted(_, {queryFulfilled, dispatch}) {
        try {
          const response = await queryFulfilled;
          if ('data' in response) {
            saveToken(response.data.token);
          }
          dispatch(setAuthenticated(true));
        } catch (error: any) {
          dispatch(addError(error.error));
        }
      },
    }),
    telegramSingIn: build.query<IAuthorizationResponse, ITelegramAuthPayload>({
      query: ({hash, payload, referral}) => ({
        url: '/auth/soa/telegram/callback',
        params: {
          hash,
          payload,
          ...(referral && {referral: atob(referral)})
        }
      }),
      async onQueryStarted(_, {queryFulfilled, dispatch}) {
        try {
          const response = await queryFulfilled;
          if ('data' in response) {
            saveToken(response.data.token);
          }
          dispatch(setAuthenticated(true));
        } catch (error: any) {
          dispatch(addError(error.error));
        }
      },
    }),
    googleSingOn: build.query<IAuthorizationResponse, IGoogleAuthPayload>({
      query: ({creditals, referral}) => ({
        url: '/auth/soa/google/callback',
        params: {
          code: creditals,
          ...(referral && {referral: atob(referral)})
        }
      }),
      async onQueryStarted(_, {queryFulfilled, dispatch}) {
        try {
          const response = await queryFulfilled;
          if ('data' in response) {
            saveToken(response.data.token);
          }
          dispatch(setAuthenticated(true));
        } catch (error: any) {
          dispatch(addError(error.error));
        }
      },
    }),
    resetPassword: build.mutation<any, IResetPasswordPayload>({
      query: ({login, token}) => ({
        url: '/reset-password',
        method: 'post',
        body: {
          login,
          token
        }
      }),
      async onQueryStarted(_, {queryFulfilled, dispatch}) {
        try {
          await queryFulfilled;

        } catch (error: any) {
          dispatch(addError(error.error));
        }
      },
    }),
    resetPasswordFinal: build.mutation<IResetPasswordFinalResponse, IResetPasswordFinalPayload>({
      query: ({resetKey, password}) => ({
        url: `/reset-password/${resetKey}`,
        method: 'post',
        body: {
          password
        }
      }),
      async onQueryStarted(_, {queryFulfilled, dispatch}) {
        try {
          await queryFulfilled;

        } catch (error: any) {
          dispatch(addError(error.error));
        }
      },
    })
  })
});

export const {
  useAuthorizationMutation,
  useRegistrationMutation,
  useLazyTelegramSingInQuery,
  useLazyGoogleSingOnQuery,
  useResetPasswordMutation,
  useResetPasswordFinalMutation
} = AuthApi;