import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface IErrorState {
  errors :object[];
}

const initialState :IErrorState = {
  errors: []
};

export const errorsSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    addError(state, action :PayloadAction<object>) {
      state.errors.push(action.payload);
    },
    removeError(state, action :PayloadAction<number>) {

    }
  }
});

export const errorsActions = errorsSlice.actions;
export const errorsReducer = errorsSlice.reducer;