import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {getDataFromStorage, setDataToStorage} from '../../../Utils/localStorageHelpers';
import {
  LS_ORDERS_APP,
  LS_ORDERS_DATE_START,
  LS_ORDERS_DATE_STOP,
  LS_ORDERS_STATUS
} from '../Constants/OrdersLSConstants';

interface IFiltersState {
  selectedAppId: number,
  dateStart: string,
  dateStop: string,
  selectedStatus: number
}

const initialState: IFiltersState = {
  selectedAppId: getDataFromStorage(LS_ORDERS_APP) ? Number(getDataFromStorage(LS_ORDERS_APP)) : 0,
  selectedStatus: getDataFromStorage(LS_ORDERS_STATUS) ? Number(getDataFromStorage(LS_ORDERS_STATUS)) : 0,
  dateStart: getDataFromStorage(LS_ORDERS_DATE_START) ?? '',
  dateStop: getDataFromStorage(LS_ORDERS_DATE_STOP) ?? ''
};

export const myOrdersFiltersSlice = createSlice({
  name: 'myOrdersFilters',
  initialState,
  reducers: {
    setOrdersSelectedApp(state, action: PayloadAction<number>) {
      state.selectedAppId = action.payload;
      setDataToStorage(LS_ORDERS_APP, action.payload);
    },
    setOrdersSelectedStatus(state, action: PayloadAction<number>) {
      state.selectedStatus = action.payload;
      setDataToStorage(LS_ORDERS_STATUS, action.payload);
    },
    setOrdersDateStart(state, action: PayloadAction<string>) {
      state.dateStart = action.payload;
      setDataToStorage(LS_ORDERS_DATE_START, action.payload);
    },
    setOrdersDateStop(state, action: PayloadAction<string>) {
      state.dateStop = action.payload;
      setDataToStorage(LS_ORDERS_DATE_STOP, action.payload);
    }
  }
});

export const myOrdersFiltersActions = myOrdersFiltersSlice.actions;

export const myOrdersFiltersReducer = myOrdersFiltersSlice.reducer;