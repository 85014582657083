import styled from 'styled-components';
import {IThemeProvider} from '../../Theme/defaultTheme';

import logoIcon from '../../Images/svg/header-logo-icon.svg';
import logoText from '../../Images/png/header-logo-text.png';

import appStoreIcon from '../../Images/svg/appstore-logo.svg';
import googleStoreIcon from '../../Images/png/playmarket-icon.png';

export const StyledAuthPage = styled.div<IThemeProvider>`
  position: relative;
  width: 100%;
  height: 100vh;

  background: #BFC7EF;

  display: flex;
  align-items: center;
  justify-content: space-around;
  overflow: hidden;

  &::before {
    content: '';
    width: 3480px;
    height: 3146px;
    border-radius: 3480.865px;
    background: linear-gradient(309deg, #E5E9FC 17.66%, rgba(232, 234, 249, 0.00) 100%);
    filter: blur(5px);

    position: absolute;
    right: -277px;
    bottom: -77px;
  }

  &::after {
    content: '';
    width: 1731.433px;
    height: 1731.433px;
    border-radius: 1731.433px;
    background: linear-gradient(101deg, #C4CDF9 0%, rgba(232, 234, 249, 0.00) 100%);
    filter: blur(5px);

    position: absolute;
    top: -1223px;
    right: -90px;
  }
`;

export const AuthLogoBlock = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  width: max-content;
`;

export const AuthLogo = styled.div`
  display: flex;
  align-items: center;
  gap: 34px;
`;

export const AuthLogoIcon = styled.div`
  display: block;
  width: 35px;
  height: 59px;
  background-image: url(${logoIcon});
  background-repeat: no-repeat;
  background-size: contain;
`;

export const AuthLogoText = styled.div`
  display: block;
  width: 246px;
  height: 48px;
  background-image: url(${logoText});
  background-repeat: no-repeat;
  background-size: contain;
`;

export const AuthStoreBlock = styled.div<IThemeProvider>`
  display: flex;
  gap: ${props => props.theme.format.main_md_gap};
`;

export const AuthStoreItem = styled.div<IThemeProvider>`
  background-color: ${props => props.theme.colors.white};
  display: flex;
  align-items: center;
  gap: ${props => props.theme.format.main_md_gap};
  border-radius: ${props => props.theme.format.main_md_border_radius};
  padding: 8px 24px;
`;

export const AuthStoreIcon = styled.div`
  display: block;
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-size: contain;

  &[data-store='appStore'] {
    background-image: url(${appStoreIcon});
  }

  &[data-store='google'] {
    background-image: url(${googleStoreIcon});
  }
`;

export const AuthStoreText = styled.span<IThemeProvider>`
  font-family: ${props => props.theme.font_style.font_family};
  font-weight: ${props => props.theme.font_style.font_weight.regular};
  font-size: ${props => props.theme.font_sizes.content};
  color: ${props => props.theme.colors.grey};
  text-align: center;
`;

export const AuthFormBlock = styled.div`
  position: relative;
  z-index: 2;
`;