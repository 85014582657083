import React, {useEffect, useState} from 'react';
import {FinancialLogsBlock} from './styled';
import {BasicPageTitle} from '../../../../Theme/basicElements';
import PaymentsTable from './Components/PaymentsTable';
import {useGetPaymentsQuery} from '../../../../Store/APIs/Payments.api';
import {IPaymentItem} from '../../../../Models/ApiModels';

const FinancialLogsModule = () => {
  const {data = [], isLoading} = useGetPaymentsQuery();
  const [paymentsList, setPaymentsList] = useState<IPaymentItem[]>([]);
  const [writtenOffList, setWrittenOffList] = useState<IPaymentItem[]>([]);

  useEffect(() => {
    if (data.length) {
      const newWrittenOffList: IPaymentItem[] = [];
      const newPaymentsList = data.filter((payment) => {
        if (Number(payment.amount) > 0) {
          return payment;
        }
        if (Number(payment.amount) < 0) {
          newWrittenOffList.push(payment);
        }
      });

      setPaymentsList(newPaymentsList);
      setWrittenOffList(newWrittenOffList);
    }
  }, [data]);

  return (
    <FinancialLogsBlock>
      <BasicPageTitle>My payments</BasicPageTitle>
      <PaymentsTable paymentsList={paymentsList} isLoading={isLoading}/>
      <BasicPageTitle>Table of written-off funds</BasicPageTitle>
      <PaymentsTable paymentsList={writtenOffList} isLoading={isLoading}/>
    </FinancialLogsBlock>
  );
};

export default FinancialLogsModule;