import React, {useEffect, useState} from 'react';

interface IValidationsList {
  nonEmpty: boolean,
  minLength?: number,
  maxLength?: number,
  symbols?: boolean
}

const useValidation = (value: string | number, validations: IValidationsList) => {
  const [isEmpty, setIsEmpty] = useState(true);
  const [minLengthError, setMinLengthError] = useState(false);
  const [maxLengthError, setMaxLengthError] = useState(false);
  const [correctSymbolsError, setCorrectSymbolsError] = useState(false);

  const [inputValid, setInputValid] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    for (const validation in validations) {
      if (validations.hasOwnProperty(validation)) {
        switch (validation) {
          case 'nonEmpty':
            if (!String(value).length) {
              setIsEmpty(true);
              setError('Field is empty');
              return;
            } else {
              setIsEmpty(false);
              setError('');
              break;
            }

          case 'minLength':
            if (validations.minLength != null && String(value).length < validations.minLength) {
              setMinLengthError(true);
              setError('Length must be between 8 and 50 characters');
              return;
            } else {
              setMinLengthError(false);
              setError('');
              break;
            }

          case 'maxLength':
            if (validations.maxLength != null && String(value).length > validations.maxLength) {
              setMaxLengthError(true);
              setError('Length must be between 8 and 50 characters');
              return;
            } else {
              setMaxLengthError(false);
              setError('');
              break;
            }

          case 'symbols':
            const regex = /^[a-zA-Z0-9!@#$%~^&*()_+-=,.<>?;:'"{}[\]\\|]*$/;
            if (!regex.test(String(value))) {
              setCorrectSymbolsError(true);
              setError('The password can only consist of Latin characters, numbers and special characters');
              return;
            } else {
              setCorrectSymbolsError(false);
              setError('');
              break;
            }
        }
      }
    }
  }, [value]);

  useEffect(() => {
    if (isEmpty || minLengthError || maxLengthError || correctSymbolsError) {
      setInputValid(false);
    } else {
      setInputValid(true);
    }

  }, [isEmpty, minLengthError, maxLengthError, correctSymbolsError]);


  return {isEmpty, minLengthError, maxLengthError, inputValid, correctSymbolsError, error};
};

export const useInput = (initialValue: string, validations: IValidationsList) => {
  const [isDirty, setIsDirty] = useState(false);
  const [value, setValue] = useState(initialValue);
  const valid = useValidation(value, validations);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const onBlur = () => {
    setIsDirty(true);
  };

  const onFocus = () => {
    setIsDirty(false);
  };

  return {value, onChange, onBlur, onFocus, ...valid, isDirty};
};