import React, {forwardRef} from 'react';
import {
  SubmitCampaignModal,
  SubmitCampaignModalText,
  SubmitCampaignModalTextBlock,
  SubmitCampaignModalTitle
} from './styled';
import BasicButton from '../../../../UI/BasicButton';
import {useAppSelector} from '../../../../Hooks/redux/useAppSelector';
import {useGetAppsQuery} from '../../../../Store/APIs/Apps.api';
import {
  getTotalCostValue,
  getTotalCurrentTypeName,
  getTotalKeywordsCountFromCountries
} from '../../Helpers/CreateOrderHelpers';
import RemoveCrossButton from '../../../../UI/RemoveCrossButton';
import {CloseButtonBlock} from '../../../../Theme/modalsElements';

interface ICreateNewOrderModalProps {
  handleSubmitCreateNewOrder: () => void;
  onClose: () => void;
}

const CreateNewOrderModal = forwardRef(({
                                          handleSubmitCreateNewOrder,
                                          onClose
                                        }: ICreateNewOrderModalProps, ref: React.ForwardedRef<HTMLDivElement>) => {
  const {countriesList, app, type, attributesValue} = useAppSelector(state => state.newOrderSlice);
  const {user} = useAppSelector(state => state.clientDataSlice);

  const {data: appsList = []} = useGetAppsQuery({offset: 0, search: '', limit: 999});

  return (
    <SubmitCampaignModal ref={ref} tabIndex={-1}>
      <CloseButtonBlock>
        <RemoveCrossButton onClick={onClose}/>
      </CloseButtonBlock>
      <SubmitCampaignModalTitle>Are you sure you want to start
        the promotion?</SubmitCampaignModalTitle>
      <SubmitCampaignModalTextBlock>
        <SubmitCampaignModalText>Campaign
          price: <b>${(getTotalCostValue(app, type, user, appsList, countriesList, attributesValue))?.toFixed(2)}</b></SubmitCampaignModalText>
        <SubmitCampaignModalText>Total {!!type && getTotalCurrentTypeName(type)}: <b>{getTotalKeywordsCountFromCountries(countriesList)}</b></SubmitCampaignModalText>
      </SubmitCampaignModalTextBlock>
      <BasicButton onClick={handleSubmitCreateNewOrder} size={'lg'} text={'Ok'} variant={'blue'}/>
    </SubmitCampaignModal>
  );
});

export default CreateNewOrderModal;