import styled from 'styled-components';
import {BasicContentParagraph, BasicContentSpan} from '../../../../../../../Theme/basicElements';
import {IThemeProvider} from '../../../../../../../Theme/defaultTheme';
import {BasicModalTitle, BasicModalWrapper} from '../../../../../../../Theme/modalsElements';

export const DelayAttentionModalWrapper = styled(BasicModalWrapper)`
  gap: 32px;
  align-items: center;
`;

export const DelayAttentionModalTitle = styled(BasicModalTitle)`
  margin-bottom: 0;
`;

export const DelayAttentionModalText = styled(BasicContentParagraph)`
  text-align: center;
  font-size: 16px;
`;

export const DelayCheckboxLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const DelayCheckboxSpan = styled(BasicContentSpan)<IThemeProvider>`
  color: ${props => props.theme.colors.black};
`;

export const DelayAttentionButtonBlock = styled.div`
  width: 250px;
`;