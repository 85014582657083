import React from 'react';
import {PackageAdditionLabel, PackageAdditionTitle, PackageControlBlock, PackageControlRow} from './styled';
import HelpButton from '../../../../../Components/HelpButton';
import BasicButton from '../../../../../../../UI/BasicButton';

interface ICountryPackageAdditionBlockProps {
  handleRemoveCurrentCountry: () => void;
}

const CountryPackageAdditionBlock: React.FC<ICountryPackageAdditionBlockProps> = ({handleRemoveCurrentCountry}) => {
  return (
    <PackageControlBlock>
      <PackageControlRow>
        <PackageAdditionLabel>
          <PackageAdditionTitle>Installs</PackageAdditionTitle>
          <HelpButton
            popupText={'The "Installations by package" campaign type is selected. Specify the required number of installations.'}/>
        </PackageAdditionLabel>
      </PackageControlRow>
      <BasicButton onClick={handleRemoveCurrentCountry} size={'md'} text={'Remove country'} variant={'red'}/>
    </PackageControlBlock>
  );
};

export default CountryPackageAdditionBlock;