import React, {forwardRef, useEffect, useState} from 'react';
import {
  PasswordCloseModalButtonBlock,
  PasswordInputBlock,
  PasswordInputLabel,
  PasswordModal,
  PasswordModalTitle
} from './styled';
import BasicButton from '../../../../UI/BasicButton';
import RemoveCrossButton from '../../../../UI/RemoveCrossButton';
import {useChangePasswordMutation} from '../../../../Store/APIs/ClientData.api';
import {throwNewToastError, throwNewToastSuccess} from '../../../../Utils/MainUtils';
import {useInput} from '../../../../Hooks/UseInput/UseInput';
import CustomLoader from '../../../../Components/Loaders/CustomLoader';
import BasicAuthInput from '../../../../UI/BasicAuthInput';

interface IChangePasswordModalProps {
  handleClose: () => void;
}

const ChangePasswordModal = forwardRef(({handleClose}: IChangePasswordModalProps, ref: React.ForwardedRef<HTMLDivElement>) => {
  const oldPassword = useInput('', {nonEmpty: true});
  const newPassword = useInput('', {nonEmpty: true, minLength: 8, maxLength: 50, symbols: true});
  const retryPassword = useInput('', {nonEmpty: true, minLength: 8, maxLength: 50});

  const [retryPasswordError, setRetryPasswordError] = useState(false);
  const [oldPasswordError, setOldPasswordError] = useState(false);

  const [oldPasswordIsVisible, serOldPasswordIsVisible] = useState(false);
  const [newPasswordIsVisible, setNewPasswordIsVisible] = useState(false);

  const [changePassword, {isLoading}] = useChangePasswordMutation();

  useEffect(() => {
    setRetryPasswordError(false);
  }, [retryPassword.value, newPassword.value]);

  useEffect(() => {
    setOldPasswordError(false);
  }, [oldPassword.value]);

  const validationForm = () => {
    if (!oldPassword.inputValid || !newPassword.inputValid || !retryPassword.inputValid) {
      throwNewToastError('Some field are not valid');
      return false;
    }

    if (newPassword.value !== retryPassword.value) {
      throwNewToastError('The passwords do not match');
      setRetryPasswordError(true);
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    if (validationForm()) {
      changePassword({oldPassword: oldPassword.value.trim(), newPassword: newPassword.value.trim()})
        .unwrap()
        .then(result => {
          if (result.status === 'ok') {
            throwNewToastSuccess('Password changed successfully');
            handleClose();
          }
        }).catch(error => {
        if ('status' in error && error.status === 403) {
          throwNewToastError('You need to verify your account');
          return;
        }

        if ('data' in error && error.data && 'error' in error.data && error.data.error) {
          if (error.data.error === 'Old password incorrect') {
            setOldPasswordError(true);
            throwNewToastError('Wrong password');
          } else {
            throwNewToastError(error.data.error);
          }
        }

        if ('data' in error && error.data && 'status' in error.data && error.data.status) {
          throwNewToastError(error.data.status);
        }

        console.log(error);
      });
    }
  };

  const setDisabledSubmit = () => {
    return !(oldPassword.inputValid && newPassword.inputValid && retryPassword.inputValid);
  };

  const handleSetOldPassVisible = () => {
    serOldPasswordIsVisible(!oldPasswordIsVisible);
  };

  const handleSetNewPassVisible = () => {
    setNewPasswordIsVisible(!newPasswordIsVisible);
  };

  return (
    <PasswordModal ref={ref} tabIndex={-1}>
      <PasswordCloseModalButtonBlock>
        <RemoveCrossButton onClick={handleClose}/>
      </PasswordCloseModalButtonBlock>
      <PasswordModalTitle>Change password</PasswordModalTitle>
      <PasswordInputBlock>
        <PasswordInputLabel>
          Old password
          <BasicAuthInput
            fieldType={'password'}
            fieldError={(oldPassword.isDirty && (oldPassword.isEmpty || oldPassword.maxLengthError || oldPassword.minLengthError) || oldPasswordError)}
            value={oldPassword.value}
            onChange={oldPassword.onChange}
            placeholder={'Enter old password'}
            type={oldPasswordIsVisible ? 'text' : 'password'}
            errorText={oldPassword.isDirty && !!oldPassword.error.length ? oldPassword.error : ''}
            isVisible={oldPasswordIsVisible}
            handleSetVisible={handleSetOldPassVisible}
            onBlur={oldPassword.onBlur}
            onFocus={oldPassword.onFocus}
          />
        </PasswordInputLabel>
        <PasswordInputLabel>
          New password
          <BasicAuthInput
            fieldType={'password'}
            fieldError={(newPassword.isDirty && (newPassword.isEmpty || newPassword.maxLengthError || newPassword.minLengthError || newPassword.correctSymbolsError)) || retryPasswordError}
            value={newPassword.value}
            onChange={newPassword.onChange}
            placeholder={'Enter new password'}
            type={newPasswordIsVisible ? 'text' : 'password'}
            errorText={newPassword.isDirty && !!newPassword.error.length ? newPassword.error : ''}
            isVisible={newPasswordIsVisible}
            handleSetVisible={handleSetNewPassVisible}
            onBlur={newPassword.onBlur}
            onFocus={newPassword.onFocus}
          />
        </PasswordInputLabel>
        <PasswordInputLabel>
          Repeat new password
          <BasicAuthInput
            fieldType={'password'}
            fieldError={(retryPassword.isDirty && (retryPassword.isEmpty || retryPassword.maxLengthError || retryPassword.minLengthError)) || retryPasswordError}
            value={retryPassword.value}
            onChange={retryPassword.onChange}
            placeholder={'Retry new password'}
            type={newPasswordIsVisible ? 'text' : 'password'}
            errorText={retryPassword.isDirty && !!retryPassword.error.length ? retryPassword.error : ''}
            isVisible={newPasswordIsVisible}
            handleSetVisible={handleSetNewPassVisible}
            onBlur={retryPassword.onBlur}
            onFocus={retryPassword.onFocus}
          />
        </PasswordInputLabel>
      </PasswordInputBlock>
      <BasicButton onClick={handleSubmit} size={'lg'} text={'Change'} variant={'blue'} disabled={setDisabledSubmit()}/>
      {isLoading && <CustomLoader size="lg" loadingText="Changing..." backdrop={true}/>}
    </PasswordModal>
  );
});

export default ChangePasswordModal;