import React, {useEffect, useState} from 'react';
import {
  ForgotButtonsBlock,
  ForgotFootnote, ForgotFormErrorText,
  ForgotInputsBlock, ForgotThanksBlock, ForgotThanksText,
  ForgotTitle,
  ForgotTitleBlock,
  ForgotWindow, PasswordChangedIcon
} from '../../styled';
import BasicAuthInput from '../../../../UI/BasicAuthInput';
import BasicButton from '../../../../UI/BasicButton';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {useResetPasswordFinalMutation} from '../../../../Store/APIs/Auth.api';
import {Loader} from 'rsuite';

const ResetPasswordWindow = () => {
  const {resetKey} = useParams();
  const navigate = useNavigate();

  const [passwordChanged, setPasswordChanged] = useState(false);

  const [newPassword, setNewPassword] = useState('');
  const [retryPassword, setRetryPassword] = useState('');

  const [newPasswordIsError, setNewPasswordIsError] = useState(false);
  const [retryPasswordIsError, setRetryPasswordIsError] = useState(false);

  const [newPasswordErrorText, setNewPasswordErrorText] = useState('');
  const [retryPasswordErrorText, setRetryPasswordErrorText] = useState('');

  const [formErrorText, setFormErrorText] = useState('');

  const [visiblePassword, setVisiblePassword] = useState(false);

  const [resetPasswordFinal, {isLoading}] = useResetPasswordFinalMutation();

  const handleSetNewPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value);
  };

  const handleSetRetryPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRetryPassword(event.target.value);
  };

  const handleSetVisiblePassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setVisiblePassword(!visiblePassword);
  };

  useEffect(() => {
    setNewPasswordIsError(false);
    setNewPasswordErrorText('');
    setRetryPasswordIsError(false);
    setRetryPasswordErrorText('');
  }, [newPassword, retryPassword]);

  const handleSendNewPassword = () => {
    if (!resetKey) {
      setFormErrorText('Error, try again later');
    }

    if (!newPassword.length || !retryPassword.length) {
      setNewPasswordIsError(true);
      setNewPasswordErrorText('Enter the password');
      setRetryPasswordIsError(true);
      setRetryPasswordErrorText('Enter the password');
      return;
    }

    if (newPassword.length < 8 || newPassword.length > 50) {
      setNewPasswordIsError(true);
      setNewPasswordErrorText('Length must be between 8 and 50 characters');
      return;
    }

    if (!/^[a-zA-Z0-9!@#$%^~&*()_+-=,.<>?;:'"{}[\]\\|]*$/.test(newPassword)) {
      setNewPasswordIsError(true);
      setNewPasswordErrorText('The password can only consist of Latin characters, numbers and special characters');
      return;
    }

    // if (!/[A-Z]/.test(newPassword)) {
    //   setNewPasswordIsError(true);
    //   setNewPasswordErrorText('The password must contain one capital letter');
    //   return;
    // }

    // if (!/[0-9]/.test(newPassword)) {
    //   setNewPasswordIsError(true);
    //   setNewPasswordErrorText('The password must contain one number');
    //   return;
    // }

    if (newPassword !== retryPassword) {
      setNewPasswordIsError(true);
      setNewPasswordErrorText('The passwords do not match');
      setRetryPasswordIsError(true);
      setRetryPasswordErrorText('The passwords do not match');

      return;
    }

    if (resetKey && newPassword.length && retryPassword.length) {
      resetPasswordFinal({password: newPassword.trim(), resetKey: resetKey}).then((result) => {
        if ('data' in result) {
          setPasswordChanged(true);
        }
        if ('error' in result) {
          if (result.error && 'status' in result.error && (result.error.status === 401)) {
            setFormErrorText('Wrong reset key, try again');
          }
        }
      });
    }
  };

  const handleReturn = () => {
    navigate('/authorization');
  };

  return (
    !passwordChanged ?
      <ForgotWindow>
        <ForgotTitleBlock>
          <ForgotTitle>Setup new password</ForgotTitle>
          <ForgotFootnote>Have you already reset the password? <Link to={'/authorization'}>Sign
            in</Link></ForgotFootnote>
        </ForgotTitleBlock>
        <ForgotInputsBlock>
          <BasicAuthInput
            fieldType={'password'}
            fieldError={newPasswordIsError}
            value={newPassword}
            onChange={handleSetNewPassword}
            placeholder={'New password'}
            type={visiblePassword ? 'text' : 'password'}
            isVisible={visiblePassword}
            handleSetVisible={handleSetVisiblePassword}
            errorText={newPasswordErrorText}
          />
          <BasicAuthInput
            fieldType={'password'}
            fieldError={retryPasswordIsError}
            value={retryPassword}
            onChange={handleSetRetryPassword}
            placeholder={'Repeat password'}
            type={visiblePassword ? 'text' : 'password'}
            isVisible={visiblePassword}
            handleSetVisible={handleSetVisiblePassword}
            errorText={retryPasswordErrorText}
          />
        </ForgotInputsBlock>
        <ForgotButtonsBlock>
          <BasicButton onClick={handleSendNewPassword} size={'lg'} text={'Submit'} variant={'blue'}/>
        </ForgotButtonsBlock>
        {!!formErrorText.length && <ForgotFormErrorText>{formErrorText}</ForgotFormErrorText>}
        {isLoading && <Loader backdrop size="lg" content="Sending..." vertical/>}
      </ForgotWindow> :
      <ForgotWindow>
        <ForgotThanksBlock>
          <ForgotTitle>New Password</ForgotTitle>
          <PasswordChangedIcon/>
          <ForgotThanksText>The password has been successfully changed</ForgotThanksText>
        </ForgotThanksBlock>
        <BasicButton onClick={handleReturn} size={'lg'} text={'OK'} variant={'blue'}/>
      </ForgotWindow>

  );
};

export default ResetPasswordWindow;