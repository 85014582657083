import React, {useState} from 'react';
import {MakePaymentContainer} from './styled';
import PaymentMethodsList from './Components/PaymentMethodsList';
import BasicButton from '../../../../UI/BasicButton';
import {Modal} from '@mui/material';
import PaymentWalletModal from './Modals/PaymentWalletModal';
import {useAppSelector} from '../../../../Hooks/redux/useAppSelector';

const MakePaymentModule = () => {
  const [openWalletModal, setOpenWalletModal] = useState(false);
  const {user} = useAppSelector(state => state.clientDataSlice);

  const handleOpenWalletModal = () => {
    setOpenWalletModal(true);
  };

  const handleCloseWalletModal = () => {
    setOpenWalletModal(false);
  };

  return (
    <MakePaymentContainer>
      <PaymentMethodsList/>
      <BasicButton onClick={handleOpenWalletModal} size={'md'} text={'Make a payment'} variant={'blue'}
                   disabled={user ? !user?.isVerify : true}/>
      <Modal open={openWalletModal} onClose={handleCloseWalletModal}>
        <PaymentWalletModal onClose={handleCloseWalletModal}/>
      </Modal>
    </MakePaymentContainer>
  );
};

export default MakePaymentModule;