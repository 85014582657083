import React from 'react';
import {ICampaignItem} from '../../../../../../../Models/ApiModels';
import {ArchiveModal, ArchiveModalButtonsBlock, ArchiveModalText, ArchiveModalTitle} from './styled';
import BasicButton from '../../../../../../../UI/BasicButton';
import {useArchiveCampaignMutation} from '../../../../../../../Store/APIs/Campaign.api';
import {Loader} from 'rsuite';

interface IArchiveOrderModalProps {
  orderInfo: ICampaignItem;
  onClose: () => void;
  fetchNewOrdersList: () => void;
}

const ArchiveOrderModal = React.forwardRef(({
                                              orderInfo,
                                              onClose,
                                              fetchNewOrdersList
                                            }: IArchiveOrderModalProps, ref: React.ForwardedRef<HTMLDivElement>) => {
  const [archiveCampaign, {isLoading}] = useArchiveCampaignMutation();

  const handleArchiveOrder = () => {
    archiveCampaign({campaign_id: orderInfo.id}).then(() => {
      fetchNewOrdersList();
      onClose();
    });
  };

  return (
    <>
      <ArchiveModal ref={ref} tabIndex={-1}>
        <ArchiveModalTitle>Attention!</ArchiveModalTitle>
        <ArchiveModalText>Are you sure you want to archive campaign <b>{orderInfo.id}</b>?</ArchiveModalText>
        <ArchiveModalButtonsBlock>
          <BasicButton onClick={handleArchiveOrder} size={'md'} text={'Archive campaign'} variant={'white'}/>
          <BasicButton onClick={onClose} size={'md'} text={'Close'} variant={'blue'}/>
        </ArchiveModalButtonsBlock>
      </ArchiveModal>
      {isLoading && <Loader backdrop size="lg" content="Archiving..." vertical/>}
    </>

  );
});

export default ArchiveOrderModal;