import styled from 'styled-components';
import {IThemeProvider} from '../../../../../../Theme/defaultTheme';
import {BasicModalWrapper} from '../../../../../../Theme/modalsElements';
import {BasicContentSpan} from '../../../../../../Theme/basicElements';

export const NewAppModalContainer = styled(BasicModalWrapper)`
  width: 512px;
`;

export const CountryDescription = styled.p<IThemeProvider>`
  font-family: ${props => props.theme.font_style.font_family};
  font-weight: ${props => props.theme.font_style.font_weight.regular};
  color: ${props => props.theme.colors.grey};
  font-size: ${props => props.theme.font_sizes.small};

  margin-bottom: 48px;
`;

export const CountryError = styled.span<IThemeProvider>`
  font-family: ${props => props.theme.font_style.font_family};
  font-weight: ${props => props.theme.font_style.font_weight.regular};
  color: ${props => props.theme.colors.secondary_red};
  font-size: ${props => props.theme.font_sizes.small};

  width: 100%;
  text-align: center;

  margin-bottom: 62px;
`;

export const ModalError = styled(BasicContentSpan)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 12px;
  color: ${props => props.theme.colors.secondary_red};
  width: max-content;
`;