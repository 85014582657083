import React from 'react';
import {
  AuthInputs,
  AuthInputsBlock
} from '../../AuthWindow/styled';
import {
  ERROR_INCORRECT_EMAIL,
  ERROR_INCORRECT_PASSWORD_FIELD,
  ERROR_INCORRECT_RETRY_PASSWORD_FIELD,
  ERROR_NO_VALID_PASSWORD,
  ERROR_USER_ALREADY_EXIST
} from '../../../Constants/AuthErrorsList';
import {IAuthFormProps} from '../../../Models';
import BasicAuthInput from '../../../../../UI/BasicAuthInput';

const RegForm: React.FC<IAuthFormProps> = ({
                                             validationErrors,
                                             handleSetPassword,
                                             password,
                                             handleSetVisible,
                                             handleSetEmail,
                                             isVisible,
                                             username,
                                             retryPassword = '',
                                             handleSetRetryPassword
                                           }) => {
  return (
    <AuthInputsBlock>
      <AuthInputs>
        <BasicAuthInput
          type={'text'}
          placeholder={'E-mail'}
          onChange={handleSetEmail}
          value={username}
          fieldType={'email'}
          fieldError={validationErrors.includes(ERROR_INCORRECT_EMAIL) || validationErrors.includes(ERROR_USER_ALREADY_EXIST)}
          errorText={validationErrors.includes(ERROR_INCORRECT_EMAIL) ? ERROR_INCORRECT_EMAIL : ''}
        />
        <BasicAuthInput
          fieldType={'password'}
          fieldError={validationErrors.includes(ERROR_INCORRECT_PASSWORD_FIELD) || validationErrors.includes(ERROR_NO_VALID_PASSWORD)}
          value={password}
          onChange={handleSetPassword}
          placeholder={'Password'}
          type={isVisible ? 'text' : 'password'}
          errorText={validationErrors.includes(ERROR_INCORRECT_PASSWORD_FIELD) ? ERROR_INCORRECT_PASSWORD_FIELD : validationErrors.includes(ERROR_NO_VALID_PASSWORD) ? ERROR_NO_VALID_PASSWORD : ''}
          isVisible={isVisible}
          handleSetVisible={handleSetVisible}
        />
        {!!handleSetRetryPassword && <BasicAuthInput
          fieldType={'password'}
          fieldError={validationErrors.includes(ERROR_INCORRECT_RETRY_PASSWORD_FIELD)}
          errorText={validationErrors.includes(ERROR_INCORRECT_RETRY_PASSWORD_FIELD) ? ERROR_INCORRECT_RETRY_PASSWORD_FIELD : ''}
          value={retryPassword}
          onChange={handleSetRetryPassword}
          placeholder={'Confirm password'}
          type={isVisible ? 'text' : 'password'}
          isVisible={isVisible}
          handleSetVisible={handleSetVisible}
        />}
      </AuthInputs>
    </AuthInputsBlock>
  );
};

export default RegForm;