import styled from 'styled-components';
import {IThemeProvider} from '../../Theme/defaultTheme';

import search from '../../Images/svg/search.svg';

interface IStyledInputBlock {
  size :'md' | 'lg';
}

export const StyledInputBlock = styled.label<IThemeProvider & IStyledInputBlock>`
  width: ${props => props.size === 'md' ? '397px' : '765px'};
  padding: ${props => props.theme.format.main_md_padding};
  box-sizing: border-box;
  display: flex;
  gap: ${props => props.theme.format.main_sm_gap};
  align-items: center;
  border: 1px solid ${props => props.theme.colors.grey_semi};
  border-radius: ${props => props.theme.format.main_md_border_radius};

  &:focus-within {
    border: 1px solid ${props => props.theme.colors.grey};
  }

  &::before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background-image: url(${search});
    background-repeat: no-repeat;
    background-size: contain;
  }
`;

export const StyledInput = styled.input<IThemeProvider>`
  width: 100%;
  border: 0;
  background-color: transparent;
  font-family: ${props => props.theme.font_style.font_family};
  font-size: ${props => props.theme.font_sizes.content};
  font-weight: ${props => props.theme.font_style.font_weight.regular};

  &:focus-visible {
    outline: 0;
    border: 0;
  }
`;