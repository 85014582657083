import React from 'react';
import {
  CountryItemDuplicateError,
  CountryItemSelectorRow,
  CountryItemSelectorRowLabel,
  CountryItemSelectorRowTitle
} from './styled';
import HelpButton from '../../../../../Components/HelpButton';
import {CountrySelector} from '../../../../../../../Components/Selectors';
import {BasicRedAccentSpan} from '../../../../../../../Theme/basicElements';

interface ICountrySelectorRowProps {
  selectedCountry: string | null;
  handleSetCountryGeo: (geo: string | null) => void;
  isDuplicate: boolean;
}

const CountrySelectorRow: React.FC<ICountrySelectorRowProps> = ({
                                                                  selectedCountry,
                                                                  handleSetCountryGeo,
                                                                  isDuplicate
                                                                }) => {

  return (
    <CountryItemSelectorRow>
      <CountryItemSelectorRowLabel>
        <CountryItemSelectorRowTitle>Country <BasicRedAccentSpan>*</BasicRedAccentSpan></CountryItemSelectorRowTitle>
        <HelpButton popupText={'Select the country of application promotion'}/>
      </CountryItemSelectorRowLabel>
      <CountrySelector
        size={'sm'}
        placeholder={'Choose country'}
        handleSelectCountry={handleSetCountryGeo}
        selectedCountry={selectedCountry}
      />
      {isDuplicate && <CountryItemDuplicateError>This country is already exist</CountryItemDuplicateError>}
    </CountryItemSelectorRow>
  );
};

export default CountrySelectorRow;