import styled, {keyframes} from 'styled-components';
import {IThemeProvider} from '../../Theme/defaultTheme';

import hideMark from '../../Images/svg/nav-hide-mark.svg';

interface IItemActive {
  readonly 'data-active-link'?: boolean;
}

interface IAsideOpen {
  readonly 'data-rolled': boolean;
}

const slideOutAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
`;

const slideInAnimation = keyframes`
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%);
  }
`;

export const StyledAside = styled.aside<IThemeProvider>`
  width: 100%;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.brand_blue};
  height: calc(100vh - ${props => props.theme.format.header_height});
  position: sticky;
  top: ${props => props.theme.format.header_height};
`;

export const NavList = styled.nav<IThemeProvider>`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.format.main_lg_padding};
  box-sizing: border-box;

  a {
    display: block;
    width: 100%;
    text-decoration: none;
  }

  a:focus-visible {
    border: 0;
    outline: 0;

    & div {
      background-color: ${props => props.theme.colors.brand_hover};
    }
  }
`;

export const NavItem = styled.div <IThemeProvider & IItemActive>`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: ${props => props.theme.format.main_md_padding};
  gap: 12px;
  background-color: ${(props) => props.theme.colors.brand_blue};
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  overflow: hidden;

  &[data-active-link='true'] {
    background-color: ${props => props.theme.colors.brand_purple};
  }

  img {
    width: 24px;
    height: 24px;
    object-fit: contain;

  }

  &:hover {
    background-color: ${props => props.theme.colors.brand_hover};
  }
`;

export const NavText = styled.span<IAsideOpen & IThemeProvider>`
  color: ${(props) => props.theme.colors.white};
  white-space: nowrap;
  font-family: ${props => props.theme.font_style.font_family};
  font-weight: ${props => props.theme.font_style.font_weight.regular};
  font-size: ${props => props.theme.font_sizes.content};

  animation-duration: 500ms;
  animation-fill-mode: forwards;
  animation-name: ${slideInAnimation};

  &[data-rolled='true'] {
    animation-name: ${slideOutAnimation};
  }

  overflow: hidden;

  &:hover {
    text-decoration: none;
  }
`;

export const HideButton = styled.button<IThemeProvider & IAsideOpen>`
  position: absolute;
  bottom: 120px;
  left: 50%;
  transform: translateX(-50%);

  width: 56px;
  height: 56px;
  background-color: ${props => props.theme.colors.brand_blue};
  border: 0;
  border-radius: ${props => props.theme.format.small_border_radius};
  align-self: center;
  padding: 0;

  &::before {
    content: '';
    width: 8px;
    height: 12px;
    background-image: url(${hideMark});
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: ease-in 500ms;
  }

  &[data-rolled='false'] {
    &::before {
      transform: translateX(-50%) translateY(-50%) rotateY(180deg);
    }
  }

  &::after {
    content: '';
    width: 24px;
    height: 24px;
    display: block;
    position: absolute;
    border-radius: 5px;
    border: 1px solid ${props => props.theme.colors.white};

    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  &:hover {
    background-color: ${props => props.theme.colors.brand_hover};
  }

  &:focus-visible {
    outline: 0;
    background-color: ${props => props.theme.colors.brand_hover};
  }
`;