import styled from 'styled-components';
import {IThemeProvider} from '../../Theme/defaultTheme';

import clockIcon from '../../Images/svg/date-icon.svg';

export const DateFilterBlock = styled.div<IThemeProvider>`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.format.main_md_padding};
  z-index: 2;

  &:before {
    content: '';
    display: block;
    width: 32px;
    height: 32px;
    background-image: url(${clockIcon});
    background-repeat: no-repeat;
    background-size: contain;
  }
`;

export const DateInput = styled.div`
  position: relative;
  width: 210px;
  box-sizing: border-box;

  & .react-datepicker-wrapper {
    display: block;
    z-index: 2;
  }
`;

export const StyledDataPicker = styled.input<IThemeProvider>`
  padding: ${props => props.theme.format.main_md_padding};
  display: block;
  border: 1px solid ${props => props.theme.colors.grey_semi};
  border-radius: ${props => props.theme.format.main_md_border_radius};
  background-color: transparent;
  width: 100%;

  font-family: ${props => props.theme.font_style.font_family};
  font-weight: ${props => props.theme.font_style.font_weight.regular};
  font-size: ${props => props.theme.font_sizes.content};

  color: ${props => props.theme.colors.black};
  box-sizing: border-box;
  cursor: pointer;
`;

