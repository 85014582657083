import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {LS_ORDERS_COLUMNS_SETTINGS} from '../Constants/OrdersLSConstants';
import {setDataToStorage} from '../../../Utils/localStorageHelpers';

export interface IColumnsState {
  [key: string]: boolean;
}

const initialZeroState: IColumnsState = {
  id: true,
  app: true,
  geo: true,
  type: true,
  count: true,
  days: true,
  price: true,
  startDate: true,
  progress: true,
  status: true,
};

const storageValue = localStorage.getItem(LS_ORDERS_COLUMNS_SETTINGS);

const initialState: IColumnsState = storageValue ? JSON.parse(storageValue) : initialZeroState;

export const myOrdersColumnsSlice = createSlice({
  name: 'ordersSlice',
  initialState,
  reducers: {
    setOrdersColumns(state, action: PayloadAction<IColumnsState>) {
      setDataToStorage(LS_ORDERS_COLUMNS_SETTINGS, action.payload);

      return action.payload;
    },
    toggleAllColumns(state, action: PayloadAction<boolean>) {
      const newState: IColumnsState = Object.fromEntries(Object.keys(state).map(key => [key, Boolean(action.payload)]));

      setDataToStorage(LS_ORDERS_COLUMNS_SETTINGS, newState);
      return newState;
    }

  }
});

export const myOrdersColumnsActions = myOrdersColumnsSlice.actions;

export const myOrdersColumnsReducer = myOrdersColumnsSlice.reducer;