import styled from 'styled-components';
import {IThemeProvider} from '../../../Theme/defaultTheme';

export const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const ContentItem = styled.div<IThemeProvider>`
  display: flex;
  flex-direction: column;
  font-family: ${props => props.theme.font_style.font_family};
`;

export const ContentTitle = styled.h4<IThemeProvider>`
  margin-bottom: 24px;
  font-size: 18px;
`;

export const ContentText = styled.p``;

export const ContentListBlock = styled.div`
  padding: 24px 16px 0;
`;

export const ContentList = styled.ul`
  padding: 0;
`;

export const ContentListItem = styled.li``;