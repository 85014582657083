import styled from 'styled-components';
import {IThemeProvider} from '../../../../Theme/defaultTheme';

export const CostTableContainer = styled.div``;

export const CostTable = styled.table``;

export const CostTableThead = styled.thead``;

export const CostTableTbody = styled.tbody``;

export const CostTableTr = styled.tr`
  &:nth-child(even) {
    background: ${props => props.theme.colors.primary_light};
  }
`;

export const CostTableTh = styled.th<IThemeProvider>`
  border-bottom: 1px solid ${props => props.theme.colors.grey_semi};
  background: ${props => props.theme.colors.primary_light};
  min-width: 110px;
  padding: 16px 8px;

  font-family: ${props => props.theme.font_style.font_family};
  font-weight: ${props => props.theme.font_style.font_weight.regular};
  font-size: ${props => props.theme.font_sizes.content};
  color: ${props => props.theme.colors.grey};
`;

export const CostTableTd = styled.td`
  width: max-content;
  padding: 16px;

  font-family: ${props => props.theme.font_style.font_family};
  font-weight: ${props => props.theme.font_style.font_weight.regular};
  font-size: ${props => props.theme.font_sizes.content};
  color: ${props => props.theme.colors.black};

  text-align: center;
`;