import styled from 'styled-components';
import {BasicOrderFormRow, BasicOrderFormRowLabel, BasicOrderFormRowTitle} from '../../../../../Styles/newOrdersBasics';
import {IThemeProvider} from '../../../../../../../Theme/defaultTheme';

export const KeywordsControlBlock = styled.div<IThemeProvider>`
  display: flex;
  gap: ${props => props.theme.format.main_lg_gap};
`;

export const KeywordsAdditionRow = styled(BasicOrderFormRow)`
  align-items: flex-start;
`;

export const KeywordsAdditionLabel = styled(BasicOrderFormRowLabel)<IThemeProvider>`
  width: 139px;
  padding: ${props => props.theme.format.main_md_padding} 0;
`;

export const KeywordsAdditionTitle = styled(BasicOrderFormRowTitle)``;

export const KeywordsAdditionTextarea = styled.textarea<IThemeProvider>`
  width: 256px;

  max-width: 400px;
  max-height: 400px;

  overflow-y: auto;

  background-color: ${props => props.theme.colors.white};
  font-family: ${props => props.theme.font_style.font_family};
  font-weight: ${props => props.theme.font_style.font_weight.regular};
  font-size: ${props => props.theme.font_sizes.content};

  border: 1px solid ${props => props.theme.colors.grey_semi};
  border-radius: ${props => props.theme.format.main_md_border_radius};
  padding: ${props => props.theme.format.main_md_padding};
`;

