import styled from 'styled-components';
import {IThemeProvider} from '../../../../Theme/defaultTheme';

export const OrdersFilterBlock = styled.div<IThemeProvider>`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${props => props.theme.format.main_lg_gap};
`;

export const OrdersTableBlock = styled.div<IThemeProvider>`
  width: 100%;
  background-color: ${props => props.theme.colors.primary_light};
  padding: ${props => props.theme.format.main_lg_padding};
  border-radius: ${props => props.theme.format.main_lg_border_radius};
  box-sizing: border-box;
`;