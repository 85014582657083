import React, {memo, useEffect, useRef, useState} from 'react';
import {OrdersTbody, OrdersTable, OrdersTableContainer, OrdersTableScrollWrapper} from './styled';
import {useLazyGetCampaignsQuery} from '../../../../Store/APIs/Campaign.api';
import OrdersTableHead from './Components/OrdersTableHead';
import {useAppSelector} from '../../../../Hooks/redux/useAppSelector';
import OrdersCampaignItem from './Components/OrdersCampaignItem';
import {Modal} from '@mui/material';
import SettingsTableModal from './Components/Modals/SettingsTableModal';
import {ICampaignItem} from '../../../../Models/ApiModels';
import BlockLoader from '../../../../Components/Loaders/BlockLoader';
import {BasicEmptyTableDataText} from '../../../../Theme/basicElements';

let ordersListIsEnded = false;

const OrdersTableModule = memo(() => {
  const [openSettingsModal, setOpenSettingsModal] = useState(false);

  const [fetchOffset, setFetchOffset] = useState(0);
  const [fetchLimit, setFetchLimit] = useState(20);

  const [ordersList, setOrdersList] = useState<ICampaignItem[]>([]);
  const [isFetching, setIsFetching] = useState(true);

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const {selectedAppId, selectedStatus, dateStart, dateStop} = useAppSelector(state => state.myOrdersFiltersSlice);

  const [getOrdersList, {isLoading}] = useLazyGetCampaignsQuery();

  useEffect(() => {
    if (scrollContainerRef.current) {
      const container = scrollContainerRef.current;
      container.addEventListener('scroll', handleScroll);

      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const fetchNewOrdersList = async () => {
    ordersListIsEnded = false;

    await getOrdersList({
      app: selectedAppId,
      status: selectedStatus,
      dateStart: dateStart,
      dateStop: dateStop,
      offset: 0,
      limit: 21
    }).then(({data}) => {
      if (data) {
        setOrdersList(data);
        setFetchOffset(21);

        if (data?.length < 21) {
          ordersListIsEnded = true;
        }
      }
    });
  };


  useEffect(() => {
    ordersListIsEnded = false;
    setOrdersList([]);
    setFetchOffset(0);
    setIsFetching(true);
  }, [selectedAppId, selectedStatus, dateStart, dateStop]);

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      const container = scrollContainerRef.current;
      const isEndReached =
        container.scrollHeight - (container.scrollTop + container.clientHeight) < 100;

      if (isEndReached && !ordersListIsEnded) {
        setIsFetching(true);
      }
    }
  };

  useEffect(() => {
    if (!isFetching) return;

    getOrdersList({
      app: selectedAppId,
      status: selectedStatus,
      dateStart: dateStart,
      dateStop: dateStop,
      offset: fetchOffset,
      limit: fetchLimit
    }).then(({data}) => {
      if (data) {
        setOrdersList([...ordersList, ...data]);
        setFetchOffset(fetchOffset + 20);

        if (data?.length !== 20) {
          ordersListIsEnded = true;
        }
      }
    }).finally(() => {
      setIsFetching(false);
    });
  }, [isFetching]);

  const handleToggleSettingsModal = () => {
    setOpenSettingsModal(!openSettingsModal);
  };

  return (
    <>
      <OrdersTableContainer>
        <OrdersTableScrollWrapper ref={scrollContainerRef}>
          <OrdersTable>
            <OrdersTableHead handleOpenSettingsModal={handleToggleSettingsModal}/>
            {(!!ordersList.length && !isLoading) &&
              <OrdersTbody>
                {ordersList.map((order) =>
                  <OrdersCampaignItem
                    key={order.id}
                    orderInfo={order}
                    fetchNewOrdersList={fetchNewOrdersList}
                  />
                )}
              </OrdersTbody>
            }
          </OrdersTable>
          {(!ordersList.length && isFetching) && <BlockLoader size={'lg'}/>}
          {(!ordersList.length && !isLoading && !isFetching) &&
            <BasicEmptyTableDataText>No orders</BasicEmptyTableDataText>}
        </OrdersTableScrollWrapper>
      </OrdersTableContainer>
      <Modal open={openSettingsModal} onClose={handleToggleSettingsModal}>
        <SettingsTableModal handleToggleSettingsModal={handleToggleSettingsModal}/>
      </Modal>
    </>
  );
});

export default OrdersTableModule;