import React from 'react';
import {StyledText} from "./styled";
import {ORDERS_STATUSES} from "../../../../Constants/OrdersStatuses";

interface IStatusItem {
  status: number
}

const StatusItem: React.FC<IStatusItem> = React.memo(({status}) => {
  return (
    <StyledText data-status={status}>
      {ORDERS_STATUSES.find(s => s.number === status)?.title}
    </StyledText>
  );
});

export default StatusItem;