import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {useGetCampaignInfoQuery} from '../../../../Store/APIs/Campaign.api';
import CreateCampaignFormPresenter from '../../Presentor';
import {checkThePastDate, getFormattedDate} from '../../../../Utils/DateUtils';
import {getDataFromStorage, setDataToStorage} from '../../../../Utils/localStorageHelpers';
import {
  LS_DUPLICATE_ORDER_ATTRIBUTES, LS_DUPLICATE_ORDER_COUNTRIES,
  LS_DUPLICATE_ORDER_DATE_START,
  LS_DUPLICATE_ORDER_DATE_STOP, LS_DUPLICATE_ORDER_DELAY, LS_DUPLICATE_ORDER_ID
} from '../../Constants/NewCampaignLSConstants';
import {ICountryModel} from '../../Models/CountryModel';
import {useActions} from '../../../../Hooks/redux/useActions';
import {ICampaignInfo} from '../../../../Models/ApiModels';
import {useAppSelector} from '../../../../Hooks/redux/useAppSelector';
import {ParseDuplicateKeywords} from '../../Helpers/CampaignParsers';
import {Loader} from 'rsuite';

interface IDuplicateOrderModuleProps {
  id: number;
  handleCreateNewOrder: () => void;
}

const DuplicateOrderModule = forwardRef(({id, handleCreateNewOrder}: IDuplicateOrderModuleProps, ref) => {
  const [campaignData, setCampaignData] = useState<ICampaignInfo | null>(null);

  const {dateStart, dateStop, delayedStartValue} = useAppSelector(state => state.newOrderSlice);

  const internalRef = useRef();

  const {data, isLoading} = useGetCampaignInfoQuery(id);

  const {
    setOrderDateStart,
    setOrderDateStop,
    setOrderType,
    setOrderApp,
    setOrderAttributes,
    setOrderDelayedStart,
    setCountriesList,
    clearAllStateFields,
    setActiveScenario
  } = useActions();

  useEffect(() => {
    setActiveScenario('duplicate');

    return () => {
      clearAllStateFields();
    };
  }, []);

  useEffect(() => {
    if (data) {
      setCampaignData(data);
      setOrderType(data.type);
      setOrderApp(data.app);

      if (Number(getDataFromStorage(LS_DUPLICATE_ORDER_ID)) === id) {
        const storageDateStart = getDataFromStorage(LS_DUPLICATE_ORDER_DATE_START);
        const storageDateStop = getDataFromStorage(LS_DUPLICATE_ORDER_DATE_STOP);
        const storageAttributes = getDataFromStorage(LS_DUPLICATE_ORDER_ATTRIBUTES);
        const storageDelay = getDataFromStorage(LS_DUPLICATE_ORDER_DELAY);

        if (storageDateStart && checkThePastDate(storageDateStart) && storageDateStop && checkThePastDate(storageDateStop)) {
          setOrderDateStart(storageDateStart);
          setOrderDateStop(storageDateStop);
        }
        !!storageAttributes && setOrderAttributes(Number(storageAttributes));
        setOrderDelayedStart(storageDelay);
      } else {
        setDataToStorage(LS_DUPLICATE_ORDER_ID, id);
        handleSetAttributesValue(data.settings_message ? data.settings_message : 0);
        handleSetDelayStartValue(data.delayed_start ? data.delayed_start : null);
        localStorage.removeItem(LS_DUPLICATE_ORDER_COUNTRIES);
        localStorage.removeItem(LS_DUPLICATE_ORDER_DATE_START);
        localStorage.removeItem(LS_DUPLICATE_ORDER_DATE_STOP);
      }
    }
  }, [data]);

  useEffect(() => {
    const storageDateStart = getDataFromStorage(LS_DUPLICATE_ORDER_DATE_START);
    const storageDateStop = getDataFromStorage(LS_DUPLICATE_ORDER_DATE_STOP);
    const storageCountries = localStorage.getItem(LS_DUPLICATE_ORDER_COUNTRIES);

    if (storageDateStart === dateStart && storageDateStop === dateStop) {
      setCountriesList(storageCountries ? JSON.parse(storageCountries) : []);
    } else if (dateStart && dateStop && campaignData) {
      const newCountries = ParseDuplicateKeywords(campaignData, dateStart, dateStop);

      setCountriesList(newCountries);
      handleSetCountriesListInStorage(newCountries);
      setDataToStorage(LS_DUPLICATE_ORDER_DATE_START, dateStart);
      setDataToStorage(LS_DUPLICATE_ORDER_DATE_STOP, dateStop);
    }
  }, [campaignData, dateStart, dateStop]);

  const handleSetOrderApp = (appId: number) => {
    setOrderApp(appId);
  };

  const handleSetOrderType = (type: string | null) => {
    setOrderType(type);
  };

  const handleSetDateStart = (date: Date | null) => {
    setOrderDateStart(date ? getFormattedDate(date) : null);

    if (date && delayedStartValue) {
      handleSetDelayStartValue(null);
    }
  };

  const handleSetDateStop = (date: Date | null) => {
    setOrderDateStop(date ? getFormattedDate(date) : null);
  };

  const handleSetAttributesValue = (value: number) => {
    setOrderAttributes(value);
    if (value) {
      setDataToStorage(LS_DUPLICATE_ORDER_ATTRIBUTES, value);
    } else {
      localStorage.removeItem(LS_DUPLICATE_ORDER_ATTRIBUTES);
    }
  };

  const handleSetDelayStartValue = (dateString: string | null) => {
    setOrderDelayedStart(dateString);
    if (dateString) {
      setDataToStorage(LS_DUPLICATE_ORDER_DELAY, dateString);
    } else {
      localStorage.removeItem(LS_DUPLICATE_ORDER_DELAY);
    }
  };

  const handleSetCountriesListInStorage = (countriesList: ICountryModel[]) => {
    if (countriesList.length) {
      setDataToStorage(LS_DUPLICATE_ORDER_COUNTRIES, countriesList);
    } else {
      localStorage.removeItem(LS_DUPLICATE_ORDER_COUNTRIES);
    }
  };

  const handleResetAllForms = () => {
    handleSetDateStart(null);
    handleSetDateStop(null);
    localStorage.removeItem(LS_DUPLICATE_ORDER_DATE_START);
    localStorage.removeItem(LS_DUPLICATE_ORDER_DATE_STOP);
    handleSetAttributesValue(0);
    handleSetCountriesListInStorage([]);
    setCountriesList([]);
  };

  const handleCreateNewCampaign = () => {
    handleCreateNewOrder();
  };

  useImperativeHandle(ref, () => ({
    childFunction: handleResetAllForms,
    internalRef: internalRef.current,
  }));


  return (
    <>
      <CreateCampaignFormPresenter
        scenario={'duplicate'}
        handleResetAllForms={handleResetAllForms}
        handleCreateNewCampaign={handleCreateNewCampaign}
        handleSetDateStop={handleSetDateStop}
        handleSetDateStart={handleSetDateStart}
        handleSetAttributesValue={handleSetAttributesValue}
        handleSetCountriesListInStorage={handleSetCountriesListInStorage}
        handleSetOrderApp={handleSetOrderApp}
        handleSetOrderType={handleSetOrderType}
        handleSetDelayStartValue={handleSetDelayStartValue}
      />
      {isLoading && <Loader backdrop size="lg" content="Loading..." vertical/>}
    </>
  );
});

export default DuplicateOrderModule;