import React, {useState} from 'react';
import {
  ForgotButtonsBlock,
  ForgotEmailIcon,
  ForgotFootnote, ForgotThanksBlock, ForgotThanksText,
  ForgotTitle,
  ForgotTitleBlock,
  ForgotWindow
} from '../../styled';
import BasicAuthInput from '../../../../UI/BasicAuthInput';
import BasicButton from '../../../../UI/BasicButton';
import {validateEmail} from '../../../AuthPage/Helpers/ValidationHelpers';
import {useResetPasswordMutation} from '../../../../Store/APIs/Auth.api';
import {Loader} from 'rsuite';

interface ISendEmailWindowProps {
  handleReturn: () => void;
  captchaToken: string;
}

const SendEmailWindow: React.FC<ISendEmailWindowProps> = ({handleReturn, captchaToken}) => {
  const [emailValue, setEmailValue] = useState('');
  const [emailFieldError, setEmailFieldError] = useState(false);

  const [emailSent, setEmailSent] = useState(false);

  const [sendEmail, {isLoading}] = useResetPasswordMutation();

  const handleSetEmailValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailFieldError(false);
    setEmailValue(event.target.value);
  };

  const handleSendEmail = () => {
    if (emailValue.length && validateEmail(emailValue) && captchaToken) {
      sendEmail({login: emailValue, token: captchaToken}).then(result => {
        if ('data' in result) {
          setEmailSent(true);
        }
      });
    } else {
      setEmailFieldError(true);
    }
  };

  return (
    <>
      {!emailSent ?
        <ForgotWindow>
          <ForgotTitleBlock>
            <ForgotTitle>Forgot password</ForgotTitle>
            <ForgotFootnote>Enter your email to reset password</ForgotFootnote>
          </ForgotTitleBlock>
          <BasicAuthInput
            fieldType={'email'}
            fieldError={emailFieldError}
            value={emailValue}
            onChange={handleSetEmailValue}
            placeholder={'E-mail'}
            type={'text'}
            errorText={'Incorrect email'}
          />
          <ForgotButtonsBlock>
            <BasicButton onClick={handleSendEmail} size={'lg'} text={'Submit'} variant={'blue'}/>
            <BasicButton onClick={handleReturn} size={'lg'} text={'Back'} variant={'white'}/>
          </ForgotButtonsBlock>
          {isLoading && <Loader backdrop size="lg" content="Sending..." vertical/>}
        </ForgotWindow> :
        <ForgotWindow>
          <ForgotThanksBlock>
            <ForgotTitle>Thank you!</ForgotTitle>
            <ForgotEmailIcon/>
            <ForgotThanksText>Your message has been sent, please check your email</ForgotThanksText>
          </ForgotThanksBlock>
          <BasicButton onClick={handleReturn} size={'lg'} text={'OK'} variant={'blue'}/>
        </ForgotWindow>
      }
    </>

  );
};

export default SendEmailWindow;