import React, {useState} from 'react';
import {SettingsItem, SettingsList, SettingsModal} from './styled';
import {BasicModalTitle} from '../../../../../../../Theme/modalsElements';
import BasicCheckboxButton from '../../../../../../../UI/BasicCheckboxButton';
import BasicButton from '../../../../../../../UI/BasicButton';
import {useAppSelector} from '../../../../../../../Hooks/redux/useAppSelector';
import {COLUMNS_LIST} from '../../../../../Constants/OrdersColumns';
import {useActions} from '../../../../../../../Hooks/redux/useActions';

interface ISettingsTableModalProps {
  handleToggleSettingsModal: () => void;
}

const SettingsTableModal = React.forwardRef(({handleToggleSettingsModal}: ISettingsTableModalProps, ref: React.ForwardedRef<HTMLDivElement>) => {
  const selectedColumns = useAppSelector(state => state.myOrdersColumnsSlice);
  const [newSelectedColumns, setNewSelectedColumns] = useState(selectedColumns);

  const {setOrdersColumns} = useActions();

  const handleChooseColumn = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewSelectedColumns({...newSelectedColumns, [event.target.value]: event.target.checked});
  };

  const handleChooseAllColumn = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewSelectedColumns(Object.fromEntries(Object.keys(newSelectedColumns).map(key => [key, Boolean(event.target.checked)])));
  };

  const handleSubmit = () => {
    setOrdersColumns(newSelectedColumns);
    handleToggleSettingsModal();
  };

  return (
    <SettingsModal ref={ref} tabIndex={-1}>
      <BasicModalTitle>Columns settings</BasicModalTitle>
      <SettingsList>
        <SettingsItem>
          <BasicCheckboxButton
            checked={!Object.values(newSelectedColumns).includes(false)}
            value={'all'}
            name={'settings'}
            onChange={handleChooseAllColumn}/>
          All columns
        </SettingsItem>
        {COLUMNS_LIST.map(column => (
          <SettingsItem key={column.key}>
            <BasicCheckboxButton
              checked={newSelectedColumns[column.key]}
              value={column.key}
              name={'settings'}
              onChange={handleChooseColumn}/>
            {column.label}
          </SettingsItem>
        ))}
      </SettingsList>
      <BasicButton onClick={handleSubmit} size={'lg'} text={'Save'} variant={'blue'}/>
    </SettingsModal>
  );
});

export default SettingsTableModal;