import styled from 'styled-components';
import {BasicModalTitle, BasicModalWrapper} from '../../../../Theme/modalsElements';
import {IThemeProvider} from '../../../../Theme/defaultTheme';

export const PasswordModal = styled(BasicModalWrapper)``;

export const PasswordModalTitle = styled(BasicModalTitle)``;

export const PasswordInputBlock = styled.div<IThemeProvider>`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.format.main_lg_gap};
  margin-bottom: 48px;
`;

export const PasswordInputLabel = styled.div<IThemeProvider>`
  font-family: ${props => props.theme.font_style.font_family};
  font-size: ${props => props.theme.font_sizes.content};
  color: ${props => props.theme.colors.black};

  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
`;

export const PasswordCloseModalButtonBlock = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  top: 32px;
  right: 36px;
`;