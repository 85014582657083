import React, {forwardRef} from 'react';
import {BasicModalWrapper} from '../../../../../../../Theme/modalsElements';
import {BasicContentParagraph} from '../../../../../../../Theme/basicElements';
import BasicButton from '../../../../../../../UI/BasicButton';
import {CancelDelayedOrderModalTextBlock, CancelDelayedOrderModalTitle} from './styled';

interface CancelDelayedOrderModalProps {
  onClose: () => void;
}

const CancelDelayedOrderModal: React.ForwardRefExoticComponent<CancelDelayedOrderModalProps> = forwardRef(({onClose}, ref: React.ForwardedRef<HTMLDivElement>) => {
  return (
    <BasicModalWrapper ref={ref} tabIndex={-1}>
      <CancelDelayedOrderModalTitle>Delayed campaign cancelling</CancelDelayedOrderModalTitle>
      <CancelDelayedOrderModalTextBlock>
        <BasicContentParagraph>Check with the manager about the possibility of canceling the campaign for
          today.</BasicContentParagraph>
        <BasicContentParagraph>The remaining days of the campaign, if any, will be stopped
          automatically.</BasicContentParagraph>
      </CancelDelayedOrderModalTextBlock>

      <BasicButton onClick={onClose} size={'lg'} text={'OK'} variant={'blue'}/>
    </BasicModalWrapper>
  );
});

export default CancelDelayedOrderModal;