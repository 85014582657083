import React from 'react';
import {PopupButton, PopupContainer} from "./styled";
import {useNavigate} from "react-router-dom";

interface IPromotePopoverProps {
  campaignId: number
}

const PromotePopover: React.FC<IPromotePopoverProps> = ({campaignId}) => {
  const navigate = useNavigate()

  const handleClickRecreate = () => {
    navigate(`/create_campaign/duplicate/${campaignId}`)
  }

  const handleClickExtend = () => {
    navigate(`/create_campaign/extend/${campaignId}`)
  }

  return (
    <PopupContainer>
      <PopupButton onClick={handleClickRecreate}>Recreate</PopupButton>
      <PopupButton onClick={handleClickExtend}>Extend</PopupButton>
    </PopupContainer>
  );
};

export default PromotePopover;