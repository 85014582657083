import styled from 'styled-components';
import {IThemeProvider} from '../../../../../../Theme/defaultTheme';

export const AddCard = styled.button<IThemeProvider>`
  width: 31%;
  min-width: 290px;
  min-height: 200px;

  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  box-sizing: border-box;
  padding: ${props => props.theme.format.main_lg_padding} 30px;
  background: conic-gradient(from 153deg at 50% 50%, rgba(98, 132, 255, 0.40) 40deg, rgba(255, 114, 182, 0.40) 100deg, rgba(249, 229, 232, 0.40) 176deg, rgba(151, 225, 212, 0.40) 277deg, rgba(98, 132, 255, 0.40) 359deg);
  border-radius: ${props => props.theme.format.main_lg_border_radius};
  cursor: pointer;

  @media (max-width: 1300px) {
    width: 47%;
  }

  @media (max-width: 900px) {
    width: 100%;
  }

  &:hover div {
    background-color: ${props => props.theme.colors.white_80};
  }
`;

export const AddCardTextBlock = styled.div<IThemeProvider>`
  background-color: ${props => props.theme.colors.white_60};
  display: flex;
  gap: ${props => props.theme.format.main_md_gap};
  width: max-content;
  padding: ${props => `${props.theme.format.main_md_padding} ${props.theme.format.main_lg_padding}`};
  border-radius: ${props => props.theme.format.main_md_border_radius};
  align-items: center;
`;

export const AddCartText = styled.span`
  font-family: ${props => props.theme.font_style.font_family};
  font-size: ${props => props.theme.font_sizes.large_content};
  font-weight: ${props => props.theme.font_style.font_weight.regular};
  color: ${props => props.theme.colors.black};
  text-align: left;
`;
