import styled from 'styled-components';
import {BasicModalTitle} from '../../../../../../../Theme/modalsElements';

export const CancelDelayedOrderModalTitle = styled(BasicModalTitle)`
  color: ${props => props.theme.colors.secondary_red};
  text-align: center;
`;

export const CancelDelayedOrderModalTextBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  text-align: center;
  align-self: center;

  max-width: 80%;
  margin-bottom: 32px;
`;