import {IOrderStatusItem} from '../../../Models/ConstModels';

export const ORDERS_STATUSES: IOrderStatusItem[] = [
  {
    title: 'All orders',
    status: 'all',
    number: 0
  },
  {
    title: 'Prepared',
    status: 'prepared',
    number: 1
  },
  {
    title: 'Active',
    status: 'active',
    number: 2
  },
  {
    title: 'Completed',
    status: 'done',
    number: 3
  },
  {
    title: 'Cancelled',
    status: 'cancelled',
    number: 4
  },
  {
    title: 'Failed',
    status: 'error',
    number: 5
  },
  {
    title: 'Archived',
    status: 'archived',
    number: 6
  },
  {
    title: 'Delayed',
    status: 'delayed',
    number: 7
  }
];