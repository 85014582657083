import MyAppsPage from '../../Pages/MyAppsPage';
import {Route, Routes, useNavigate} from 'react-router-dom';
import {Content} from './styled';
import {JSX, useEffect} from 'react';
import MyOrdersPage from '../../Pages/MyOrdersPage';
import FinancePage from '../../Pages/FinancePage';
import MyProfilePage from '../../Pages/MyProfilePage';
import FAQPage from '../../Pages/FAQPage';
import CreateCampaignPage from '../../Pages/CreateCampaignPage';
import {useAppSelector} from '../../Hooks/redux/useAppSelector';

interface IRoutes {
  path: string,
  element: JSX.Element
}

const ContentProvider = () => {
  const {isAuthorization} = useAppSelector(state => state.authSlice);

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthorization) {
      return navigate('/authorization');
    }
  }, [isAuthorization, navigate]);

  const ROUTES: IRoutes[] = [
    {
      path: '/',
      element: <MyAppsPage/>
    },
    {
      path: '/orders',
      element: <MyOrdersPage/>
    },
    {
      path: '/finance',
      element: <FinancePage/>
    },
    {
      path: '/profile',
      element: <MyProfilePage/>
    },
    {
      path: '/faq',
      element: <FAQPage/>
    },
    {
      path: '/create_campaign/:scenario?/:id?',
      element: <CreateCampaignPage/>
    },

  ];

  return (
    <Content>
      <Routes>
        {ROUTES.map(({path, element}) => <Route key={path} path={path} element={element}/>)}
      </Routes>
    </Content>
  );
};

export default ContentProvider;