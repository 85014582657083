import React from 'react';
import {
  AuthLogo, AuthLogoBlock,
  AuthLogoIcon,
  AuthLogoText,
  AuthStoreBlock,
  AuthStoreItem, AuthStoreText,
  StyledAuthPage
} from '../AuthPage/styled';

const MobilePage = () => {
  return (
    <StyledAuthPage>
      <AuthLogoBlock>
        <AuthLogo>
          <AuthLogoIcon/>
          <AuthLogoText/>
        </AuthLogo>
        <AuthStoreBlock>
          <AuthStoreItem>
            <AuthStoreText>Sorry, this screen resolution is not currently supported</AuthStoreText>
          </AuthStoreItem>
        </AuthStoreBlock>
      </AuthLogoBlock>
    </StyledAuthPage>
  );
};

export default MobilePage;