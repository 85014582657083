type columnsItemType = {
  key: string,
  label: string
}
export const COLUMNS_LIST: columnsItemType[] = [
  {
    key: 'id',
    label: 'ID'
  },
  {
    key: 'app',
    label: 'Application'
  },
  {
    key: 'geo',
    label: 'GEO'
  },
  {
    key: 'type',
    label: 'Type'
  },
  {
    key: 'count',
    label: 'Count'
  },
  {
    key: 'days',
    label: 'Days'
  },
  {
    key: 'price',
    label: 'Price'
  },
  {
    key: 'startDate',
    label: 'Start date'
  },
  {
    key: 'progress',
    label: 'Progress'
  },
  {
    key: 'status',
    label: 'Status'
  }
];