import React from 'react';
import {ICampaignItem} from '../../../../../../../Models/ApiModels';
import {CancelModal, CancelModalButtonsBlock, CancelModalText, CancelModalTitle} from './styled';
import BasicButton from '../../../../../../../UI/BasicButton';
import {useCancelCampaignMutation} from '../../../../../../../Store/APIs/Campaign.api';
import {Loader} from 'rsuite';
import {useLazyGetDataQuery} from '../../../../../../../Store/APIs/ClientData.api';

interface ICancelOrderModalProps {
  orderInfo: ICampaignItem;
  onClose: () => void;
  fetchNewOrdersList: () => void;
  openDelayedAttention: () => void;
}

const CancelOrderModal = React.forwardRef(({
                                             orderInfo,
                                             onClose,
                                             fetchNewOrdersList,
                                             openDelayedAttention
                                           }: ICancelOrderModalProps, ref: React.ForwardedRef<HTMLDivElement>) => {
  const [cancelCampaign, {isLoading}] = useCancelCampaignMutation();

  const [refreshUserData] = useLazyGetDataQuery();

  const handleCancelCampaign = async () => {
    await cancelCampaign({campaign_id: orderInfo.id})
      .then(() => {
        refreshUserData();
        onClose();
        if (orderInfo.status === 7) {
          openDelayedAttention();
        }
      }).finally(() => {
        setTimeout(() => {
          fetchNewOrdersList();
        }, 500);
      });
  };

  return (
    <>
      <CancelModal ref={ref} tabIndex={-1}>
        <CancelModalTitle>Attention!</CancelModalTitle>
        <CancelModalText>Are you sure you want to cancel campaign <b>{orderInfo.id}</b>?</CancelModalText>
        <CancelModalButtonsBlock>
          <BasicButton onClick={handleCancelCampaign} size={'md'} text={'Cancel campaign'} variant={'white'}/>
          <BasicButton onClick={onClose} size={'md'} text={'Close'} variant={'blue'}/>
        </CancelModalButtonsBlock>
      </CancelModal>
      {isLoading && <Loader backdrop size="lg" content="Canceling..." vertical/>}
    </>
  );
});

export default CancelOrderModal;