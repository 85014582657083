import styled from 'styled-components';
import {IThemeProvider} from '../../../../../../../Theme/defaultTheme';

export const DaysAttributeBody = styled.div<IThemeProvider>`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.format.main_md_gap};
`;

export const DaysAttributeRow = styled.div<IThemeProvider>`
  display: flex;
  gap: ${props => props.theme.format.main_lg_gap};
`;

export const DaysAttributeItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 70px;
`;

export const DaysAttributeValue = styled.label<IThemeProvider>`
  font-family: ${props => props.theme.font_style.font_family};
  font-weight: ${props => props.theme.font_style.font_weight.regular};
  font-size: ${props => props.theme.font_sizes.content};

  color: ${props => props.theme.colors.black};

  &[data-is-disabled='true'] {
    color: ${props => props.theme.colors.grey};
  }
`;