import React from 'react';
import {DaysAttributeBody, DaysAttributeItem, DaysAttributeRow, DaysAttributeValue} from './styled';
import {DAYS_ATTRIBUTES} from '../../Constants/DaysAttributes';
import BasicCheckboxButton from '../../../../../../../UI/BasicCheckboxButton';

interface IDaysListAttributesProps {
  handleSetSelectedCheckboxesValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isDisabled: boolean;
  selectedCheckboxesValues: string[];
}

const DaysListAttributes: React.FC<IDaysListAttributesProps> = ({
                                                                  handleSetSelectedCheckboxesValue,
                                                                  selectedCheckboxesValues,
                                                                  isDisabled
                                                                }) => {

  return (
    <DaysAttributeBody>
      <DaysAttributeRow>
        {DAYS_ATTRIBUTES.slice(0, 7).map(day => (
          <DaysAttributeItem key={day.value}>
            <DaysAttributeValue data-is-disabled={isDisabled} htmlFor={day.value}>{day.title}</DaysAttributeValue>
            <BasicCheckboxButton
              isDisabled={isDisabled}
              name={'days'}
              value={day.value}
              checked={!!selectedCheckboxesValues.find(attribute => attribute === day.value)}
              onChange={handleSetSelectedCheckboxesValue}
            />
          </DaysAttributeItem>
        ))}
      </DaysAttributeRow>
      <DaysAttributeRow>
        {DAYS_ATTRIBUTES.slice(7, 14).map(day => (
          <DaysAttributeItem key={day.value}>
            <DaysAttributeValue data-is-disabled={isDisabled} htmlFor={day.value}>{day.title}</DaysAttributeValue>
            <BasicCheckboxButton
              isDisabled={isDisabled}
              name={'days'}
              value={day.value}
              checked={!!selectedCheckboxesValues.find(attribute => attribute === day.value)}
              onChange={handleSetSelectedCheckboxesValue}
            />
          </DaysAttributeItem>
        ))}
      </DaysAttributeRow>
    </DaysAttributeBody>
  );
};

export default DaysListAttributes;