import React from 'react';
import {
  Header,
  HeaderControls,
  HeaderNoVerifyWarning,
  HeaderRightBlock,
  LogoBlock,
  LogoIcon,
  LogoTitle
} from './styled';
import HeaderBalanceBlock from './Components/HeaderBalanceBlock';
import UserButton from './Components/UserButton';
import {useActions} from '../../Hooks/redux/useActions';
import {useGetDataQuery} from '../../Store/APIs/ClientData.api';
import ServerTime from './Components/ServerTime';

interface IHeaderProps {
  rolled: boolean;
}

const PageHeader = ({rolled}: IHeaderProps) => {
  const {setAuthenticated} = useActions();
  useGetDataQuery();

  const {data} = useGetDataQuery(undefined, {pollingInterval: 30000});

  const handleLogOut = () => {
    setAuthenticated(false);
    localStorage.clear();
    window.location.href = '/authorization';
  };

  return (
    <Header data-rolled={rolled}>
      <LogoBlock data-rolled={rolled}>
        <LogoIcon/>
        <LogoTitle/>
      </LogoBlock>
      <HeaderControls>
        <HeaderBalanceBlock/>
        {(data && !data.isVerify) &&
          <HeaderNoVerifyWarning>To use your account on AsoFast, you need to confirm the email address.<br/>
            Check your email and locate the confirmation email. Follow the steps in the email to confirm your email
            address.</HeaderNoVerifyWarning>}
        <HeaderRightBlock>
          {(data && data.unix) &&
            <ServerTime unix={data.unix}/>
          }
          <UserButton handleLogOut={handleLogOut}/>
        </HeaderRightBlock>
      </HeaderControls>
    </Header>
  );
};

export default PageHeader;