import React, {useEffect, useRef, useState} from 'react';
import {LogOutButton, PopupButtonBlock, PopupButtonIcon, StyledUserButton} from './styled';
import CustomPopup from '../../../../Components/CustomPopup';
import {Link} from 'react-router-dom';

interface IUserButtonProps {
  handleLogOut: () => void;
}

const UserButton = ({handleLogOut}: IUserButtonProps) => {
  const [openPopup, setOpenPopup] = useState(false);

  const popupRef = useRef<HTMLDivElement>(null);

  const togglePopup = () => {
    setOpenPopup(!openPopup);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
      setOpenPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <StyledUserButton onClick={togglePopup}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="User">
            <path id="Ellipse 45"
                  d="M19.7274 20.4471C19.2716 19.1713 18.2672 18.0439 16.8701 17.2399C15.4729 16.4358 13.7611 16 12 16C10.2389 16 8.52706 16.4358 7.12991 17.2399C5.73276 18.0439 4.72839 19.1713 4.27259 20.4471"
                  stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
            <circle id="Ellipse 46" cx="12" cy="8" r="4" stroke="currentColor" strokeWidth="2"
                    strokeLinecap="round"/>
          </g>
        </svg>
        {openPopup &&
          <CustomPopup ref={popupRef}>
            <PopupButtonBlock>
              <PopupButtonIcon>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12.12 12.78C12.05 12.77 11.96 12.77 11.88 12.78C10.12 12.72 8.71997 11.28 8.71997 9.51001C8.71997 7.70001 10.18 6.23001 12 6.23001C13.81 6.23001 15.28 7.70001 15.28 9.51001C15.27 11.28 13.88 12.72 12.12 12.78Z"
                    stroke="#5063C0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M18.74 19.38C16.96 21.01 14.6 22 12 22C9.40001 22 7.04001 21.01 5.26001 19.38C5.36001 18.44 5.96001 17.52 7.03001 16.8C9.77001 14.98 14.25 14.98 16.97 16.8C18.04 17.52 18.64 18.44 18.74 19.38Z"
                    stroke="#5063C0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                    stroke="#5063C0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </PopupButtonIcon>
              <Link to={'/profile'}>Profile</Link>
            </PopupButtonBlock>
            <PopupButtonBlock>
              <PopupButtonIcon>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17 21H7C3 21 2 20 2 16V8C2 4 3 3 7 3H17C21 3 22 4 22 8V16C22 20 21 21 17 21Z"
                        stroke="#5063C0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M14 8H19" stroke="#5063C0" strokeWidth="1.5" strokeLinecap="round"
                        strokeLinejoin="round"/>
                  <path d="M15 12H19" stroke="#5063C0" strokeWidth="1.5" strokeLinecap="round"
                        strokeLinejoin="round"/>
                  <path d="M17 16H19" stroke="#5063C0" strokeWidth="1.5" strokeLinecap="round"
                        strokeLinejoin="round"/>
                  <path
                    d="M8.5 11.29C9.49964 11.29 10.31 10.4796 10.31 9.48001C10.31 8.48038 9.49964 7.67001 8.5 7.67001C7.50037 7.67001 6.69 8.48038 6.69 9.48001C6.69 10.4796 7.50037 11.29 8.5 11.29Z"
                    stroke="#5063C0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path
                    d="M12 16.33C11.86 14.88 10.71 13.74 9.26 13.61C8.76 13.56 8.25 13.56 7.74 13.61C6.29 13.75 5.14 14.88 5 16.33"
                    stroke="#5063C0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </PopupButtonIcon>
              <a href={'https://t.me/g0ogIe'} target={'_blank'}>Your manager</a>
            </PopupButtonBlock>
            <PopupButtonBlock>
              <PopupButtonIcon>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.90002 7.55999C9.21002 3.95999 11.06 2.48999 15.11 2.48999H15.24C19.71 2.48999 21.5 4.27999 21.5 8.74999V15.27C21.5 19.74 19.71 21.53 15.24 21.53H15.11C11.09 21.53 9.24002 20.08 8.91002 16.54"
                    stroke="#5063C0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M15 12H3.62" stroke="#5063C0" strokeWidth="1.5" strokeLinecap="round"
                        strokeLinejoin="round"/>
                  <path d="M5.85 8.64999L2.5 12L5.85 15.35" stroke="#5063C0" strokeWidth="1.5" strokeLinecap="round"
                        strokeLinejoin="round"/>
                </svg>
              </PopupButtonIcon>
              <LogOutButton onClick={handleLogOut}>Log out</LogOutButton>
            </PopupButtonBlock>
          </CustomPopup>
        }
      </StyledUserButton>
    </>


  );
};

export default UserButton;