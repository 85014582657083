import React from 'react';
import {
  PrivacyContainer, PrivacyWrapper,
  PrivacyHeader,
  PrivacyHeaderLogo,
  PrivacyLogoIcon,
  PrivacyLogoText,
  PrivacyTitle, PrivacyContentWrapper
} from './styled';
import PrivacyContent from './Contents/PrivacyContent';
import TermsContent from './Contents/TermsContent';

interface PrivacyProps {
  content: 'privacyPolicy' | 'termsOfUse';
}

const PrivacyPage: React.FC<PrivacyProps> = ({content}) => {
  return (
    <PrivacyContainer>
      <PrivacyHeader>
        <PrivacyHeaderLogo>
          <PrivacyLogoIcon/>
          <PrivacyLogoText/>
        </PrivacyHeaderLogo>
      </PrivacyHeader>
      <PrivacyTitle>{content === 'privacyPolicy' ? 'Privacy Policy' : 'Terms of Use'}</PrivacyTitle>
      <PrivacyWrapper>
        <PrivacyContentWrapper>
          {content === 'privacyPolicy' ? <TermsContent/> : <PrivacyContent/>}
        </PrivacyContentWrapper>
      </PrivacyWrapper>
    </PrivacyContainer>
  );
};

export default PrivacyPage;