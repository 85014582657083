import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './Containers/App/App';
import {BrowserRouter} from 'react-router-dom';
import {ThemeProvider} from 'styled-components';
import {DefaultTheme} from './Theme/defaultTheme';
import {Provider} from 'react-redux';
import {setupStore} from './Store/store';
import {GoogleOAuthProvider} from '@react-oauth/google';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const store = setupStore();

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={DefaultTheme}>
        <Provider store={store}>
          <GoogleOAuthProvider clientId={'86935402230-5f0kf5aq2pq1tr698f8u13ctg2vm2vt9.apps.googleusercontent.com'}>
            <App/>
          </GoogleOAuthProvider>
        </Provider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);
