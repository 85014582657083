import styled from "styled-components";
import {BasicSmallSpan} from "../../../../../../Theme/basicElements";
import {IThemeProvider} from "../../../../../../Theme/defaultTheme";

export const DownloadButton = styled.a<IThemeProvider>`
  background-color: transparent;
  border: 0;

  display: flex;
  flex-direction: column;
  gap: 16px;
  width: max-content;
  align-items: center;
  margin: 16px auto 64px;
  padding: 0;

  &:hover {
    & div {
      color: ${props => props.theme.colors.grey};
    }

    & span {
      color: ${props => props.theme.colors.brand_hover};
    }
  }

`;

export const DownloadButtonIcon = styled.div<IThemeProvider>`
  width: 128px;
  height: 128px;

  color: ${props => props.theme.colors.grey_semi};
`;

export const DownloadButtonSpan = styled(BasicSmallSpan)<IThemeProvider>`
  color: ${props => props.theme.colors.brand_blue};
`;