import React from 'react';
import {
  CostTable,
  CostTableContainer,
  CostTableTbody,
  CostTableTd,
  CostTableTh,
  CostTableThead,
  CostTableTr
} from './styled';
import {IClientData} from '../../../../Models/ApiModels';

interface IUserCostsTableProps {
  userInfo: IClientData;
}

const UserCostsTable: React.FC<IUserCostsTableProps> = ({userInfo}) => {
  return (
    <CostTableContainer>
      <CostTable>
        <CostTableThead>
          <CostTableTr>
            <CostTableTh>Store</CostTableTh>
            <CostTableTh>Install, $</CostTableTh>
            <CostTableTh>Rate, $</CostTableTh>
          </CostTableTr>
        </CostTableThead>
        <CostTableTbody>
          <CostTableTr>
            <CostTableTd>Google play</CostTableTd>
            <CostTableTd>{userInfo.install_cost}</CostTableTd>
            <CostTableTd>{userInfo.rate_cost}</CostTableTd>
          </CostTableTr>
          <CostTableTr>
            <CostTableTd>App Store</CostTableTd>
            <CostTableTd>{userInfo.a_install_cost}</CostTableTd>
            <CostTableTd>{userInfo.a_rate_cost}</CostTableTd>
          </CostTableTr>
        </CostTableTbody>
      </CostTable>
    </CostTableContainer>
  );
};

export default UserCostsTable;