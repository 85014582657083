import styled from 'styled-components';
import {IThemeProvider} from '../../../../../../../Theme/defaultTheme';

export const TasksTableButtonsList = styled.div<IThemeProvider>`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${props => props.theme.colors.grey_semi};
`;

export const TasksTableButtonsRow = styled.div`
  padding: 20px 8px;
  display: flex;
  justify-content: right;
  gap: 8px;
  height: 65px;
  border-bottom: 1px solid ${props => props.theme.colors.grey_semi};
`;