import styled from "styled-components";
import {IThemeProvider} from "../../../../../Theme/defaultTheme";

export const SubmitBlock = styled.div<IThemeProvider>`
  width: 380px;
  margin-left: auto;

  display: flex;
  gap: ${props => props.theme.format.main_lg_gap};
  align-items: center;
`;