import React, {JSX, useRef, useState} from 'react';
import CreateBaseOrderModule from './ScenarioModules/CreateBaseOrderModule';
import {useNavigate, useParams} from 'react-router-dom';
import PromoteApplicationModule from './ScenarioModules/PromoteApplicationModule';
import DuplicateOrderModule from './ScenarioModules/DuplicateOrderModule';
import ExtendOrderModule from './ScenarioModules/ExtendOrderModule';
import {useAppSelector} from '../../Hooks/redux/useAppSelector';
import {INewCampaignObject} from './Models/CountryModel';
import {getTotalCostValue, validationFinalOrderForm} from './Helpers/CreateOrderHelpers';
import {throwNewToastError, throwNewToastSuccess} from '../../Utils/MainUtils';
import {ERRORS_ITEMS} from './Constants/ErrorsContants';
import {useCreateNewCampaignMutation} from '../../Store/APIs/Campaign.api';
import {Modal} from '@mui/material';
import CreateNewOrderModal from './Components/CreateNewOrderModal';
import CustomLoader from '../../Components/Loaders/CustomLoader';
import {useGetAppsQuery} from '../../Store/APIs/Apps.api';
import {useLazyGetDataQuery} from '../../Store/APIs/ClientData.api';

type IScenario = Record<string, JSX.Element>;

const CreateCampaignPage = () => {
  const {scenario = 'new', id} = useParams();
  const navigate = useNavigate();

  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const [finalOrderState, setFinalOrderState] = useState<INewCampaignObject | null>(null);

  const {user} = useAppSelector(state => state.clientDataSlice);
  const [fetchNewUserData] = useLazyGetDataQuery();

  const {
    type,
    app,
    dateStart,
    dateStop,
    countriesList,
    delayedStartValue,
    delayedStartEnable,
    attributesValue
  } = useAppSelector(state => state.newOrderSlice);

  const scenarioCurrentRef = useRef<any | null>(null);

  const {data: appsList = []} = useGetAppsQuery({
    offset: 0,
    limit: 999
  });

  const handleCreateNewOrder = () => {
    const finalOrderObject: INewCampaignObject = {
      app_id: app,
      type: type,
      date_start: dateStart,
      date_stop: dateStop,
      keywords: countriesList,
      settings_message: attributesValue,
      delayed_start: delayedStartValue,
    };

    const errorsList = validationFinalOrderForm(finalOrderObject);

    if (delayedStartEnable && !delayedStartValue) {
      errorsList.push('delayed_start_empty');
    }

    if (app && type && !!countriesList.length && !!appsList.length && user) {
      const totalCost = getTotalCostValue(app, type, user, appsList, countriesList, attributesValue);
      if (totalCost && totalCost > user.balance - (user.limit)) {
        errorsList.push('error_lack_money');
      }
    }

    if (errorsList.length > 0) {
      errorsList.forEach(error => throwNewToastError(ERRORS_ITEMS[error]));
      return;
    } else {
      setFinalOrderState(finalOrderObject);
      setOpenSubmitModal(true);
    }
  };

  const [createNewCampaign, {isLoading}] = useCreateNewCampaignMutation();

  const handleSubmitCreateNewOrder = () => {
    if (finalOrderState) {
      createNewCampaign(finalOrderState)
        .unwrap()
        .then((result) => {
          if (result.status === 'ok') {
            if (scenarioCurrentRef.current) {
              scenarioCurrentRef.current.childFunction();
            }

            setOpenSubmitModal(false);
            navigate('/orders');
            throwNewToastSuccess('Campaign created successfully');

            return fetchNewUserData();
          }
        })
        .catch((error) => {
          setOpenSubmitModal(false);

          if ('data' in error && error.data && 'error' in error.data && error.data.error) {
            throwNewToastError(error.data.error);
          }

          if ('data' in error && error.data && 'status' in error.data && error.data.status) {
            throwNewToastError(error.data.status);
          }

          console.log(error);
        });
    }
  };

  const handleCloseSubmitModal = () => {
    setOpenSubmitModal(false);
  };

  const scenarioModules: IScenario = {
    'new': <CreateBaseOrderModule
      handleCreateNewOrder={handleCreateNewOrder}
      ref={scenarioCurrentRef}/>,
    'promote': <PromoteApplicationModule
      promoteAppId={Number(id)}
      handleCreateNewOrder={handleCreateNewOrder}
      ref={scenarioCurrentRef}/>,
    'duplicate': <DuplicateOrderModule
      id={Number(id)}
      ref={scenarioCurrentRef}
      handleCreateNewOrder={handleCreateNewOrder}/>,
    'extend': <ExtendOrderModule
      id={Number(id)}
      ref={scenarioCurrentRef}
      handleCreateNewOrder={handleCreateNewOrder}/>
  };

  return (
    <>
      {scenarioModules[scenario]}
      <Modal open={openSubmitModal} onClose={handleCloseSubmitModal}>
        <CreateNewOrderModal handleSubmitCreateNewOrder={handleSubmitCreateNewOrder} onClose={handleCloseSubmitModal}/>
      </Modal>
      {isLoading && <CustomLoader size={'lg'} loadingText={'Sending...'} backdrop={true}/>}
    </>

  );
};

export default CreateCampaignPage;