import styled from 'styled-components';
import {IThemeProvider} from '../../../../Theme/defaultTheme';

export const FAQContainer = styled.div<IThemeProvider>`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.format.main_lg_gap};
`;

export const FAQList = styled.ul<IThemeProvider>`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.format.main_md_gap};
  margin: 0;
  padding: 0;
`;