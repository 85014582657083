import React, {useState} from 'react';
import {IPaymentItem} from '../../../../../../Models/ApiModels';
import {
  FinancialButtons,
  FinancialEmptyBody, FinancialEmptyBodyText,
  FinancialTable,
  FinancialTableBlock,
  FinancialTbody, FinancialTd,
  FinancialTh,
  FinancialThead,
  FinancialTr
} from './styled';
import BasicButton from '../../../../../../UI/BasicButton';
import {Loader} from 'rsuite';

interface IPaymentsTable {
  paymentsList: IPaymentItem[];
  isLoading: boolean;
}

const PaymentsTable = ({paymentsList, isLoading}: IPaymentsTable) => {
  const [selectedLimit, setSelectedLimit] = useState(5);

  const getVisiblePaymentsList = (list: IPaymentItem[], limit: number): IPaymentItem[] => {
    return list.slice(0, limit);
  };

  const handleShowMoreClick = () => {
    setSelectedLimit(prevState => prevState + 5);
  };

  const handleRollUp = () => {
    setSelectedLimit(5);
  };

  return (
    <FinancialTableBlock>
      <FinancialTable>
        <FinancialThead>
          <FinancialTr>
            <FinancialTh>Amount</FinancialTh>
            <FinancialTh>Date</FinancialTh>
            <FinancialTh>Details</FinancialTh>
          </FinancialTr>
        </FinancialThead>
        <FinancialTbody>
          {!!paymentsList.length &&
            getVisiblePaymentsList(paymentsList, selectedLimit).map(payment =>
              <FinancialTr key={payment.createdAt}>
                <FinancialTd>$ {payment.amount && Number(payment.amount).toFixed(2)}</FinancialTd>
                <FinancialTd>{payment.createdAt.slice(0, 10)}</FinancialTd>
                <FinancialTd style={{width: '66%'}}>{payment.value}</FinancialTd>
              </FinancialTr>
            )
          }
        </FinancialTbody>
      </FinancialTable>
      {!paymentsList.length ?
        <FinancialEmptyBody>
          {
            isLoading ?
              <Loader center size={'lg'}/> :
              <FinancialEmptyBodyText>No data</FinancialEmptyBodyText>
          }
        </FinancialEmptyBody> :
        <FinancialButtons>
          <BasicButton onClick={handleShowMoreClick} size={'sm'} text={'Show more'} variant={'blue'}
                       disabled={paymentsList.length <= selectedLimit}/>
          <BasicButton onClick={handleRollUp} size={'sm'} text={'Roll up'} variant={'white'}/>
        </FinancialButtons>
      }
    </FinancialTableBlock>
  );
};

export default PaymentsTable;