import styled from "styled-components";
import {IThemeProvider} from "../../../../../../../../Theme/defaultTheme";

export const TableButton = styled.button<IThemeProvider>`
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;

  background-color: transparent;
  border: 0;

  color: ${props => props.theme.colors.brand_blue};

  &:hover {
    color: ${props => props.theme.colors.brand_hover};
  }

  &[data-role='globalRatio'] {
    color: ${props => props.theme.colors.grey};

    &:hover {
      color: ${props => props.theme.colors.black};
    }
  }

  &[data-role='globalClear'] {
    color: ${props => props.theme.colors.secondary_red_b};

    &:hover {
      color: ${props => props.theme.colors.secondary_red};
    }
  }

  & svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;