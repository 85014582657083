import React from 'react';
import {
  ContentBlock,
  ContentItem,
  ContentText,
  ContentTitle
} from './styled';
import {BasicContentSpan} from '../../../Theme/basicElements';

const TermsContent = () => {
  return (
    <ContentBlock>
      <BasicContentSpan>Update Date: April 02, 2024</BasicContentSpan>

      <ContentItem>
        <ContentText>This privacy policy describes the ways in which we collect, use, store, and protect information
          provided by users when using the app.asofast.io website ("Service"). </ContentText>
        <ContentItem>Please read before using the service.</ContentItem>
      </ContentItem>

      <ContentItem>
        <ContentTitle>1. Information Collected:</ContentTitle>
        <ContentItem>We may collect the following data:</ContentItem>
        <ContentItem>- Email address</ContentItem>
        <ContentItem>- Browser and device data</ContentItem>
        <ContentItem>- IP address</ContentItem>
        <ContentItem>- Other information provided by the user</ContentItem>
      </ContentItem>

      <ContentItem>
        <ContentTitle>2. Use of Collected Data:</ContentTitle>
        <ContentItem>The collected information is used for:</ContentItem>
        <ContentItem>- Providing services</ContentItem>
        <ContentItem>- Enhancing service quality</ContentItem>
        <ContentItem>- Communicating with users</ContentItem>
        <ContentItem>- Sending marketing materials (with the user's consent)</ContentItem>
      </ContentItem>

      <ContentItem>
        <ContentTitle>3. Data Protection:</ContentTitle>
        <ContentItem>We store personal data for the purpose of providing our services and fulfilling contracts entered
          into with you.</ContentItem>
        <ContentItem>We take security measures to protect your data from unauthorized access, alteration, or
          dissemination.</ContentItem>
        <ContentItem>We retain your data only to the extent necessary for the purposes for which it was collected, or as
          required by law.</ContentItem>
      </ContentItem>

      <ContentItem>
        <ContentTitle>4. Data Access:</ContentTitle>
        <ContentItem>Access to your personal data is restricted to employees who need it to perform their professional
          duties.</ContentItem>
      </ContentItem>

      <ContentItem>
        <ContentTitle>5. Use of Cookies:</ContentTitle>
        <ContentItem>We may use cookies to improve user experience. Users can disable cookies in their browser
          settings.</ContentItem>
      </ContentItem>

      <ContentItem>
        <ContentTitle>6. Disclosure of Information to Third Parties:</ContentTitle>
        <ContentItem>We do not share your personal data with third parties without your consent, except as required by
          law.</ContentItem>
        <ContentItem>We use services from Google and Telegram for user authentication. Please review their
          policies:</ContentItem>
        <ContentItem>https://policies.google.com/privacy</ContentItem>
        <ContentItem>https://telegram.org/privacy/</ContentItem>
      </ContentItem>

      <ContentItem>
        <ContentTitle>7. User Rights: </ContentTitle>
        <ContentItem>You have the right to request access, correction, or deletion of your personal data.</ContentItem>
      </ContentItem>

      <ContentItem>
        <ContentTitle>8. Changes to the Privacy Policy:</ContentTitle>
        <ContentItem>We reserve the right to make changes to this privacy policy. Changes take effect from the moment
          they are published on the website.</ContentItem>
      </ContentItem>

      <ContentItem>
        <ContentTitle>9. Consent to the Privacy Policy:</ContentTitle>
        <ContentItem>By using our service, you agree to the terms of this privacy policy.</ContentItem>
      </ContentItem>

      <ContentItem>
        <ContentItem>If you have any questions regarding our privacy policy, please contact us at
          support@asofast.io</ContentItem>
      </ContentItem>


    </ContentBlock>
  );
};

export default TermsContent;