import React from 'react';
import {useAppSelector} from '../../../../Hooks/redux/useAppSelector';
import {AddBalanceButton, BalanceBlock, BalanceSpan} from './styled';
import {useNavigate} from 'react-router-dom';

const HeaderBalanceBlock = () => {
  const dataUser = useAppSelector(state => state.clientDataSlice.user);
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/finance');
  };

  return (
    <BalanceBlock>
      <BalanceSpan>$ {dataUser?.balance?.toFixed(2)}</BalanceSpan>
      <AddBalanceButton title={'Add funds'} onClick={handleNavigate}/>
    </BalanceBlock>
  );
};

export default HeaderBalanceBlock;