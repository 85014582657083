import styled from "styled-components";
import {IThemeProvider} from "../../../../../../../Theme/defaultTheme";

export const PopupContainer = styled.div<IThemeProvider>`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.format.main_md_gap};
  box-sizing: border-box;

  width: 126px;
  padding: ${props => props.theme.format.main_md_padding};

  background-color: ${props => props.theme.colors.white};
  border-radius: 12px;
`;

export const PopupButton = styled.button<IThemeProvider>`
  width: 100%;
  padding: 8px 0;

  font-family: ${props => props.theme.font_style.font_family};
  font-size: ${props => props.theme.font_sizes.small};
  font-weight: ${props => props.theme.font_style.font_weight.regular};
  border-radius: ${props => props.theme.format.small_border_radius};
  cursor: pointer;

  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.secondary_btn};
  border: 0;

  &:hover {
    background-color: ${props => props.theme.colors.brand_blue};
  }

  &:disabled {
    background-color: ${props => props.theme.colors.grey_semi};
    color: ${props => props.theme.colors.black_40};
  }
`;