import styled from 'styled-components';
import {BasicModalTitle, BasicModalWrapper} from '../../../../../../Theme/modalsElements';
import {BasicTableTd, BasicTableTh} from '../../../../../../Theme/basicElements';
import {IThemeProvider} from '../../../../../../Theme/defaultTheme';

export const StatisticModal = styled(BasicModalWrapper)<IThemeProvider>`
  width: 1172px;
  max-width: unset;

  gap: ${props => props.theme.format.main_lg_gap};
`;

export const StatisticTitleWrapper = styled.div<IThemeProvider>`
  display: flex;
  gap: ${props => props.theme.format.main_md_gap};
  align-items: center;
`;

export const StatisticModalTitle = styled(BasicModalTitle)`
  margin-bottom: 0;
`;

export const ControlBlock = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 64px;
`;

export const StatisticTableBlock = styled.div`
  width: 100%;
  overflow: auto;
  position: relative;

  min-height: 300px;
`;

export const StatisticTable = styled.table`
`;

export const StatisticTHead = styled.thead``;

export const StatisticTBody = styled.tbody``;

export const StatisticTr = styled.tr``;

export const StatisticTh = styled(BasicTableTh)<IThemeProvider>`
  width: 120px;
  padding: 8px;
  text-align: left;

  background-color: ${props => props.theme.colors.primary_light};
  border-bottom: 1px solid ${props => props.theme.colors.grey_semi};
  color: ${props => props.theme.colors.black_60};
`;

export const StatisticTd = styled(BasicTableTd)`
  width: 120px;
  padding: 8px;

  &[data-role='key'] {
    background-color: ${props => props.theme.colors.primary_light};
    position: sticky;
    left: 0;
  }
`;

export const StatisticEmptyText = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;

  font-family: ${props => props.theme.font_style.font_family};
  font-size: 30px;
  color: ${props => props.theme.colors.grey};
  width: max-content;

  transform: translate(-50%, -50%);
`;