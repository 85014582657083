import styled from "styled-components";
import {IThemeProvider} from "../../../../../../Theme/defaultTheme";

interface IButtonProps {
  'data-role': string
}

export const StyledButton = styled.button<IButtonProps & IThemeProvider>`
  width: max-content;
  background-color: transparent;

  font-family: ${props => props.theme.font_style.font_family};
  font-size: ${props => props.theme.font_sizes.small};

  padding: 8px 16px;
  border-radius: ${props => props.theme.format.small_border_radius};

  &[data-role='cancel'] {
    border: 1px solid ${props => props.theme.colors.secondary_red};
    color: ${props => props.theme.colors.secondary_red};

    &:hover {
      border: 1px solid ${props => props.theme.colors.secondary_red_b};
      color: ${props => props.theme.colors.secondary_red_b};
    }
  }

  &[data-role='promote'] {
    border: 1px solid ${props => props.theme.colors.secondary_green_c};
    color: ${props => props.theme.colors.secondary_green_c};

    &:hover {
      border: 1px solid ${props => props.theme.colors.secondary_green_a};
      color: ${props => props.theme.colors.secondary_green_a};
    }
  }

  &[data-role='archive'] {
    border: 1px solid ${props => props.theme.colors.secondary_blue_a};
    color: ${props => props.theme.colors.secondary_blue_a};

    &:hover {
      border: 1px solid ${props => props.theme.colors.brand_hover};
      color: ${props => props.theme.colors.brand_hover};
    }
  }

  &:disabled {
    border: 1px solid ${props => props.theme.colors.black_40};
    color: ${props => props.theme.colors.black_40};

    &:hover {
      border: 1px solid ${props => props.theme.colors.black_40};
      color: ${props => props.theme.colors.black_40};
    }
  }
`;