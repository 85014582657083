import React from 'react';
import {HideButton, NavItem, NavList, NavText, StyledAside} from './styled';
import {Link, useLocation} from 'react-router-dom';

import myAppsIcon from '../../Images/svg/nav-apps.svg';
import myOrdersIcon from '../../Images/svg/my-orders-icon.svg';
import addMoneyIcon from '../../Images/svg/add-money-icon.svg';
import myProfileIcon from '../../Images/svg/my-profile-icon.svg';
import contactUsIcon from '../../Images/svg/contact-us-icon.svg';

interface IRoutes {
  title: string,
  path: string,
  icon: any
}

interface IAsideProps {
  handleHideMenu: () => void;
  rolled: boolean;
}

const PageAside = ({handleHideMenu, rolled}: IAsideProps) => {
  const location = useLocation();

  const ROUTES: IRoutes[] = [
    {
      title: 'My apps',
      path: '/',
      icon: myAppsIcon
    },
    {
      title: 'My orders',
      path: '/orders',
      icon: myOrdersIcon
    },
    {
      title: 'Finance',
      path: '/finance',
      icon: addMoneyIcon
    },
    {
      title: 'My profile',
      path: '/profile',
      icon: myProfileIcon
    },
    {
      title: 'FAQ',
      path: '/faq',
      icon: contactUsIcon
    },

  ];

  return (
    <StyledAside>
      <NavList>
        {ROUTES.map((route) =>
          <Link to={route.path} key={route.path}>
            <NavItem data-active-link={location.pathname === route.path}>
              <img src={route.icon} alt="item-pic"/>
              <NavText data-rolled={rolled}>{route.title}</NavText>
            </NavItem>
          </Link>
        )}
      </NavList>
      <HideButton onClick={handleHideMenu} data-rolled={rolled}/>
    </StyledAside>
  );
};

export default PageAside;