import styled from "styled-components";
import {IThemeProvider} from "../../../../../Theme/defaultTheme";

export const OrderCountriesBlock = styled.div<IThemeProvider>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.format.main_lg_gap};
`;

export const OrderCountriesList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${props => props.theme.format.main_lg_gap};
  margin: 0;
  padding: 0;
  list-style-type: none;
`;