import styled from 'styled-components';
import {IThemeProvider} from '../../../../../../../Theme/defaultTheme';
import {BasicOrderFormRow, BasicOrderFormRowLabel, BasicOrderFormRowTitle} from '../../../../../Styles/newOrdersBasics';
import {BasicContentSpan} from '../../../../../../../Theme/basicElements';

export const RatesControlBlock = styled.div<IThemeProvider>`
  display: flex;
  gap: ${props => props.theme.format.main_lg_gap};
  align-items: center;
`;

export const RatesAdditionRow = styled(BasicOrderFormRow)`
  align-items: center;
`;

export const RatesAdditionLabel = styled(BasicOrderFormRowLabel)<IThemeProvider>`
  width: 139px;
  padding: ${props => props.theme.format.main_md_gap} 0;
`;

export const RatesAdditionTitle = styled(BasicOrderFormRowTitle)``;

export const RatesCheckboxesBlock = styled.div<IThemeProvider>`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.format.main_md_gap};
`;

export const RatesCheckboxItem = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const RatesCheckboxTitle = styled(BasicContentSpan)<IThemeProvider>`
  color: ${props => props.theme.colors.black};
`;