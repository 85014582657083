import styled from 'styled-components';
import arrowDownIcon from '../../../Images/svg/arrows/arrow-down.svg';
import arrowUpIcon from '../../../Images/svg/arrows/arrow-up.svg';
import {IThemeProvider} from '../../../Theme/defaultTheme';

interface ISelectBlock {
  'data-size': 'sm' | 'md' | 'lg',
}

interface ISelectItem {
  'data-active': boolean;
}

export const SelectBlock = styled.div<ISelectBlock>`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  height: min-content;

  &[data-size='sm'] {
    width: 418px;
  }

  &[data-size='md'] {
    width: 397px;
  }

  &[data-size='ld'] {
    width: 100%;
  }
`;

export const StyledSelectInputLabel = styled.label<IThemeProvider>`
  display: flex;
  flex-direction: column;
  gap: 8px;

  font-family: ${props => props.theme.font_style.font_family};
  font-size: ${props => props.theme.font_sizes.content};
  font-weight: ${props => props.theme.font_style.font_weight.regular};
  color: ${props => props.theme.colors.black};

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    right: 1px;
    pointer-events: none;

    background-image: url(${arrowDownIcon});
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: 50% 50%;
    background-color: ${props => props.theme.colors.white};

    padding: 12px;
  }

  &[data-list-is-open='true'] {
    &::after {
      background-image: url(${arrowUpIcon});
    }
  }
`;

export const SelectList = styled.div<IThemeProvider>`
  position: absolute;
  top: calc(100% - 1px);
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  max-height: 300px;
  overflow: auto;
  z-index: 5;
  background-color: ${props => props.theme.colors.white};
  border-top: 0;
  border-radius: 0 0 ${props => props.theme.format.main_md_border_radius} ${props => props.theme.format.main_md_border_radius};
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.colors.grey_semi};

  &[data-is-focused='true'] {
    outline: 1.5px solid ${props => props.theme.colors.brand_blue};
    border: 1px solid transparent;
  }
`;

export const StyledSelectInput = styled.input<IThemeProvider>`
  display: block;
  width: 100%;
  padding: ${props => `${props.theme.format.main_md_padding} 25px ${props.theme.format.main_md_padding} ${props.theme.format.main_md_padding}`};
  background-color: transparent;
  border: 1px solid ${props => props.theme.colors.grey_semi};
  border-radius: ${props => props.theme.format.main_md_border_radius};
  box-sizing: border-box;

  position: relative;

  font-family: ${props => props.theme.font_style.font_family};
  font-size: ${props => props.theme.font_sizes.content};
  font-weight: ${props => props.theme.font_style.font_weight.regular};
  color: ${props => props.theme.colors.black};

  &[data-in-error='true'] {
    border: 1px solid ${props => props.theme.colors.secondary_red};
  }

  &[data-list-is-open='true'] {
    border-radius: ${props => `${props.theme.format.main_md_border_radius} ${props.theme.format.main_md_border_radius} 0 0`};
  }

  &:focus-visible {
    outline: 1.5px solid ${props => props.theme.colors.brand_blue};
    border: 1px solid transparent;
  }

  &:disabled {
    color: ${props => props.theme.colors.grey}
  }
`;

export const SelectItem = styled.div<IThemeProvider & ISelectItem>`
  padding: 8px 16px;
  box-sizing: border-box;
  white-space: nowrap;

  display: flex;
  gap: 12px;
  align-items: center;

  color: ${props => props.theme.colors.black};

  background-color: transparent;

  cursor: pointer;

  &[data-active='true'] {
    background-color: ${props => props.theme.colors.primary_blue};
  }

  &:hover {
    background-color: ${props => props.theme.colors.primary_blue};
  }
`;

export const SelectItemSpan = styled.span`
  font-family: ${props => props.theme.font_style.font_family};
  font-size: ${props => props.theme.font_sizes.content};
  font-weight: ${props => props.theme.font_style.font_weight.regular};
`;