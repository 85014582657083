import React from 'react';
import {Loader} from 'rsuite';

interface ILargeLoaderProps {
  size: 'sm' | 'md' | 'lg';
  loadingText?: string;
  backdrop?: boolean;
}

const CustomLoader: React.FC<ILargeLoaderProps> = ({size, loadingText, backdrop = false}) => {
  return (
    <Loader backdrop={backdrop} size={size} content={loadingText} vertical/>
  );
};

export default CustomLoader;