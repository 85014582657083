import React, {useEffect} from 'react';
import {OrderCountriesBlock, OrderCountriesList} from './styled';
import CampaignCountryItem from './Components/CampaignCountryItem';
import CountriesTotalBlock from './Components/CountriesTotalBlock';
import {ICountryModel, IKeyword} from '../../../Models/CountryModel';
import {useAppSelector} from '../../../../../Hooks/redux/useAppSelector';
import {useActions} from '../../../../../Hooks/redux/useActions';
import {throwNewToastError} from '../../../../../Utils/MainUtils';
import {getDatesListFromCountry, getKeysFromCountry} from '../../../Helpers/CreateOrderHelpers';
import {getDatesBetween} from '../../../../../Utils/DateUtils';

interface ICreateCampaignCountriesListProps {
  handleSetCountriesListInStorage: (countriesList: ICountryModel[]) => void;
}

const CreateCampaignCountriesList: React.FC<ICreateCampaignCountriesListProps> = ({handleSetCountriesListInStorage}) => {
  const {countriesList, type, dateStart, dateStop} = useAppSelector(state => state.newOrderSlice);
  const {
    setCountriesList,
    addNewCountry,
    cloneSelectedCountry,
    removeSelectedCountry,
    addNewBpCountry
  } = useActions();

  const putEditedCountryInList = (country: ICountryModel, index: number) => {
    //Сохранение в локальное хранилище
    const newCountriesList = [...countriesList];
    newCountriesList[index] = country;

    handleSetCountriesListInStorage(newCountriesList);
  };

  const handleAddNewCountry = () => {
    if (!type) {
      throwNewToastError('Please, choose the type of order');
      return;
    }

    if (type === 'install_bp') {
      if (dateStart && dateStop) {
        const dateArray = getDatesBetween(dateStart, dateStop);

        let newKeywords: IKeyword = {};

        dateArray.forEach((date) => {
          newKeywords[date] = {'#install_by_package#': 0};
        });

        addNewBpCountry(newKeywords);

        const newCountry: ICountryModel = {geo: null, keywords: newKeywords};
        handleSetCountriesListInStorage([...countriesList, newCountry]);
        return;
      } else {
        throwNewToastError('Please select start and end date');
        return;
      }
    } else {
      //функция добавляет новую страну
      addNewCountry();

      //Сохранение в локальное хранилище
      const newCountry: ICountryModel = {geo: null, keywords: {}};

      handleSetCountriesListInStorage([...countriesList, newCountry]);
    }
  };

  const handleCloneCurrentCountry = (countryIndex: number) => {
    cloneSelectedCountry(countryIndex);

    //Сохранение в локальное хранилище
    const newCountry: ICountryModel = JSON.parse(JSON.stringify(countriesList[countryIndex]));
    newCountry.geo = null;
    const newCountriesList = [...countriesList];
    newCountriesList.splice(countryIndex + 1, 0, newCountry);

    handleSetCountriesListInStorage(newCountriesList);
  };

  const handleRemoveCurrentCountry = (countryIndex: number) => {
    removeSelectedCountry(countryIndex);

    const newCountriesList = [...countriesList];
    newCountriesList.splice(countryIndex, 1);

    handleSetCountriesListInStorage(newCountriesList);
  };

  useEffect(() => {
    //срабатывает первее чем очистка стейта
    if (dateStart && dateStop && countriesList.length) {
      let newCountriesList: ICountryModel[] = [];

      countriesList.forEach(country => {
        const prevKeywordsObj = country.keywords;
        let newKeywordsObj: IKeyword = {};

        const prevDateArray = getDatesListFromCountry(country);
        const newDateArray = getDatesBetween(dateStart, dateStop);

        let countryKeys: string[] = [];

        if (Object.values(country.keywords).length) {
          countryKeys = getKeysFromCountry(country);
        }

        if (countryKeys.length) {
          newDateArray.forEach((newDate) => {
            if (prevDateArray.find(prevDate => prevDate === newDate)) {
              newKeywordsObj[newDate] = prevKeywordsObj[newDate];
            } else {
              newKeywordsObj[newDate] = {};
              countryKeys.forEach((key) => newKeywordsObj[newDate][key] = 0);
            }
          });
        }

        const newCountry = {...country};
        newCountry.keywords = newKeywordsObj;
        newCountriesList.push(newCountry);
      });
      handleSetCountriesListInStorage(newCountriesList);
      setCountriesList(newCountriesList);
    }
  }, [dateStart, dateStop]);

  return (
    <OrderCountriesBlock>
      <OrderCountriesList>
        {!!countriesList.length && countriesList.map((country, countryIndex) =>
          <CampaignCountryItem
            key={countryIndex}
            countryIndex={countryIndex}
            country={country}
            putEditedCountryInList={putEditedCountryInList}
            handleCloneCurrentCountry={handleCloneCurrentCountry}
            handleRemoveCurrentCountry={handleRemoveCurrentCountry}
          />
        )}
      </OrderCountriesList>
      <CountriesTotalBlock
        handleAddNewCountry={handleAddNewCountry}
      />
    </OrderCountriesBlock>
  );
};

export default CreateCampaignCountriesList;