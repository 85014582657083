import React, {useState} from 'react';
import {StyledInput, StyledInputBlock} from './styled';

interface IInputProps {
  getInputValue: (value: string | null) => void;
  placeholder: string;
  size: 'md' | 'lg';
}

const SearchInput = ({getInputValue, placeholder, size}: IInputProps) => {
  const [value, setValue] = useState('');

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    if (e.target.value === '') {
      getInputValue(null);
    } else {
      getInputValue(e.target.value);
    }
  };

  return (
    <StyledInputBlock size={size}>
      <StyledInput type="text" value={value} onChange={handleChangeValue} placeholder={placeholder} maxLength={30}/>
    </StyledInputBlock>
  );
};

export default SearchInput;