import styled from "styled-components";
import {BasicModalWrapper} from "../../../../../../../Theme/modalsElements";

export const SettingsModal = styled(BasicModalWrapper)`
  min-width: 360px;
`;

export const SettingsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;

  list-style-type: none;
  margin: 0 0 48px;
  padding: 0;
`;

export const SettingsItem = styled.li`
  display: flex;
  gap: 16px;
  align-items: center;

  font-family: ${props => props.theme.font_style.font_family};
  font-size: ${props => props.theme.font_sizes.content};
  color: ${props => props.theme.colors.black};
`;