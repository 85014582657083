import styled from 'styled-components';
import {BasicPageTitle} from '../../Theme/basicElements';
import logoIcon from '../../Images/svg/header-logo-icon.svg';
import logoText from '../../Images/png/header-logo-text.png';
import {IThemeProvider} from '../../Theme/defaultTheme';

export const PrivacyContainer = styled.div<IThemeProvider>`
  position: relative;
  width: 100%;

  background: #BFC7EF;

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  gap: ${props => props.theme.format.main_lg_gap};

  &::before {
    content: '';
    width: 3480px;
    height: 3146px;
    border-radius: 3480.865px;
    background: linear-gradient(309deg, #E5E9FC 17.66%, rgba(232, 234, 249, 0.00) 100%);
    filter: blur(5px);

    position: absolute;
    right: -277px;
    bottom: -77px;
  }

  &::after {
    content: '';
    width: 1731.433px;
    height: 1731.433px;
    border-radius: 1731.433px;
    background: linear-gradient(101deg, #C4CDF9 0%, rgba(232, 234, 249, 0.00) 100%);
    filter: blur(5px);

    position: absolute;
    top: -1223px;
    right: -90px;
  }
`;

export const PrivacyHeader = styled.div<IThemeProvider>`
  display: flex;
  justify-content: center;
  padding: ${props => props.theme.format.main_lg_padding};
  z-index: 2;
`;

export const PrivacyHeaderLogo = styled.div`
  display: flex;
  align-items: center;
  gap: 34px;
`;

export const PrivacyLogoIcon = styled.div`
  display: block;
  width: 35px;
  height: 59px;
  background-image: url(${logoIcon});
  background-repeat: no-repeat;
  background-size: contain;
`;

export const PrivacyLogoText = styled.div`
  display: block;
  width: 246px;
  height: 48px;
  background-image: url(${logoText});
  background-repeat: no-repeat;
  background-size: contain;
`;

export const PrivacyTitle = styled(BasicPageTitle)<IThemeProvider>`
  z-index: 2;
  color: ${props => props.theme.colors.black};
`;

export const PrivacyWrapper = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 ${props => props.theme.format.main_lg_padding} 60px;
  z-index: 2;
`;

export const PrivacyContentWrapper = styled.div<IThemeProvider>`
  width: 100%;
  background-color: ${props => props.theme.colors.white};
  padding: 32px;
  border-radius: ${props => props.theme.format.main_lg_border_radius};
`;

