import React, {useEffect, useState} from 'react';
import {Route, Routes} from 'react-router-dom';
import AuthPage from '../../Pages/AuthPage';
import ForgotPasswordPage from '../../Pages/ForgotPasswordPage';
import {RECAPTCHA_KEY} from '../../Constants/ApiConstants';
import PrivacyPage from '../../Pages/PrivacyPage';

const CaptchaContainer = () => {
  const [captchaToken, setCaptchaToken] = useState('');

  const handleLoaded = () => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(RECAPTCHA_KEY, {action: 'login'})
        .then(token => {
          setCaptchaToken(token);
        });
    });
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_KEY}`;
    script.addEventListener('load', handleLoaded);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      const recaptchaElement = document.querySelector('.grecaptcha-badge');
      if (recaptchaElement) {
        recaptchaElement.remove();
      }
    };

  }, []);

  return (
    <>
      <Routes>
        <Route path={'/authorization'} element={<AuthPage captchaToken={captchaToken} authMode={'singIn'}/>}/>
        <Route path={'/registration/:refKey?'} element={<AuthPage captchaToken={captchaToken} authMode={'singUp'}/>}/>
        <Route path={'/reset-password/*'} element={<ForgotPasswordPage captchaToken={captchaToken}/>}/>
        <Route path={'/registration/privacy_policy'} element={<PrivacyPage content={'privacyPolicy'}/>}/>
        <Route path={'/registration/terms_of_use'} element={<PrivacyPage content={'termsOfUse'}/>}/>
      </Routes>
    </>
  );
};

export default CaptchaContainer;