import React from 'react';
import {PaymentsList} from './styled';
import PaymentMethodCard from '../PaymentMethodCard';
import {ALL_PAYMENT_METHODS_LIST} from '../../../../Constants/PaymentMethodsList';


const PaymentMethodsList = () => {
  return (
    <PaymentsList>
      {ALL_PAYMENT_METHODS_LIST.map(method =>
        <PaymentMethodCard title={method.title} currency={method.currency} icon={method.icon} key={method.title}/>
      )}
    </PaymentsList>
  );
};

export default PaymentMethodsList;