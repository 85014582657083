import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {API_URL} from '../../Constants/ApiConstants';
import {errorsActions} from '../Slices/errors.slice';
import {LS_USER_TOKEN} from '../../Constants/LSConstants';
import {authActions} from '../Slices/auth.slice';
import {IPaymentItem, IWalletItem} from '../../Models/ApiModels';

const {addError} = errorsActions;
const {setAuthenticated} = authActions;

export const PaymentsApi = createApi({
  reducerPath: 'PaymentsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL + '/client',
    prepareHeaders: headers => {
      const token: string | null = localStorage.getItem(LS_USER_TOKEN);

      if (token) headers.set('token', token);
    }
  }),
  tagTypes: ['PaymentsList'],
  endpoints: (build) => ({
    getPayments: build.query<IPaymentItem[], void>({
      query: () => ({
        url: `/payments`,
      }),
      providesTags: result => ['PaymentsList'],
      async onQueryStarted(_, {queryFulfilled, dispatch}) {
        try {
          await queryFulfilled;
        } catch (error: any) {
          dispatch(addError(error.error));
          if (error.error.status === 401) {
            dispatch(setAuthenticated(false));
          }
        }
      }
    }),
    getWallet: build.query<IWalletItem, void>({
      query: () => ({
        url: `/getWallet`,
      }),
      async onQueryStarted(_, {queryFulfilled, dispatch}) {
        try {
          await queryFulfilled;
        } catch (error: any) {
          dispatch(addError(error.error));
          if (error.error.status === 401) {
            dispatch(setAuthenticated(false));
          }
        }
      }
    })
  })
});

export const {useGetPaymentsQuery, useGetWalletQuery} = PaymentsApi;