import styled from 'styled-components';
import { IThemeProvider } from '../../Theme/defaultTheme';

export const ProfileBlockItem = styled.div<IThemeProvider>`
  padding: 0;
  margin: 0;

  display: flex;
  align-items: center;
`;

