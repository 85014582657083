import React, {useState} from 'react';
import {useAuthorizationMutation, useRegistrationMutation} from '../../Store/APIs/Auth.api';
import {useNavigate, useParams} from 'react-router-dom';
import {
  AuthFormBlock,
  AuthLogo,
  AuthLogoBlock,
  AuthLogoIcon,
  AuthLogoText,
  AuthStoreBlock, AuthStoreIcon, AuthStoreItem, AuthStoreText,
  StyledAuthPage
} from './styled';
import AuthWindow from './Components/AuthWindow';
import {validationAuthForm} from './Helpers/ValidationHelpers';
import {ERROR_INCORRECT_EMAIL_OR_PASSWORD, ERROR_USER_ALREADY_EXIST} from './Constants/AuthErrorsList';
import {throwNewToastError} from '../../Utils/MainUtils';

export interface IUserData {
  username: string,
  password: string,
  retryPassword?: string,
  authMode: 'singIn' | 'singUp'
}

interface IAuthPageProps {
  captchaToken: string;
  authMode: 'singIn' | 'singUp';
}

const AuthPage: React.FC<IAuthPageProps> = ({captchaToken, authMode}) => {
  const navigate = useNavigate();
  const {refKey} = useParams();

  const [authorization] = useAuthorizationMutation();
  const [registration] = useRegistrationMutation();

  const [errorsList, setErrorsList] = useState<string[]>([]);

  const handleClearErrorsList = () => {
    setErrorsList([]);
  };

  const handleSubmit = ({username, password, retryPassword, authMode}: IUserData) => {
    const errorsList = validationAuthForm(username, password, authMode, retryPassword);

    if (errorsList.length) {
      setErrorsList(errorsList);
      return;
    } else {
      try {
        if (authMode === 'singIn') {
          authorization({username: username.trim(), password: password.trim(), captchaToken})
            .unwrap()
            .then((result) => {
                if (result && result.token) {
                  navigate('/');
                }
              }
            ).catch((error) => {
            if (error && 'status' in error && (error.status === 401)) {
              setErrorsList(prevState => [...prevState, ERROR_INCORRECT_EMAIL_OR_PASSWORD]);
            }
            if (error && 'status' in error && (error.status === 403)) {
              throwNewToastError('An error occurred, please try later');
            }
            if (error && 'data' in error && error.data) {
              throwNewToastError(error.data);
            }
          });
        }
        if (authMode === 'singUp') {
          registration({
            username: username.trim(),
            password: password.trim(),
            captchaToken,
            referral: refKey
          }).unwrap()
            .then((result) => {
                if (result && result.token) {
                  navigate('/');
                }
              }
            ).catch((error) => {
            if (error && 'data' in error && (error.status === 500)) {
              setErrorsList(prevState => [...prevState, ERROR_USER_ALREADY_EXIST]);
            } else if (error && 'status' in error && (error.status === 403)) {
              throwNewToastError('An error occurred, please try later');
            } else {
              console.error(error);
            }
          });
        }
      } catch (error) {
        console.error(error);
      }
    }

  };

  return (
    <StyledAuthPage
      className="g-recaptcha"
      data-sitekey="_reCAPTCHA_site_key_"
      data-size="invisible"
    >
      <AuthLogoBlock>
        <AuthLogo>
          <AuthLogoIcon/>
          <AuthLogoText/>
        </AuthLogo>
        <AuthStoreBlock>
          <AuthStoreItem>
            <AuthStoreIcon data-store={'appStore'}/>
            <AuthStoreText>Installations for your apps <br/>on Google Play and App Store</AuthStoreText>
            <AuthStoreIcon data-store={'google'}/>
          </AuthStoreItem>
        </AuthStoreBlock>
      </AuthLogoBlock>
      <AuthFormBlock>
        <AuthWindow
          handleSubmit={handleSubmit}
          validationErrors={errorsList}
          handleClearErrorsList={handleClearErrorsList}
          authMode={authMode}
          refKey={refKey}
        />
      </AuthFormBlock>
    </StyledAuthPage>
  );
};

export default AuthPage;