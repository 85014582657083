import styled from 'styled-components';
import {IThemeProvider} from '../../../../Theme/defaultTheme';

export const OrdersTableContainer = styled.div<IThemeProvider>`
  height: 100%;
  overflow: hidden;

  padding: 32px;
  background-color: ${props => props.theme.colors.primary_light};
  border-radius: ${props => props.theme.format.main_lg_border_radius};
`;

export const OrdersTableScrollWrapper = styled.div`
  position: relative;
  height: 100%;
  overflow-y: auto;
  width: 100%;
`;

export const OrdersTable = styled.table<IThemeProvider>`

  width: 100%;
  font-family: ${props => props.theme.font_style.font_family};
  font-weight: ${props => props.theme.font_style.font_weight.regular};
  border-collapse: collapse;
`;

export const OrdersThead = styled.thead<IThemeProvider>`
`;

export const OrdersTbody = styled.tbody`
  height: 100%;
`;

export const OrdersTr = styled.tr``;

export const OrdersTh = styled.th<IThemeProvider>`
  font-weight: ${props => props.theme.font_style.font_weight.regular};
  color: ${props => props.theme.colors.grey};
  text-align: left;
  padding: 0 8px 16px;
  border-bottom: 1px solid ${props => props.theme.colors.grey_semi};

  font-size: ${props => props.theme.font_sizes.content};
`;

export const OrdersTd = styled.td`
  font-weight: ${props => props.theme.font_style.font_weight.regular};
  color: ${props => props.theme.colors.black};
  padding: 20px 8px;
  vertical-align: middle;

  font-size: ${props => props.theme.font_sizes.content};
`;