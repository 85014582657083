interface IErrorsItems {
  [error: string]: string;
}

export const ERRORS_ITEMS: IErrorsItems = {
  error_lack_app: 'Please choose an application',
  error_lack_type: 'Please choose a type',
  error_lack_date: 'Please select start date and end date',
  error_lack_countries: 'Please add at least one country',
  error_unselect_country: 'Please check selected countries',
  error_lack_money: 'Insufficient funds to create a campaign',
  error_too_mach_countries: 'You can run campaign with delayed start only for one country',
  error_no_values_keys: 'Please fill in the values for at least one key',
  delayed_start_empty: 'Attention, the “delayed start” attribute is selected, please select a time',
  error_delayed_time_exist: 'Specify the correct time for the Delayed start'
};