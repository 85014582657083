import styled from 'styled-components';
import {IThemeProvider} from '../../../../../../Theme/defaultTheme';
import {BasicModalTitle} from '../../../../../../Theme/modalsElements';

export const DeleteModalTitle = styled(BasicModalTitle)`
  text-align: center;
`;

export const DeleteModalText = styled.p<IThemeProvider>`
  text-align: center;
  max-width: 300px;
  font-family: ${props => props.theme.font_style.font_family};
  font-weight: ${props => props.theme.font_style.font_weight.regular};
  font-size: ${props => props.theme.font_sizes.large_content};
  color: ${props => props.theme.colors.black};
  margin: 0 auto 74px;
`;

export const DeleteModalBtnBlock = styled.div<IThemeProvider>`
  display: flex;
  gap: ${props => props.theme.format.main_lg_gap};
  width: 100%;
  justify-content: center;
`;