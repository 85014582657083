import React from 'react';
import {BasicBlockContent} from '../../../../Theme/basicElements';
import {ContactItem, ContactWrapper, EmailIcon, TelegramIcon} from './styled';

const ContactsModule = () => {
  return (
    <ContactWrapper>
      <ContactItem href="mailto:support@asofast.io" target={'_blank'}>
        <EmailIcon>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17 21.4795H7C4 21.4795 2 19.9795 2 16.4795V9.47949C2 5.97949 4 4.47949 7 4.47949H17C20 4.47949 22 5.97949 22 9.47949V16.4795C22 19.9795 20 21.4795 17 21.4795Z"
              stroke="currentColor" strokeOpacity="1" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round"
              strokeLinejoin="round"/>
            <path d="M17 9.97949L13.87 12.4795C12.84 13.2995 11.15 13.2995 10.12 12.4795L7 9.97949"
                  stroke="currentColor"
                  strokeOpacity="1" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round"
                  strokeLinejoin="round"/>
          </svg>
        </EmailIcon>
        <BasicBlockContent>E-mail</BasicBlockContent>
      </ContactItem>
      <ContactItem href="https://t.me/g0ogIe" target={'_blank'}>
        <TelegramIcon>
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M22 12.9795C22 18.5023 17.5228 22.9795 12 22.9795C6.47715 22.9795 2 18.5023 2 12.9795C2 7.45665 6.47715 2.97949 12 2.97949C17.5228 2.97949 22 7.45665 22 12.9795ZM12.3583 10.3619C11.3857 10.7665 9.44176 11.6038 6.52656 12.8739C6.05318 13.0622 5.8052 13.2464 5.78262 13.4264C5.74447 13.7308 6.12558 13.8506 6.64454 14.0138C6.71513 14.036 6.78828 14.059 6.86326 14.0833C7.37385 14.2493 8.06067 14.4435 8.41772 14.4512C8.7416 14.4582 9.10309 14.3247 9.50218 14.0506C12.2259 12.212 13.632 11.2827 13.7202 11.2626C13.7825 11.2485 13.8688 11.2307 13.9273 11.2827C13.9858 11.3347 13.98 11.4331 13.9738 11.4595C13.9361 11.6205 12.4401 13.0112 11.6659 13.731C11.4246 13.9554 11.2534 14.1145 11.2184 14.1509C11.14 14.2323 11.0601 14.3093 10.9833 14.3833C10.509 14.8406 10.1532 15.1835 11.003 15.7435C11.4113 16.0126 11.7381 16.2351 12.0641 16.4571C12.4201 16.6996 12.7752 16.9414 13.2347 17.2426C13.3517 17.3193 13.4635 17.399 13.5724 17.4766C13.9867 17.772 14.3589 18.0374 14.8188 17.995C15.086 17.9705 15.362 17.7192 15.5022 16.9698C15.8335 15.1988 16.4847 11.3616 16.6352 9.78031C16.6483 9.64178 16.6318 9.46448 16.6184 9.38665C16.6051 9.30882 16.5773 9.19793 16.4761 9.11583C16.3563 9.01862 16.1713 8.99811 16.0886 8.99957C15.7125 9.0062 15.1354 9.20685 12.3583 10.3619Z"
                  fill="currentColor" fillOpacity="1"/>
          </svg>
        </TelegramIcon>
        <BasicBlockContent>Telegram</BasicBlockContent>
      </ContactItem>
    </ContactWrapper>
  );
};

export default ContactsModule;