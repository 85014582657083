import React from 'react';
import {TableButton} from './styled';

interface IButtonProps {
  onClick: () => void;
  role: string;
  title?: string;
}

const RatioButton: React.FC<IButtonProps> = React.memo(({onClick, role, title}) => {
  return (
    <TableButton onClick={onClick} data-role={role} title={title}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
        <path
          d="M10.1103 12.0703H7.4603C6.8303 12.0703 6.32031 12.5803 6.32031 13.2103V18.3303H10.1103V12.0703V12.0703Z"
          stroke="currentColor" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path
          d="M12.7616 7.52002H11.2415C10.6115 7.52002 10.1016 8.03003 10.1016 8.66003V18.3201H13.8916V8.66003C13.8916 8.03003 13.3916 7.52002 12.7616 7.52002Z"
          stroke="currentColor" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16.5484 13.77H13.8984V18.3201H17.6884V14.91C17.6784 14.28 17.1684 13.77 16.5484 13.77Z"
              stroke="currentColor" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round"
              strokeLinejoin="round"/>
        <path
          d="M9 22.9199H15C20 22.9199 22 20.9199 22 15.9199V9.91992C22 4.91992 20 2.91992 15 2.91992H9C4 2.91992 2 4.91992 2 9.91992V15.9199C2 20.9199 4 22.9199 9 22.9199Z"
          stroke="currentColor" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </TableButton>
  );
});

export default RatioButton;