import styled from 'styled-components';
import {IThemeProvider} from '../../../../Theme/defaultTheme';

export const MakePaymentContainer = styled.div<IThemeProvider>`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.format.main_lg_gap};
  width: 1048px;

  padding: ${props => props.theme.format.main_lg_padding};
  border-radius: ${props => props.theme.format.main_lg_border_radius};
  background-color: ${props => props.theme.colors.primary_light};
`;