import React, {useEffect, useState} from 'react';
import {MyAppsList} from "./styled";
import AddNewAppCard from "../AddNewAppCard";
import {IAppItem} from "../../../../../../Models/ApiModels";
import MyAppCard from "../MyAppCard";
import {useGetAppsQuery} from "../../../../../../Store/APIs/Apps.api";
import useDebounce from "../../../../../../Hooks/useDebounce/useDebounce";
import AddNewAppModal from "../../Modals/AddNewAppModal";
import {Modal} from "@mui/material";

interface IAppsCardsListProps {
  appSearchValue: string | null
}

const AppsCardsList = React.memo(({appSearchValue}: IAppsCardsListProps) => {
  const [openAddAppModal, setOpenAddAppModal] = useState(false);

  const debouncedSearchValue = useDebounce(appSearchValue, 500)?.trim();

  const {data: appsList = [], isLoading: isLoadingApps, isError, refetch} = useGetAppsQuery({
    offset: 0,
    limit: 999,
    search: debouncedSearchValue
  });

  //Перезапрос списка приложений при новой отрисовке списка
  useEffect(() => {
    refetch();
  }, []);

  const handleOpenAddAppModal = () => {
    setOpenAddAppModal(true);
  };

  const handleCloseAddAppModal = () => {
    setOpenAddAppModal(false);
  };

  return (
    <>
      <MyAppsList>
        <AddNewAppCard onClick={handleOpenAddAppModal}/>
        {appsList.length > 0 &&
          appsList.map((appCard: IAppItem) => <MyAppCard key={appCard.id} appCard={appCard}/>)}
      </MyAppsList>
      <Modal
        open={openAddAppModal}
        onClose={handleCloseAddAppModal}
      >
        <AddNewAppModal onClose={handleCloseAddAppModal}/>
      </Modal>
    </>
  );
});

export default AppsCardsList;