import React from 'react';
import {BasicPageTitle, BasicPageWrapper} from '../../Theme/basicElements';
import MakePaymentModule from './Modules/MakePaymentModule';
import FinancialLogsModule from './Modules/FinancialLogsModule';

const FinancePage = () => {

  return (
    <BasicPageWrapper>
      <BasicPageTitle>Add money</BasicPageTitle>
      <MakePaymentModule/>
      <FinancialLogsModule/>
    </BasicPageWrapper>
  );
};

export default FinancePage;