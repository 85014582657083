import React from 'react';
import {SubmitBlock} from './styled';
import BasicButton from '../../../../../UI/BasicButton';

interface ICreateCampaignSubmitBlockProps {
  handleCreateNewCampaign: () => void;
  handleResetAllForms: () => void;
}

const CreateCampaignSubmitBlock: React.FC<ICreateCampaignSubmitBlockProps> = ({
                                                                                handleCreateNewCampaign,
                                                                                handleResetAllForms
                                                                              }) => {
  return (
    <SubmitBlock>
      <BasicButton onClick={handleCreateNewCampaign} size={'md'} text={'Start promotion'} variant={'blue'}/>
      <BasicButton onClick={handleResetAllForms} size={'md'} text={'Clear'} variant={'red'}
                   title={'Clear all campaign settings'}/>
    </SubmitBlock>
  );
};

export default CreateCampaignSubmitBlock;