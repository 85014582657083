import React from 'react';
import {BasicPageTitle} from '../../Theme/basicElements';
import ContactsModule from './Modules/ContactsModule';
import FAQModule from './Modules/FAQModule';
import {ContactUsWrapper} from './styled';

const FAQPage = () => {
  return (
    <ContactUsWrapper>
      <BasicPageTitle>Contact Us</BasicPageTitle>
      <ContactsModule/>
      <FAQModule/>
    </ContactUsWrapper>
  );
};

export default FAQPage;