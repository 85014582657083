import React from 'react';
import {Loader} from 'rsuite';

interface IBlockLoaderProps {
  size: 'sm' | 'md' | 'lg';
  loadingText?: string;
}

const BlockLoader: React.FC<IBlockLoaderProps> = ({size, loadingText}) => {
  return (
    <Loader center size={size} content={loadingText}/>
  );
};

export default BlockLoader;