import styled from 'styled-components';
import {IThemeProvider} from '../../../../../../Theme/defaultTheme';

export const AppNameBlock = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const AppNameIcon = styled.img`
  width: 32px;
  height: 32px;
  object-fit: contain;
  display: block;
  margin-right: 8px;
  border-radius: 6px;
`;

export const AppNameText = styled.span<IThemeProvider>`
  font-size: ${props => props.theme.font_sizes.content};
`;