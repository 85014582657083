import styled from 'styled-components';
import {BasicContentSpan} from '../../../../Theme/basicElements';
import {IThemeProvider} from '../../../../Theme/defaultTheme';

export const TimeBlock = styled.div<IThemeProvider>`
  display: flex;
  gap: 16px;
  align-items: center;
  color: ${props => props.theme.colors.brand_blue}
`;

export const UTCValue = styled(BasicContentSpan)`
  font-weight: 700;
`;