import styled from 'styled-components';

interface IAppProps {
  readonly 'data-rolled': boolean;
}

export const AppContent = styled.div<IAppProps>`
  display: grid;
  grid-template-columns: 96px 1fr;
  transition: ease-in 500ms;
  height: 100%;
  position: relative;

  &[data-rolled='true'] {
    grid-template-columns: 220px 1fr;
  }
`;

export const Application = styled.div`
  position: relative;
`;