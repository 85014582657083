import styled from 'styled-components';
import {IThemeProvider} from '../../../Theme/defaultTheme';

export const BasicOrderFormRow = styled.div<IThemeProvider>`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.format.main_md_gap};
`;

export const BasicOrderFormRowLabel = styled.div`
  width: 163px;
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 8px;
`;

export const BasicOrderFormRowTitle = styled.span<IThemeProvider>`
  font-family: ${props => props.theme.font_style.font_family};
  font-weight: ${props => props.theme.font_style.font_weight.regular};
  font-size: ${props => props.theme.font_sizes.content};
  color: ${props => props.theme.colors.black};
`;

export const PackageTypeBlock = styled.div<IThemeProvider>`
  display: flex;
  gap: ${props => props.theme.format.main_md_gap};
  color: ${props => props.theme.colors.black};
`;