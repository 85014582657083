import React, {forwardRef} from 'react';
import {DelayStartBodyWrapper, TimeSelectorBlock, TimeSelectorInput, TimeSelectorWarning} from './styled';
import DatePicker from 'react-datepicker';
import {useAppSelector} from '../../../../../../../Hooks/redux/useAppSelector';
import {getDateWithoutGTMOffset} from '../../../../../../../Utils/DateUtils';

interface ICustomDatePickerSelectorProps {
  value?: string;
  onClick?: () => void;
  placeholder?: string;
  disabled: boolean;
}

interface IDelayStartAttributeProps {
  isDisabled: boolean;
  handleSetTimeString: (date: Date | null) => void;
}

const DelayStartAttribute: React.FC<IDelayStartAttributeProps> = ({
                                                                    isDisabled,
                                                                    handleSetTimeString,
                                                                  }) => {
  const CustomDatePickerSelector = forwardRef<HTMLInputElement, ICustomDatePickerSelectorProps>(({
                                                                                                   value,
                                                                                                   onClick,
                                                                                                   placeholder,
                                                                                                   disabled
                                                                                                 }, ref) => (
    <TimeSelectorInput
      onClick={onClick}
      value={value}
      placeholder={placeholder}
      ref={ref}
      disabled={disabled}
      readOnly
      data-in-error={!(!disabled && value)}
    />
  ));

  const {dateStart, delayedStartValue} = useAppSelector(state => state.newOrderSlice);

  const getMaxTimeValue = () => {
    return new Date(new Date().setHours(23, 59, 0, 0));
  };

  const getSelectedTime = () => {
    if (delayedStartValue && dateStart) {
      const [hours, minutes] = delayedStartValue.split(' ')[0].split(':');

      const currentDate = new Date(dateStart);
      currentDate.setHours(Number(hours), Number(minutes));
      return currentDate;
    } else {
      return null;
    }
  };

  const getTimeMinValue = (dateStart: string | null) => {
    const currentDate = getDateWithoutGTMOffset(new Date());
    if (dateStart && new Date(new Date(dateStart).setHours(0, 0, 0, 0)).getTime() <= new Date(currentDate.setHours(0, 0, 0, 0)).getTime()) {
      return getDateWithoutGTMOffset(new Date());
    } else {
      return new Date(currentDate.setHours(0, 0, 0, 0));
    }
  };

  return (
    <DelayStartBodyWrapper>
      <TimeSelectorBlock>
        <DatePicker
          selected={getSelectedTime()}
          minTime={getTimeMinValue(dateStart)}
          maxTime={getMaxTimeValue()}
          onChange={handleSetTimeString}
          customInput={<CustomDatePickerSelector disabled={!dateStart || isDisabled}/>}
          placeholderText={'Select offset'}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={30}
          timeCaption="Time"
          dateFormat="HH:mm"
          timeFormat="HH:mm"
          disabled={!dateStart || isDisabled}
        />
        {(!isDisabled && !dateStart) &&
          <TimeSelectorWarning>Specify the start date of the campaign</TimeSelectorWarning>}
      </TimeSelectorBlock>
    </DelayStartBodyWrapper>
  );
};

export default DelayStartAttribute;