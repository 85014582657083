import React, {forwardRef} from 'react';
import {BasicOrderFormRow, BasicOrderFormRowLabel, BasicOrderFormRowTitle} from '../../../../../Styles/newOrdersBasics';
import HelpButton from '../../../../../Components/HelpButton';
import {CustomDateSelectorInput, DateSelectorBlock, DateSelectorInputContainer} from './styled';
import DatePicker from 'react-datepicker';
import {useAppSelector} from '../../../../../../../Hooks/redux/useAppSelector';
import {BasicRedAccentSpan} from '../../../../../../../Theme/basicElements';
import {getDateWithoutGTMOffset} from '../../../../../../../Utils/DateUtils';

interface ICustomDatePickerSelectorProps {
  value?: string;
  onClick?: () => void;
  placeholder?: string;
  disabled?: boolean;
}

interface IDateSelectorRowProps {
  handleSetDateStart: (date: Date | null) => void;
  handleSetDateStop: (date: Date | null) => void;
}

const DateSelectorRow: React.FC<IDateSelectorRowProps> = ({
                                                            handleSetDateStart,
                                                            handleSetDateStop
                                                          }) => {
  const CustomDatePickerSelector = forwardRef<HTMLInputElement, ICustomDatePickerSelectorProps>(({
                                                                                                   value,
                                                                                                   onClick,
                                                                                                   placeholder,
                                                                                                   disabled
                                                                                                 }, ref) => (
    <CustomDateSelectorInput onClick={onClick} value={value} placeholder={placeholder} ref={ref} readOnly
                             disabled={disabled}/>
  ));

  const {dateStart, dateStop, activeScenario} = useAppSelector(state => state.newOrderSlice);

  return (
    <BasicOrderFormRow>
      <BasicOrderFormRowLabel>
        <BasicOrderFormRowTitle>Campaign period <BasicRedAccentSpan>*</BasicRedAccentSpan></BasicOrderFormRowTitle>
        <HelpButton popupText={'Set the date or date range of the campaign'}/>
      </BasicOrderFormRowLabel>
      <DateSelectorBlock>
        <DateSelectorInputContainer>
          <DatePicker
            onChange={handleSetDateStart}
            customInput={<CustomDatePickerSelector disabled={activeScenario === 'extend'}/>}
            placeholderText={'Start date'}
            dateFormat="yyyy-MM-dd"
            closeOnScroll={true}
            minDate={getDateWithoutGTMOffset(new Date())}
            maxDate={dateStop ? new Date(dateStop) : null}
            selected={dateStart ? new Date(dateStart) : null}
            disabled={activeScenario === 'extend'}
          />
        </DateSelectorInputContainer>
        <DateSelectorInputContainer>
          <DatePicker
            onChange={handleSetDateStop}
            customInput={<CustomDatePickerSelector disabled={activeScenario === 'extend'}/>}
            placeholderText={'End date'}
            dateFormat="yyyy-MM-dd"
            closeOnScroll={true}
            minDate={dateStart ? new Date(dateStart) : getDateWithoutGTMOffset(new Date())}
            selected={dateStop ? new Date(dateStop) : null}
            disabled={activeScenario === 'extend'}
          />
        </DateSelectorInputContainer>
      </DateSelectorBlock>
    </BasicOrderFormRow>
  );
};

export default DateSelectorRow;