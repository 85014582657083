import React from 'react';
import {
  StyledCountryItem
} from './styled';
import CountrySelectorRow from '../CountrySelectorRow';
import CountryTasksBlock from '../CountryTasksBlock';
import {ICountryModel, IKeyword} from '../../../../../Models/CountryModel';
import {useActions} from '../../../../../../../Hooks/redux/useActions';
import {useAppSelector} from '../../../../../../../Hooks/redux/useAppSelector';

interface ICampaignCountryItemProps {
  country: ICountryModel;
  countryIndex: number;
  putEditedCountryInList: (country: ICountryModel, index: number) => void;
  handleCloneCurrentCountry: (countryIndex: number) => void;
  handleRemoveCurrentCountry: (countryIndex: number) => void;
}

const CampaignCountryItem: React.FC<ICampaignCountryItemProps> = ({
                                                                    country,
                                                                    countryIndex,
                                                                    putEditedCountryInList,
                                                                    handleCloneCurrentCountry,
                                                                    handleRemoveCurrentCountry
                                                                  }) => {
  const {
    changeGeoInSelectedCountry,
    putEditedKeywordsInCountry
  } = useActions();

  const {countriesList} = useAppSelector(state => state.newOrderSlice);

  const handleSetCountryGeo = (geo: string | null) => {
    changeGeoInSelectedCountry({geo, countryIndex});

    const newCountry: ICountryModel = JSON.parse(JSON.stringify(country));
    newCountry.geo = geo;
    putEditedCountryInList(newCountry, countryIndex);
  };

  const handleSetCountryKeywords = (keywords: IKeyword) => {
    if (keywords && country) {
      putEditedKeywordsInCountry({newKeywords: keywords, countryIndex: countryIndex});

      const newCountry: ICountryModel = JSON.parse(JSON.stringify(country));
      newCountry.keywords = keywords;
      putEditedCountryInList(newCountry, countryIndex);
    }
  };

  const handleCloneCountry = () => {
    handleCloneCurrentCountry(countryIndex);
  };

  const handleRemoveCountry = () => {
    handleRemoveCurrentCountry(countryIndex);
  };

  const checkCountryIsDuplicate = () => {
    return countriesList.some((c, index) => (c.geo === country.geo) && (index !== countryIndex) && (c.geo !== null) && (country.geo !== null));
  };

  return (
    <StyledCountryItem>
      <CountrySelectorRow
        selectedCountry={country.geo}
        handleSetCountryGeo={handleSetCountryGeo}
        isDuplicate={checkCountryIsDuplicate()}
      />
      <CountryTasksBlock
        handleRemoveCurrentCountry={handleRemoveCountry}
        country={country}
        countryIndex={countryIndex}
        handleSetCountryKeywords={handleSetCountryKeywords}
        handleCloneCountry={handleCloneCountry}
      />
    </StyledCountryItem>
  );
};

export default CampaignCountryItem;