import styled from 'styled-components';
import {BasicOrderFormRow, BasicOrderFormRowLabel, BasicOrderFormRowTitle} from '../../../../../Styles/newOrdersBasics';

export const PackageControlBlock = styled.div`
  display: flex;
  gap: ${props => props.theme.format.main_lg_gap};
  align-items: center;
`;

export const PackageControlRow = styled(BasicOrderFormRow)`
  align-items: center;
`;

export const PackageAdditionLabel = styled(BasicOrderFormRowLabel)`
  width: 139px;
  padding: ${props => props.theme.format.main_md_gap} 0;
`;

export const PackageAdditionTitle = styled(BasicOrderFormRowTitle)``;