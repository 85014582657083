import React, {useEffect, useState} from 'react';
import {TimeBlock, UTCValue} from './styled';
import {BasicContentSpan} from '../../../../Theme/basicElements';
import moment from 'moment';
import {getDateWithoutGTMOffset} from '../../../../Utils/DateUtils';

interface ServerTimeProps {
  unix: number;
}

const ServerTime: React.FC<ServerTimeProps> = ({unix}) => {
  const [currentTime, setCurrentTime] = useState<number>(unix * 1000);
  const [currentDate, setCurrentDate] = useState('');

  useEffect(() => {
    // Обновляем текущее время и дату при получении нового unix времени от сервера
    const serverTime = unix * 1000;
    setCurrentTime(serverTime);

    const date = moment(getDateWithoutGTMOffset(new Date(serverTime))).format('ddd DD-MMM-YYYY');
    setCurrentDate(date);
  }, [unix]);

  useEffect(() => {
    // Устанавливаем интервал для обновления времени каждую секунду
    const interval = setInterval(() => {
      setCurrentTime((prevTime) => prevTime + 1000);
    }, 1000);

    // Очищаем интервал при размонтировании компонента
    return () => clearInterval(interval);
  }, []);

  return (
    <TimeBlock>
      <BasicContentSpan>Server Time: </BasicContentSpan>
      <UTCValue>{moment(new Date(currentTime)).utc().format('HH:mm:ss')} UTC+0 <br/>
        {currentDate}</UTCValue>
    </TimeBlock>
  );
};

export default ServerTime;