import styled from 'styled-components';

export const MyAppsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  width: 100%;
  box-sizing: border-box;

  gap: 32px;
`;