import React from 'react';
import {PaymentCardButton, PaymentCardContent, PaymentCardCurrency, PaymentCardIcon, PaymentCardTitle} from './styled';

interface IPaymentMethodCardProps {
  currency: string;
  title: string;
  icon: string;
}


const PaymentMethodCard: React.FC<IPaymentMethodCardProps> = ({currency, icon, title}) => {
  return (
    <PaymentCardButton data-is-selected={true}>
      <PaymentCardContent>
        <PaymentCardIcon>
          <img src={icon} alt={title}/>
        </PaymentCardIcon>
        <PaymentCardCurrency>{currency}</PaymentCardCurrency>
        <PaymentCardTitle>{title}</PaymentCardTitle>
      </PaymentCardContent>
    </PaymentCardButton>
  );
};

export default PaymentMethodCard;