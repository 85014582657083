import React from 'react';
import {AddCard, AddCardTextBlock, AddCartText} from './styled';

interface IAddNewAppCard {
  onClick: () => void;
}

const AddNewAppCard = ({onClick}: IAddNewAppCard) => {
  return (
    <AddCard onClick={onClick}>
      <AddCardTextBlock>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path fillRule="evenodd" clipRule="evenodd"
                d="M1.22949 6.99982C0.677207 6.99982 0.229492 7.44753 0.229492 7.99982C0.229492 8.5521 0.677207 8.99982 1.22949 8.99982L6.99973 8.99982L6.99973 14.7703C6.99973 15.3225 7.44745 15.7703 7.99973 15.7703C8.55202 15.7703 8.99973 15.3225 8.99973 14.7703L8.99973 8.99982L14.77 8.99982C15.3223 8.99982 15.77 8.5521 15.77 7.99982C15.77 7.44753 15.3223 6.99982 14.77 6.99982L8.99973 6.99982L8.99973 1.22977C8.99973 0.677488 8.55201 0.229774 7.99973 0.229774C7.44745 0.229774 6.99973 0.677488 6.99973 1.22977L6.99973 6.99982L1.22949 6.99982Z"
                fill="#4F68E8"/>
        </svg>
        <AddCartText>Add new app</AddCartText>
      </AddCardTextBlock>
    </AddCard>
  );
};

export default AddNewAppCard;