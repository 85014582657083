import styled from 'styled-components';
import {BasicModalTitle, BasicModalWrapper} from '../../Theme/modalsElements';
import {IThemeProvider} from '../../Theme/defaultTheme';
import logoIcon from '../../Images/svg/header-logo-icon.svg';
import logoText from '../../Images/png/header-logo-text.png';
import {BasicContentSpan} from '../../Theme/basicElements';
import emailIcon from '../../Images/svg/email-icon.svg';
import passwordIcon from '../../Images/svg/shield-tick.svg';

export const ForgotPage = styled.div<IThemeProvider>`
  width: 100%;
  height: 100vh;

  background-color: rgba(80, 99, 192, 0.2);
  position: relative;
`;

export const ForgotLogoBlock = styled.div`
  width: 295px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 13px;

  position: absolute;
  left: 0;
  top: 0;
`;

export const LogoIcon = styled.div`
  width: 14px;
  height: 24px;
  background-image: url(${logoIcon});
  background-repeat: no-repeat;
  background-size: contain;
`;

export const LogoTitle = styled.div`
  background-image: url(${logoText});
  background-repeat: no-repeat;
  background-size: contain;
  width: 100px;
  height: 20px;
`;

export const ForgotWindow = styled(BasicModalWrapper)`
  gap: 64px;
  overflow: hidden;
`;

export const ForgotTitle = styled(BasicModalTitle)`
  text-align: center;
  margin: 0;
`;

export const ForgotTitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  width: 100%;
`;

export const ForgotFootnote = styled(BasicContentSpan)`
  text-align: center;
`;

export const ForgotInputsBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const ForgotButtonsBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 100%;
`;

export const ForgotThanksBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const ForgotEmailIcon = styled.div`
  width: 128px;
  height: 128px;

  background-image: url(${emailIcon});
  align-self: center;
  background-size: contain;

  opacity: 0.4;
`;

export const PasswordChangedIcon = styled.div`
  width: 128px;
  height: 128px;

  background-image: url(${passwordIcon});
  align-self: center;
  background-size: contain;

  opacity: 0.4;
`;

export const ForgotThanksText = styled(BasicContentSpan)<IThemeProvider>`
  color: ${props => props.theme.colors.black};
  text-align: center;
`;

export const ForgotFormErrorText = styled(BasicContentSpan)<IThemeProvider>`
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translate(-50%, 50%);

  color: ${props => props.theme.colors.secondary_red};
`;