import React, {useState} from 'react';
import {TasksBlock, TasksCheckboxBlock, TasksTableBlock, TasksTableLabel} from './styled';
import CountryTasksButtonsBlock from '../CountryTasksButtonsBlock';
import CountryTasksKeywordsTable from '../CountryTasksKeywordsTable';
import {ICountryModel, IKeyword} from '../../../../../Models/CountryModel';
import BasicCheckboxButton from '../../../../../../../UI/BasicCheckboxButton';
import {BasicContentSpan, BasicRedAccentSpan} from '../../../../../../../Theme/basicElements';
import HelpButton from '../../../../../Components/HelpButton';

interface ICountryTasksTableBlockProps {
  country: ICountryModel;
  handleSetCountryKeywords: (keywords: IKeyword) => void;
  orderType: string;
  countryIndex: number;
}

const CountryTasksTableBlock: React.FC<ICountryTasksTableBlockProps> = ({
                                                                          country,
                                                                          handleSetCountryKeywords,
                                                                          orderType,
                                                                          countryIndex
                                                                        }) => {
  const [autofillChecked, setAutofillChecked] = useState(true);

  const handleSetAutofill = () => {
    setAutofillChecked(!autofillChecked);
  };

  return (
    <TasksBlock>
      <TasksTableLabel>Tasks <BasicRedAccentSpan>*</BasicRedAccentSpan></TasksTableLabel>
      <TasksCheckboxBlock>
        <BasicCheckboxButton
          name={'autofill' + countryIndex}
          value={'autofill'}
          checked={autofillChecked}
          onChange={handleSetAutofill}/>
        <BasicContentSpan>Row autofill</BasicContentSpan>
        <HelpButton
          popupText={'If selected, the values in the cells of each day will repeat the value from the first day for each keyword'}/>
      </TasksCheckboxBlock>
      <TasksTableBlock>
        <CountryTasksButtonsBlock
          country={country}
          handleSetCountryKeywords={handleSetCountryKeywords}
          orderType={orderType}
        />
        <CountryTasksKeywordsTable
          country={country}
          handleSetCountryKeywords={handleSetCountryKeywords}
          orderType={orderType}
          autofill={autofillChecked}
        />
      </TasksTableBlock>
    </TasksBlock>
  );
};

export default CountryTasksTableBlock;