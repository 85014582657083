import React from 'react';
import {BasicPageTitle, BasicPageWrapper} from '../../../Theme/basicElements';
import CreateCampaignFormView from './Modules/CreateCampaignFormView';
import CreateCampaignAttributesView from './Modules/CreateCampaignAttributesView';
import CreateCampaignCountriesList from './Modules/CreateCampaignCountriesList';
import CreateCampaignSubmitBlock from './Modules/CreateCampaignSubmitBlock';
import {ICountryModel} from '../Models/CountryModel';
import BasicButton from '../../../UI/BasicButton';

interface ICreateCampaignFormPresenterProps {
  scenario: 'new_order' | 'promote' | 'duplicate' | 'extend';
  handleSetOrderType: (newType: string | null, prevType: string | null) => void;
  handleSetOrderApp: (app: number) => void;
  handleSetDateStart: (date: Date | null) => void;
  handleSetDateStop: (date: Date | null) => void;
  handleSetAttributesValue: (value: number) => void;
  handleSetDelayStartValue: (dateString: string | null) => void;
  handleSetCountriesListInStorage: (countriesList: ICountryModel[]) => void;
  handleCreateNewCampaign: () => void;
  handleResetAllForms: () => void;
  handleOpenImportModal?: () => void;
}

type ScenarioTitlesType = Record<string, string>

const CreateCampaignFormPresenter: React.FC<ICreateCampaignFormPresenterProps> = ({
                                                                                    scenario,
                                                                                    handleSetOrderType,
                                                                                    handleSetOrderApp,
                                                                                    handleSetAttributesValue,
                                                                                    handleSetDateStop,
                                                                                    handleSetCountriesListInStorage,
                                                                                    handleSetDateStart,
                                                                                    handleCreateNewCampaign,
                                                                                    handleResetAllForms,
                                                                                    handleSetDelayStartValue,
                                                                                    handleOpenImportModal
                                                                                  }) => {
  const SCENARIO_TITLES: ScenarioTitlesType = {
    'new_order': 'Add campaign',
    'promote': 'Promote application',
    'duplicate': 'Duplicate order',
  };

  return (
    <BasicPageWrapper>
      <BasicPageTitle>{SCENARIO_TITLES[scenario]}</BasicPageTitle>
      {scenario === 'new_order' && handleOpenImportModal &&
        <BasicButton onClick={handleOpenImportModal} size={'md'} text={'Import file'} variant={'blue'}/>}
      <CreateCampaignFormView
        handleSetOrderType={handleSetOrderType}
        handleSetOrderApp={handleSetOrderApp}
        handleSetDateStart={handleSetDateStart}
        handleSetDateStop={handleSetDateStop}
      />
      <CreateCampaignAttributesView
        handleSetAttributesValue={handleSetAttributesValue}
        handleSetDelayStartValue={handleSetDelayStartValue}
      />
      <CreateCampaignCountriesList
        handleSetCountriesListInStorage={handleSetCountriesListInStorage}
      />
      <CreateCampaignSubmitBlock
        handleCreateNewCampaign={handleCreateNewCampaign}
        handleResetAllForms={handleResetAllForms}
      />
    </BasicPageWrapper>
  );
};

export default CreateCampaignFormPresenter;