import React from 'react';
import {
  AuthInputs,
  AuthInputsBlock,
  ForgotSpan
} from '../../AuthWindow/styled';
import {
  ERROR_INCORRECT_EMAIL,
  ERROR_INCORRECT_EMAIL_OR_PASSWORD,
  ERROR_INCORRECT_PASSWORD_FIELD,
} from '../../../Constants/AuthErrorsList';
import {IAuthFormProps} from '../../../Models';
import {Link} from 'react-router-dom';
import BasicAuthInput from '../../../../../UI/BasicAuthInput';

const AuthForm: React.FC<IAuthFormProps> = ({
                                              validationErrors,
                                              username,
                                              password,
                                              handleSetEmail,
                                              handleSetPassword,
                                              isVisible,
                                              handleSetVisible
                                            }) => {
  return (
    <AuthInputsBlock>
      <AuthInputs>
        <BasicAuthInput
          type={'text'}
          placeholder={'E-mail'}
          onChange={handleSetEmail}
          value={username}
          fieldType={'email'}
          fieldError={validationErrors.includes(ERROR_INCORRECT_EMAIL) || validationErrors.includes(ERROR_INCORRECT_EMAIL_OR_PASSWORD)}
          errorText={validationErrors.includes(ERROR_INCORRECT_EMAIL) ? ERROR_INCORRECT_EMAIL : ''}
        />
        <BasicAuthInput
          fieldType={'password'}
          fieldError={validationErrors.includes(ERROR_INCORRECT_PASSWORD_FIELD) || validationErrors.includes(ERROR_INCORRECT_EMAIL_OR_PASSWORD)}
          value={password}
          onChange={handleSetPassword}
          placeholder={'Password'}
          type={isVisible ? 'text' : 'password'}
          errorText={validationErrors.includes(ERROR_INCORRECT_PASSWORD_FIELD) ? ERROR_INCORRECT_PASSWORD_FIELD : ''}
          isVisible={isVisible}
          handleSetVisible={handleSetVisible}
        />
      </AuthInputs>
      <ForgotSpan><Link to={'/reset-password'}>Forgot password?</Link></ForgotSpan>
    </AuthInputsBlock>
  );
};

export default AuthForm;