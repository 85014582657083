import {COUNTRIES_LIST} from '../../../Constants/CountriesList';
import {dateFormats} from '../Constants/DateFormats';

import * as XLSX from 'xlsx';
import moment from 'moment';
import {throwNewToastError} from '../../../Utils/MainUtils';

export const getCountryByAssociation = (value: string) => {
  try {
    let allegedCountry: string;

    if (value?.includes(',')) {
      allegedCountry = value.split(', ')[1];
    } else {
      allegedCountry = value;
    }

    const countryItem = COUNTRIES_LIST.find(
      countryItem =>
        countryItem.ISO.toLowerCase() === allegedCountry.toLowerCase() ||
        countryItem.Country.toLowerCase() === allegedCountry.toLowerCase() ||
        countryItem.Alias.some(alias => alias.toLowerCase() === allegedCountry.toLowerCase())
    );

    return countryItem ? countryItem.ISO : null;
  } catch {
    return null;
  }
};

export const getImportedDateList = (worksheet: XLSX.WorkSheet): string[] | null => {
  const result: (string | null)[] = Object.keys(worksheet)
    .filter(cell => cell.match(/[a-zA-Z]+1$/) && cell !== 'A1')
    .map(dateCell => {
      const startDate = new Date('01/01/1900');

      console.log(worksheet[dateCell]);

      if (worksheet[dateCell].hasOwnProperty('v') && typeof worksheet[dateCell].v === 'number') {
        return moment(new Date(startDate.setDate(worksheet[dateCell].v - 1))).format('YYYY-MM-DD');
      } else if (worksheet[dateCell].hasOwnProperty('w') && moment(worksheet[dateCell].w, dateFormats).isValid()) {
        return moment(worksheet[dateCell].w, dateFormats).format('YYYY-MM-DD');
      } else {
        throwNewToastError('Change the date format or contact the administrator');
        return null;
      }
    });

  if (result.includes(null)) {
    return null;
  }

  return result as string[];
};

