import React, {forwardRef} from 'react';
import {
  DepositDescription, DepositDescriptionSpan,
  PaymentInfoBlock, PaymentsWalletError,
  PaymentWalletBlock, PaymentWalletInputBlock,
  PaymentWalletLabelSpan,
  PaymentWalletModalWrapper,
  PaymentWalletQRCode
} from './styled';
import RemoveCrossButton from '../../../../../../UI/RemoveCrossButton';
import QRCode from 'react-qr-code';
import {CloseButtonBlock} from '../../../../../../Theme/modalsElements';
import {useGetWalletQuery} from '../../../../../../Store/APIs/Payments.api';
import {Loader} from 'rsuite';
import ReadableBasicInput from '../../../../../../UI/ReadableBasicInput';

interface IPaymentWalletModalProps {
  onClose: () => void;
}

const PaymentWalletModal = forwardRef(({onClose}: IPaymentWalletModalProps, ref: React.ForwardedRef<HTMLDivElement>) => {
  const {data, isLoading, isError} = useGetWalletQuery();

  return (
    <PaymentWalletModalWrapper ref={ref} tabIndex={-1}>
      <CloseButtonBlock>
        <RemoveCrossButton onClick={onClose}/>
      </CloseButtonBlock>
      <PaymentWalletBlock>
        {data &&
          <>
            <PaymentInfoBlock>
              <PaymentWalletInputBlock>
                <PaymentWalletLabelSpan>Address USDT (TRC-20)</PaymentWalletLabelSpan>
                <ReadableBasicInput inputValue={data.wallet}/>
              </PaymentWalletInputBlock>
              <DepositDescription>
                <DepositDescriptionSpan>Minimum deposit amount</DepositDescriptionSpan>
                <DepositDescriptionSpan>10 USDT</DepositDescriptionSpan>
              </DepositDescription>
            </PaymentInfoBlock>
            <PaymentWalletQRCode>
              <QRCode value={data.wallet} size={160}/>
            </PaymentWalletQRCode>
          </>
        }
        {isLoading &&
          <Loader center size={'md'} content="loading wallet..."/>
        }
        {isError &&
          <PaymentsWalletError>Error, please try again later</PaymentsWalletError>
        }
      </PaymentWalletBlock>
    </PaymentWalletModalWrapper>
  );
});

export default PaymentWalletModal;