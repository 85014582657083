import styled from "styled-components";
import {IThemeProvider} from "../../../../../../Theme/defaultTheme";

export const SettingsButtonBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
`;

export const SettingsButton = styled.button<IThemeProvider>`
  background-color: transparent;
  border: 0;

  width: 24px;
  height: 24px;
  object-fit: contain;

  padding: 0;

  color: ${props => props.theme.colors.grey};

  &:hover {
    color: ${props => props.theme.colors.black};
  }
`;