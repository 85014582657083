import styled from 'styled-components';
import {IThemeProvider} from '../../../Theme/defaultTheme';
import arrowDownIcon from '../../../Images/svg/arrows/arrow-down.svg';
import arrowUpIcon from '../../../Images/svg/arrows/arrow-up.svg';

interface IProps {
  'data-is-open': boolean;
}

export const SelectBlock = styled.div`
  position: relative;
  width: 418px;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    right: 1px;
    pointer-events: none;

    background-image: url(${arrowDownIcon});
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: 50% 50%;
    background-color: ${props => props.theme.colors.white};

    padding: 12px;
  }

  &[data-list-is-open='true'] {
    &::after {
      background-image: url(${arrowUpIcon});
    }
  }
`;

export const OptionBlock = styled.div<IThemeProvider & IProps>`
  display: none;
  overflow: hidden;

  position: absolute;
  top: calc(100% - 1px);
  z-index: 5;

  width: 100%;
  background-color: ${props => props.theme.colors.white};

  border-right: 1px solid ${props => props.theme.colors.grey};
  border-left: 1px solid ${props => props.theme.colors.grey};
  border-bottom: 1px solid ${props => props.theme.colors.grey};
  border-radius: 0 0 ${props => props.theme.format.main_md_border_radius} ${props => props.theme.format.main_md_border_radius};

  &[data-is-open='true'] {
    display: block;
  }

  &[data-is-focused='true'] {
    outline: 1.5px solid ${props => props.theme.colors.brand_blue};
    border: 1px solid transparent;
  }
`;

export const InputField = styled.input<IThemeProvider>`
  width: 100%;
  box-sizing: border-box;
  padding: ${props => props.theme.format.main_md_padding};
  background-color: transparent;
  border-radius: ${props => props.theme.format.main_md_border_radius};
  border: 1px solid ${props => props.theme.colors.grey_semi};

  font-family: ${props => props.theme.font_style.font_family};
  font-weight: ${props => props.theme.font_style.font_weight.regular};
  font-size: ${props => props.theme.font_sizes.content};
  color: ${props => props.theme.colors.black};

  &:focus-visible {
    outline: 1.5px solid ${props => props.theme.colors.brand_blue};
    border: 1px solid transparent;
  }

  &:disabled {
    color: ${props => props.theme.colors.grey};
  }

  &[data-is-open='true'] {
    border-radius: ${props => `${props.theme.format.main_md_border_radius} ${props.theme.format.main_md_border_radius} 0 0`};
  }
`;

export const OptionsList = styled.ul`
  padding: 0;
  margin: 0;

  list-style-type: none;
`;

export const OptionItem = styled.li<IThemeProvider>`
  font-family: ${props => props.theme.font_style.font_family};
  font-weight: ${props => props.theme.font_style.font_weight.regular};
  font-size: ${props => props.theme.font_sizes.content};

  padding: ${props => `${props.theme.format.main_sm_padding} ${props.theme.format.main_md_padding}`};

  &:hover {
    background-color: ${props => props.theme.colors.grey_lightest};
  }
`;