import styled from "styled-components";
import {BasicContentSpan} from "../../../../../../../Theme/basicElements";
import {IThemeProvider} from "../../../../../../../Theme/defaultTheme";

export const TotalWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const TotalBlock = styled.div`
  display: flex;
  gap: 64px;
`;

export const TotalItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  min-width: 142px;
`;

export const TotalValueSpan = styled(BasicContentSpan)`
  font-size: 24px;
`;

export const TotalValue = styled(BasicContentSpan)<IThemeProvider>`
  color: ${props => props.theme.colors.brand_blue};
  font-weight: ${props => props.theme.font_style.font_weight.bold};
  font-size: 24px;
`;
