import React from 'react';
import {OrdersTh, OrdersThead, OrdersTr} from "../../styled";
import SettingsTableButton from "../SettingsTableButton";
import {useAppSelector} from "../../../../../../Hooks/redux/useAppSelector";
import {COLUMNS_LIST} from "../../../../Constants/OrdersColumns";

interface IOrdersTableHeadProps {
  handleOpenSettingsModal: () => void
}

const OrdersTableHead: React.FC<IOrdersTableHeadProps> = ({handleOpenSettingsModal}) => {
  const selectedColumns = useAppSelector(state => state.myOrdersColumnsSlice);

  return (
    <OrdersThead>
      <OrdersTr>
        {Object.entries(selectedColumns).map(column => {
          const [colKey, isEnabled] = column;

          if (isEnabled) {
            return <OrdersTh key={colKey}>{COLUMNS_LIST.find((col) => col.key === colKey)?.label}</OrdersTh>
          }
        })}
        <OrdersTh></OrdersTh>
        <OrdersTh></OrdersTh>
        <OrdersTh><SettingsTableButton onClick={handleOpenSettingsModal}/></OrdersTh>
      </OrdersTr>
    </OrdersThead>
  );
};

export default OrdersTableHead;