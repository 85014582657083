import styled from 'styled-components';
import {BasicModalTitle, BasicModalWrapper} from '../../../../Theme/modalsElements';
import {BasicContentSpan} from '../../../../Theme/basicElements';
import {IThemeProvider} from '../../../../Theme/defaultTheme';

export const SubmitCampaignModal = styled(BasicModalWrapper)`
  max-width: 512px;
`;

export const SubmitCampaignModalTitle = styled(BasicModalTitle)`
  text-align: center;
`;

export const SubmitCampaignModalTextBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0 auto 64px;
`;

export const SubmitCampaignModalText = styled(BasicContentSpan)<IThemeProvider>`
  text-align: center;
  font-size: ${props => props.theme.font_sizes.large_content};
`;