import styled from 'styled-components';
import {BasicContentSpan} from '../../../../../../../Theme/basicElements';
import {IThemeProvider} from '../../../../../../../Theme/defaultTheme';

export const TasksBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TasksTableLabel = styled(BasicContentSpan)<IThemeProvider>`
  color: ${props => props.theme.colors.grey};
  width: 100%;
  display: block;
  padding: ${props => props.theme.format.main_sm_padding};
`;

export const TasksCheckboxBlock = styled.div<IThemeProvider>`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.format.main_sm_gap};
  padding: ${props => props.theme.format.main_sm_padding};
`;

export const TasksTableBlock = styled.div`
  display: flex;
  width: 100%;
  overflow-x: auto;
`;