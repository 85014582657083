import styled from 'styled-components';
import {BasicContentSpan} from '../../../../Theme/basicElements';
import {IThemeProvider} from '../../../../Theme/defaultTheme';

import arrowDownIcon from '../../../../Images/svg/arrows/arrow-down.svg';

export const FaqItemContainer = styled.li<IThemeProvider>`
  display: flex;
  flex-direction: column;

  border-radius: ${props => props.theme.format.main_md_border_radius};
  border: 1px solid ${props => props.theme.colors.grey_semi};
`;

export const FaqItemHeadingBlock = styled.div<IThemeProvider>`
  display: flex;
  justify-content: space-between;
  padding: ${props => props.theme.format.main_lg_padding};
  position: relative;
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    right: 24px;
    pointer-events: none;

    background-image: url(${arrowDownIcon});
    background-repeat: no-repeat;
    background-size: 27px;
    background-position: 50% 50%;
    background-color: ${props => props.theme.colors.white};

    padding: 12px;
  }

  &[data-open='true'] {
    &::after {
      transform: translateY(-50%) rotate(180deg);
    }
  }

`;

export const FaqItemTitle = styled(BasicContentSpan)<IThemeProvider>`
  font-weight: ${props => props.theme.font_style.font_weight.bold};
  font-size: ${props => props.theme.font_sizes.large_content};
`;

export const FaqItemContent = styled.div<IThemeProvider>`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.format.main_sm_gap};
`;


export const FaqItemText = styled.div<IThemeProvider>`
  padding: 0 ${props => `${props.theme.format.main_lg_padding}`};

  font-family: ${props => props.theme.font_style.font_family};
  font-size: ${props => props.theme.font_sizes.content};
`;

export const FaqItemGifBlock = styled.div<IThemeProvider>`
  padding: ${props => `${props.theme.format.main_md_padding} ${props.theme.format.main_lg_padding}`};

  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.format.main_sm_gap};
`;

export const FaqItemGifList = styled.div<IThemeProvider>`
  width: 100%;
  display: flex;
  gap: ${props => props.theme.format.main_sm_gap};
`;

export const FaqItemPreview = styled.div`
  width: 15%;
  cursor: pointer;

  & img {
    width: 100%;
    object-fit: contain;
  }
`;

export const FaqItemGifContainer = styled.div`
  width: 49%;
  min-width: 530px;

  & img {
    width: 100%;
    object-fit: contain;
  }
`;