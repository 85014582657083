import styled from "styled-components";
import {BasicModalTitle, BasicModalWrapper} from "../../../../../../../Theme/modalsElements";
import {BasicContentParagraph} from "../../../../../../../Theme/basicElements";

export const ArchiveModal = styled(BasicModalWrapper)`
  width: 512px;
`;

export const ArchiveModalTitle = styled(BasicModalTitle)`
  text-align: center;
`;

export const ArchiveModalText = styled(BasicContentParagraph)`
  text-align: center;
  margin-bottom: 48px;
`;

export const ArchiveModalButtonsBlock = styled.div`
  display: flex;
  gap: ${props => props.theme.format.main_lg_gap};
  width: 100%;
  justify-content: center;
`;