import addCampaignInstall from '../../../Images/Gifs/Add-Campaign-Install.gif';
import addCampaignRate from '../../../Images/Gifs/Add-Campaign-Rate.gif';
import addCampaignInstallPreview from '../../../Images/gifsPreview/AddCampaignKeywords.svg';
import addCampaignRatePreview from '../../../Images/gifsPreview/AddCampaignRates.svg';

import addApp from '../../../Images/Gifs/Add-App.gif';
import addAppPreview from '../../../Images/gifsPreview/AddApp.svg';

import addCountry from '../../../Images/Gifs/Add-Country.gif';
import addCountryPreview from '../../../Images/gifsPreview/AddCountry.svg';

import cancelCampaignActive from '../../../Images/Gifs/Cancel-campaign-active.gif';
import cancelCampaignActivePreview from '../../../Images/gifsPreview/CancelCampaignActive.svg';

import cancelCampaignPrepared from '../../../Images/Gifs/Cancel-campaign-prepared.gif';
import cancelCampaignPreparedPreview from '../../../Images/gifsPreview/CancelCampaignPrepared.svg';

import cloneCountry from '../../../Images/Gifs/Clone-country.gif';
import cloneCountryPreview from '../../../Images/gifsPreview/CloneCountry.svg';

import consoleVisibility from '../../../Images/Gifs/Console-Visibility.gif';
import consoleVisibilityPreview from '../../../Images/gifsPreview/ConsoleVisibility.svg';

import delayedStart from '../../../Images/Gifs/Delayed-start.gif';
import delayedStartPreview from '../../../Images/gifsPreview/DelayedStart.svg';

import importKeywords from '../../../Images/Gifs/Import-Keywords.gif';
import importKeywordsPreview from '../../../Images/gifsPreview/ImportKeywords.svg';

import installByPackage from '../../../Images/Gifs/Install-by-package.gif';
import installByPackagePreview from '../../../Images/gifsPreview/AddCampaignPackage.svg';

import launchApp from '../../../Images/Gifs/Launch-app.gif';
import launchAppPreview from '../../../Images/gifsPreview/LaunchApp.svg';

import ratioDeviation from '../../../Images/Gifs/Ratio-Deviation.gif';
import ratioDeviationPreview from '../../../Images/gifsPreview/RatioDeviation.svg';

import ratioFixedNumberMinus from '../../../Images/Gifs/Ratio-fixed-number-Minus.gif';
import ratioFixedNumberMinusPreview from '../../../Images/gifsPreview/RatioFixedNumberDecrease.svg';

import ratioFixedNumberPlus from '../../../Images/Gifs/Ratio-fixed-number-plus.gif';
import ratioFixedNumberPlusPreview from '../../../Images/gifsPreview/RatioFixedNumberIncrease.svg';

import ratioPercentMinus from '../../../Images/Gifs/Ratio-percent-minus.gif';
import ratioPercentMinusPreview from '../../../Images/gifsPreview/RatioPercentDecrease.svg';

import ratioPercentPlus from '../../../Images/Gifs/Ratio-percent-plus.gif';
import ratioPercentPlusPreview from '../../../Images/gifsPreview/RatioPercentIncrease.svg';

import statisticAppStatistics from '../../../Images/Gifs/Statistic-App-statistic.gif';
import statisticAppStatisticsPreview from '../../../Images/gifsPreview/StatisticAppStatistic.svg';

interface FAQGifs {
  gifUrl: string,
  previewUrl: string
}

export interface FAQQuestion {
  question: string;
  answer: string;
  gifs?: FAQGifs[];
}

export const FAQ_QUESTIONS: FAQQuestion[] = [
  {
    question: 'How to launch a campaign?',
    answer: 'To launch a campaign, make sure that:\n' +
      '1. The account is verified (when registering via email)\n' +
      '2. Sufficient funds are available in the balance\n' +
      '3. The promoted application has been added to the system.\n' +
      '\n' +
      'If all these points are met, you can launch the campaign in two ways:\n' +
      '1) By clicking the Promote button in the My apps section of the application card.\n' +
      '2) By clicking the Add campaign button in the My orders section.\n' +
      '\n' +
      'When creating a campaign in the My orders section:\n' +
      '1. Select the campaign type "Installs" or "Ratings".\n' +
      '2. Choose the application for promotion.\n' +
      '3. Specify the campaign period.\n' +
      '4. Add one or several countries for promotion.\n' +
      '5. Specify the desired country in the country settings block.\n' +
      '6. If the campaign type is "Installs", enter keywords into the Keywords field, each on a new line.\n' +
      '7. If the campaign type is "Ratings," select which ratings you want to receive (5 stars and/or 4 stars).\n' +
      '8. In the table that appears, specify the quantity of installs/ratings you wish to receive.\n' +
      '9. Launch the campaign.',
    gifs: [
      {
        gifUrl: addCampaignInstall,
        previewUrl: addCampaignInstallPreview
      },
      {
        gifUrl: addCampaignRate,
        previewUrl: addCampaignRatePreview
      }
    ]
  },
  {
    question: 'How to view statistics for past campaigns?',
    answer: 'You can view the main statistics on the My orders page.\n' +
      'Detailed statistics on completed installs and ratings are stored in the My apps section. To view it, click on the histogram icon in the personal application card.',
    gifs: [
      {
        gifUrl: statisticAppStatistics,
        previewUrl: statisticAppStatisticsPreview
      }
    ]
  },
  {
    question: 'How to add an application to the system?',
    answer: '1. Go to the My apps page.\n' +
      '2. Click on Add new app.\n' +
      '3. In the new window, provide a link to your application and specify any country where your application is available for download.\n' +
      '4. Click the Apply button.',
    gifs: [
      {
        gifUrl: addApp,
        previewUrl: addAppPreview
      }
    ]
  },
  {
    question: 'When adding an application, I need to specify only one country. Does this mean that I can only promote the application in that country?',
    answer: 'No. You can promote the application in any country where it is available for download.\n' +
      'Specifying a country when adding the application is only necessary for us to gather the required information about the application.'
  },
  {
    question: 'I see a referral link in my profile. What will I get if I use it?',
    answer: 'If you share the link with a friend or partner, you will start receiving a percentage of your partner\'s expenses as a balance, which will be credited to your account.'
  },
  {
    question: 'I have placed an order, but I now need to cancel it. What should I do?',
    answer: 'To cancel an order, simply click the ""Cancel"" button in the campaign row, but there are some restrictions:\n' +
      '\n' +
      'Orders scheduled for future dates can be easily canceled until the first day of the campaign. During this period, the order is queued and marked as ""Prepared."" In this case, all funds for the campaign are refunded to the balance.\n' +
      '\n' +
      'If the order is created for the current date:\n' +
      'Once you have created and launched the order, you have 5 minutes to cancel it and receive a full refund.\n' +
      '\n' +
      'If more than 5 minutes have passed since the order was launched:\n' +
      '- If the order is scheduled for one day, its status changes to ""Completed,"" and it cannot be stopped.\n' +
      '- If the order is scheduled for multiple days, its status changes to ""Active,"" and if canceled, only the first day of the order will be considered completed. Funds for the non-executed dates of the order will be refunded to the balance.\n' +
      '\n' +
      'Tip: Double-check the campaign details before launching it.',
    gifs: [
      {
        gifUrl: cancelCampaignActive,
        previewUrl: cancelCampaignActivePreview
      },
      {
        gifUrl: cancelCampaignPrepared,
        previewUrl: cancelCampaignPreparedPreview
      }
    ]
  },
  {
    question: 'Can I run campaigns for installs based on keywords and rates within a single campaign?',
    answer: 'No. If you need to run campaigns for installs based on keywords and rates, you will need to create two separate campaigns.'
  },
  {
    question: 'Can I create an order for one application in multiple countries within a single campaign?',
    answer: 'Certainly. Use the ""Add country"" button to add as many countries to the order as needed.\n' +
      'Additionally, if you plan to promote the same keywords in different countries, simply fill out the keyword table in the first country and use the ""Clone"" button to replicate them across other countries.',
    gifs: [
      {
        gifUrl: addCountry,
        previewUrl: addCountryPreview
      },
      {
        gifUrl: cloneCountry,
        previewUrl: cloneCountryPreview
      }
    ]
  },
  {
    question: 'I have a large table of keywords. I don\'t want to fill in all the cells manually. Is there any solution?',
    answer: 'Yes.\n' +
      '1. Method: If you have a ready-made table with keywords and the number of installs, you can upload it using the ""Import"" button in the campaign settings. Download the template, enter your data following the example, and import the completed file.\n' +
      '2. Method: You need to set the desired number of installs on the first day of the campaign. The number of installs for subsequent days will be automatically calculated based on the first day of the campaign.\n' +
      'If you need different values for each day of the order or want to set them in ascending/descending order, use the Ratio/Global Ratio function (histogram icon).\n' +
      '\n' +
      'Use:\n' +
      '- Percent to increase or decrease the number of installs by a percentage daily.\n' +
      '- Fixed number to increase or decrease the number of installs by a fixed amount daily.\n' +
      '- Deviation to adjust the deviation range of the number of installs compared to the first day of the order.\n' +
      '\n' +
      'Global Ratio adjusts all cells for all keywords.\n' +
      'Ratio adjusts settings for a specific individual keyword.',
    gifs: [
      {
        gifUrl: importKeywords,
        previewUrl: importKeywordsPreview
      },
      {
        gifUrl: ratioDeviation,
        previewUrl: ratioDeviationPreview
      },
      {
        gifUrl: ratioFixedNumberMinus,
        previewUrl: ratioFixedNumberMinusPreview
      },
      {
        gifUrl: ratioFixedNumberPlus,
        previewUrl: ratioFixedNumberPlusPreview
      },
      {
        gifUrl: ratioPercentMinus,
        previewUrl: ratioPercentMinusPreview
      },
      {
        gifUrl: ratioPercentPlus,
        previewUrl: ratioPercentPlusPreview
      }
    ]
  },
  {
    question: 'I want to extend the campaign by making some changes. How can I do that?',
    answer: 'To extend a campaign, click the Re | Ex button in the campaign row.\n' +
      '\n' +
      'Extend:\n' +
      'If you need to adjust keywords/install counts or add/remove promotion countries, use the Extend method. All other campaign settings will be locked. In this case, the new campaign will be launched for the same time period and with the same settings as the parent campaign. With the Extend method, you can also simply extend any campaigns that do not require changes.\n' +
      '\n' +
      'Important: Be careful if the parent campaign was set to increase/decrease install counts, as you will need to set this up again in the new campaign.\n' +
      '\n' +
      'Recreate:\n' +
      'If you need to adjust any campaign settings, use the Recreate method. You will have access to all core campaign settings except for the campaign type and application. Choose the necessary settings and specify the campaign period. For convenience, the first day of the new campaign will be filled with values from the last day of the parent campaign.\n' +
      '\n' +
      'Important: It is recommended to first select the period in the settings of the new campaign to immediately access keyword settings."'
  },
  {
    question: 'I accidentally canceled an active campaign, how do I run the remaining part of the unexecuted campaign?',
    answer: 'You cannot extend a stopped campaign. You can use the Recreate method and manually select the remaining period of the campaign and adjust the number of installations/rates. \n' +
      'Note that the day the campaign was stopped is considered executed."'
  },
  {
    question: 'Can I schedule campaign launches?',
    answer: 'Yes. To do this, you need to select the Delayed start checkbox in the campaign settings and specify the time for its launch. Note that for a full refund, campaigns launched in this way can only be canceled when they are in the Prepared status (first 5 minutes if the campaign is launched on the current date or all days before the order date). Otherwise, the day of canceling the campaign will be considered executed.',
    gifs: [
      {
        gifUrl: delayedStart,
        previewUrl: delayedStartPreview
      }
    ]
  },
  {
    question: 'Why don\'t I see installations in the Google Play Developer Console?',
    answer: 'By default, campaigns for Android apps are launched with installations without being displayed in the console. You can enable installation visibility by selecting the Console visibility checkbox in the campaign settings.\n' +
      'Note: This checkbox is only available for apps from the Google Play Store.',
    gifs: [
      {
        gifUrl: consoleVisibility,
        previewUrl: consoleVisibilityPreview
      }
    ]
  },
  {
    question: 'Can I launch campaigns with subsequent app openings?',
    answer: 'Yes, you can create campaigns with app launches and subsequent app openings for up to 14 days. Select the Launch App checkbox in the campaign settings and specify the desired number of app opens.\n' +
      'Please note that this option increases the cost of installation by $0.01 for each day the app is opened.\n' +
      'For more information about this option and associated costs, please consult your account manager.',
    gifs: [
      {
        gifUrl: launchApp,
        previewUrl: launchAppPreview
      }
    ]
  },
  {
    question: 'Can I expect a discount for large volumes of installations?',
    answer: 'Yes. There is a pricing grid in place. Please check with your account manager for conditions regarding reducing the cost of installations/rates.'
  },
  {
    question: 'Will my campaign stop if my app gets banned? Will the funds be returned to my balance?',
    answer: 'If your app is banned, the campaign will automatically stop. Funds for unexecuted installations will be refunded to your balance.\n' +
      'If your app is banned and the funds have not been returned to your balance, it means that the campaign has been successfully completed.\n' +
      'In all other cases, contact your account manager for clarification on details and the possibility of a recalculation.'
  },
  {
    question: 'I\'m spending a significant amount, but my keyword position isn\'t changing.',
    answer: 'First:\n' +
      '1. Check the spelling of the keyword. There may have been a typo when entered manually. It is preferable to use copy and paste.\n' +
      '2. Verify the promotion country.\n' +
      '3. Ensure that you have correctly assessed the competitiveness of the keyword. Often, the number of installations required to achieve results can vary significantly for the same keyword in different countries.\n' +
      '\n' +
      'If after following these steps you still have questions, contact your account manager.'
  },
  {
    question: 'Can I launch an order for installations using a direct link to the app?\n',
    answer: 'Yes. To do this, when placing an order, select the campaign type as "Installs" and check the box labeled "by package," located to the right of the field.',
    gifs: [
      {
        gifUrl: installByPackage,
        previewUrl: installByPackagePreview
      }
    ]
  },
  {
    question: 'Yesterday, I was setting up a campaign but decided not to launch it. Today, I want to start a new campaign, but the settings from the old one are still there. Reloading the window doesn\'t help—all fields remain filled in.',
    answer: 'That\'s correct. The campaign creation screen is cached intentionally for your convenience so that you don\'t lose progress (for example, in case of an internet connection loss).\n' +
      'Additionally, while creating a campaign on the My Orders page, you can start another campaign by going to the My Apps section and clicking the ""Promote"" button on the app card. \n' +
      'After that, you can return and continue creating the order on the My Orders page.\n' +
      '\n' +
      'If you need to clear all campaign settings, use the ""Clear"" button at the bottom of the screen.'
  },
  {
    question: 'Can I use the import function in campaigns to upload a file with rates?',
    answer: 'Currently, the file import function only works for campaigns with keywords.'
  },
  {
    question: 'I created an order, but when trying to launch it, I received an error stating that I cannot start it today.',
    answer: 'You may be trying to launch the campaign outside of working hours. All orders are automatically launched from our end, but queuing for installation is done manually. Our operators work from 1 AM to 3 PM UTC +0. If you want to launch the order at a different time, use the ""Delayed start"" option in the campaign settings.'
  },
];