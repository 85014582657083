import React, {useState} from 'react';
import {
  FaqItemContainer,
  FaqItemText,
  FaqItemHeadingBlock,
  FaqItemTitle,
  FaqItemContent,
  FaqItemGifBlock, FaqItemGifList, FaqItemGifContainer, FaqItemPreview
} from './styled';
import {FAQQuestion} from '../../Constants/FAQQuestions';
import {LazyLoadImage} from 'react-lazy-load-image-component';

interface IFaqItemProps {
  faqItem: FAQQuestion;
}

const FaqItem: React.FC<IFaqItemProps> = ({faqItem}) => {
  const [itemOpen, setItemOpen] = useState(false);
  const [playingGif, setPlayingGif] = useState<number | null>(null);

  function convertTextToHTML(text: string) {
    // Замените символы новой строки на тег <br>
    const textWithLineBreaks = text.replace(/\n/g, '<br>');
    // Замените двойные кавычки на тег <p>
    // const textWithParagraphs = textWithLineBreaks.replace(/\'(.*?)\'/g, '<p>$1</p>');
    // Замените точки с новой строкой на тег <li>
    // const textWithListItems = textWithParagraphs.replace(/(\d+\.)\s(.*?)(?=\n|$)/g, '<li>$2</li>');
    // // Замените двойные кавычки вокруг заголовков на тег <h3>
    // const textWithHeaders = textWithListItems.replace(/\'\'(.*?)\'\'/g, '<h3>$1</h3>');
    return {__html: textWithLineBreaks};
  }

  const handleOpenAnswer = () => {
    setItemOpen(!itemOpen);
  };
  const handleSetPlayingGif = (index: number) => {
    if (playingGif === null || playingGif !== index) {
      setPlayingGif(index);
    } else {
      setPlayingGif(null);
    }
  };

  return (
    <FaqItemContainer>
      <FaqItemHeadingBlock onClick={handleOpenAnswer} data-open={itemOpen}>
        <FaqItemTitle>{faqItem.question}</FaqItemTitle>
      </FaqItemHeadingBlock>

      {itemOpen &&
        <FaqItemContent>
          <FaqItemText dangerouslySetInnerHTML={convertTextToHTML(faqItem.answer)}/>
          <FaqItemGifBlock>
            <FaqItemGifList>
              {faqItem.gifs && faqItem.gifs.map((preview, index) =>
                <FaqItemPreview
                  key={preview.previewUrl}
                  onClick={() => handleSetPlayingGif(index)}
                >
                  <LazyLoadImage
                    src={preview.previewUrl}
                  />
                </FaqItemPreview>
              )}
            </FaqItemGifList>
            {faqItem.gifs && playingGif !== null &&
              <FaqItemGifContainer>
                <LazyLoadImage
                  
                  src={faqItem.gifs[playingGif].gifUrl}
                />
              </FaqItemGifContainer>}
          </FaqItemGifBlock>
        </FaqItemContent>}
    </FaqItemContainer>
  );
};

export default FaqItem;