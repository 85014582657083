import styled from 'styled-components';
import {IThemeProvider} from '../../../../../../Theme/defaultTheme';

export const FinancialTableBlock = styled.div`
  width: 1048px;
  font-family: ${props => props.theme.font_style.font_family};
  font-weight: ${props => props.theme.font_style.font_weight.regular};
  background-color: ${props => props.theme.colors.primary_light};
  padding: ${props => props.theme.format.main_lg_padding};
  border-radius: ${props => props.theme.format.main_lg_border_radius};
  box-sizing: border-box;

  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const FinancialTable = styled.table<IThemeProvider>`
  width: 100%;
`;

export const FinancialThead = styled.thead<IThemeProvider>`
`;

export const FinancialTbody = styled.tbody`
`;

export const FinancialEmptyBody = styled.div`
  width: 100%;
  min-height: 200px;
  position: relative;
`;

export const FinancialEmptyBodyText = styled.span<IThemeProvider>`
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;

  font-family: ${props => props.theme.font_style.font_family};
  font-size: 30px;
  color: ${props => props.theme.colors.grey};
  width: max-content;

  transform: translate(-50%, -50%);
`;

export const FinancialTr = styled.tr``;

export const FinancialTh = styled.th<IThemeProvider>`
  font-weight: ${props => props.theme.font_style.font_weight.regular};
  color: ${props => props.theme.colors.grey};
  text-align: left;
  padding: 0 8px 16px;
  border-bottom: 1px solid ${props => props.theme.colors.grey_semi};
`;

export const FinancialTd = styled.td`
  font-weight: ${props => props.theme.font_style.font_weight.regular};
  color: ${props => props.theme.colors.black};
  padding: 20px 8px;
  white-space: nowrap;
`;

export const FinancialButtons = styled.div`
  display: flex;
  gap: 16px;
  padding-top: 24px;
  width: 100%;
  border-top: 1px solid ${props => props.theme.colors.grey_semi};
`;