import React from 'react';
import {TasksBlock} from './styled';
import CountryKeywordsAdditionBlock from '../CountryKeywordsAdditionBlock';
import CountryTasksTableBlock from '../CountryTasksTableBlock';
import {ICountryModel, IKeyword} from '../../../../../Models/CountryModel';
import CountryTasksTotalBlock from '../CountryTasksTotalBlock';
import {getDatesListFromCountry, getKeysFromCountry} from '../../../../../Helpers/CreateOrderHelpers';
import {useAppSelector} from '../../../../../../../Hooks/redux/useAppSelector';
import CountryRatesAdditionBlock from '../CountryRatesAdditionBlock';
import CountryPackageAdditionBlock from '../CountryPackageAdditionBlock';

interface ICountryTasksBlockProps {
  handleRemoveCurrentCountry: () => void;
  country: ICountryModel;
  countryIndex: number;
  handleSetCountryKeywords: (keywords: IKeyword) => void;
  handleCloneCountry: () => void;
}

const CountryTasksBlock: React.FC<ICountryTasksBlockProps> = ({
                                                                handleRemoveCurrentCountry,
                                                                country,
                                                                handleSetCountryKeywords,
                                                                handleCloneCountry,
                                                                countryIndex
                                                              }) => {
  const {type} = useAppSelector(state => state.newOrderSlice);

  return (
    <TasksBlock>
      {type === 'install' &&
        <CountryKeywordsAdditionBlock
          handleRemoveCurrentCountry={handleRemoveCurrentCountry}
          country={country}
          handleSetCountryKeywords={handleSetCountryKeywords}
        />
      }
      {type === 'rate' &&
        <CountryRatesAdditionBlock
          handleRemoveCurrentCountry={handleRemoveCurrentCountry}
          country={country}
          handleSetCountryKeywords={handleSetCountryKeywords}
          countryIndex={countryIndex}
        />
      }
      {type === 'install_bp' &&
        <CountryPackageAdditionBlock
          handleRemoveCurrentCountry={handleRemoveCurrentCountry}
        />
      }
      {!!type && !!getDatesListFromCountry(country).length && !!getKeysFromCountry(country).length &&
        <>
          <CountryTasksTableBlock
            country={country}
            countryIndex={countryIndex}
            handleSetCountryKeywords={handleSetCountryKeywords}
            orderType={type}
          />
          <CountryTasksTotalBlock
            handleCloneCountry={handleCloneCountry}
            country={country}
          />
        </>
      }
    </TasksBlock>
  );
};

export default CountryTasksBlock;