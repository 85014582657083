import React, {useState} from 'react';
import {useAppSelector} from '../../Hooks/redux/useAppSelector';
import {BasicPageWrapper, BasicPageTitle, BasicBlockContent, BasicBlockItem} from '../../Theme/basicElements';
import {ProfileBlockItem} from './styled';
import BasicButton from '../../UI/BasicButton';
import {useNavigate} from 'react-router-dom';
import UserCostsTable from './Components/UserCostsTable';
import ReadableBasicInput from '../../UI/ReadableBasicInput';
import {Modal} from '@mui/material';
import ChangePasswordModal from './Modals/ChangePasswordModal';


const MyProfilePage = () => {
  const navigate = useNavigate();

  const dataUser = useAppSelector(state => state.clientDataSlice.user);

  const [openPasswordModal, setOpenPasswordModal] = useState(false);

  const handleAddMoney = () => {
    navigate('/finance');
  };

  const getEncryptedId = (id: number) => {
    return btoa(String(id));
  };

  const handleTogglePasswordModal = () => {
    setOpenPasswordModal(!openPasswordModal);
  };

  return (
    <BasicPageWrapper>
      <BasicPageTitle>My profile</BasicPageTitle>
      <ProfileBlockItem style={{gap: '59px'}}>
        <BasicBlockItem>Account name</BasicBlockItem>
        <BasicBlockContent>{dataUser?.name}</BasicBlockContent>
      </ProfileBlockItem>
      <ProfileBlockItem style={{gap: '32px'}}>
        <BasicBlockItem>Change password</BasicBlockItem>
        <BasicButton onClick={handleTogglePasswordModal} size={'md'} text="Change" variant="blue"
                     disabled={dataUser?.name?.includes('telegram')}></BasicButton>
      </ProfileBlockItem>
      <ProfileBlockItem style={{gap: '32px'}}>
        <ProfileBlockItem style={{gap: '111px'}}>
          <BasicBlockItem>Balance</BasicBlockItem>
          <BasicPageTitle>$ {dataUser?.balance?.toFixed(2)}</BasicPageTitle>
        </ProfileBlockItem>
        <BasicButton onClick={handleAddMoney} size={'md'} text="Add" variant="blue"></BasicButton>
      </ProfileBlockItem>
      <ProfileBlockItem style={{gap: '130px', alignItems: 'start'}}>
        <BasicBlockItem>Prices</BasicBlockItem>
        {dataUser &&
          <UserCostsTable userInfo={dataUser}/>
        }
      </ProfileBlockItem>
      <ProfileBlockItem style={{gap: '75px'}}>
        <BasicBlockItem>Referral Link</BasicBlockItem>
        <ReadableBasicInput
          inputValue={dataUser ? `${window.location.origin}/registration/${getEncryptedId(dataUser?.id)}` : ''}
          size={'md'}/>
      </ProfileBlockItem>
      <Modal open={openPasswordModal} onClose={handleTogglePasswordModal}>
        <ChangePasswordModal handleClose={handleTogglePasswordModal}/>
      </Modal>
    </BasicPageWrapper>
  );
};

export default MyProfilePage;