import React from 'react';
import {StatusButton, StatusList} from './styled';
import {useAppSelector} from '../../../../Hooks/redux/useAppSelector';
import {useActions} from '../../../../Hooks/redux/useActions';
import {ORDERS_STATUSES} from '../../Constants/OrdersStatuses';

const OrdersStatusSelector = () => {
  const selectedStatus = useAppSelector(state => state.myOrdersFiltersSlice.selectedStatus);

  const {setOrdersSelectedStatus} = useActions();

  return (
    <StatusList>
      {ORDERS_STATUSES.filter((status => status.number !== 7)).map((status) => (
        <StatusButton
          key={status.status}
          data-button-active={status.number === selectedStatus}
          onClick={() => {
            setOrdersSelectedStatus(status.number);
          }}
        >{status.title}</StatusButton>
      ))}
    </StatusList>
  );
};

export default OrdersStatusSelector;