import styled from 'styled-components';
import {IThemeProvider} from '../../../../../../../Theme/defaultTheme';
import CalendarIcon from '../../../../../../../Images/svg/calendar.svg';

export const DateSelectorBlock = styled.div<IThemeProvider>`
  display: flex;
  gap: 32px;

  position: relative;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 16px;
    height: 1px;

    left: 50%;
    top: 50%;

    transform: translateX(-50%);

    background-color: ${props => props.theme.colors.grey_semi};
  }
`;

export const DateSelectorInputContainer = styled.div`
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 12px;
    top: 10px;

    width: 20px;
    height: 20px;
    object-fit: contain;

    background-image: url(${CalendarIcon});
  }
`;

export const CustomDateSelectorInput = styled.input<IThemeProvider>`
  padding: ${props => `${props.theme.format.main_md_padding} ${props.theme.format.main_md_padding} ${props.theme.format.main_md_padding} 40px`};
  width: 193px;
  box-sizing: border-box;

  background-color: transparent;
  font-family: ${props => props.theme.font_style.font_family};
  font-weight: ${props => props.theme.font_style.font_weight.regular};
  font-size: ${props => props.theme.font_sizes.content};

  border-radius: ${props => props.theme.format.main_md_border_radius};
  border: 1px solid ${props => props.theme.colors.grey_semi};

  &:disabled {
    color: ${props => props.theme.colors.grey};
  }
`;