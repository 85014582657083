import styled from "styled-components";
import {BasicContentSpan} from "../../../../../../../Theme/basicElements";
import {IThemeProvider} from "../../../../../../../Theme/defaultTheme";

export const TasksTotalBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 112px;
`;

export const TasksTotalValueRow = styled.div`
  display: flex;
  gap: 16px;
`;

export const TasksTotalValue = styled(BasicContentSpan)<IThemeProvider>`
  color: ${props => props.theme.colors.brand_blue};
  font-weight: ${props => props.theme.font_style.font_weight.bold};
`;