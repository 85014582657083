import React from 'react';
import {TasksTotalBlock, TasksTotalValue, TasksTotalValueRow} from './styled';
import BasicButton from '../../../../../../../UI/BasicButton';
import {BasicContentSpan} from '../../../../../../../Theme/basicElements';
import {ICountryModel} from '../../../../../Models/CountryModel';
import {useAppSelector} from '../../../../../../../Hooks/redux/useAppSelector';
import {getTotalCurrentTypeName} from '../../../../../Helpers/CreateOrderHelpers';

interface ICountryTasksTotalBlockProps {
  handleCloneCountry: () => void;
  country: ICountryModel;
}

const CountryTasksTotalBlock: React.FC<ICountryTasksTotalBlockProps> = ({handleCloneCountry, country}) => {
  const {type} = useAppSelector(state => state.newOrderSlice);

  const getTotalValue = (countryItem: ICountryModel) => {
    return Object.values(countryItem.keywords).map((obj) => {
      const arr = Object.values(obj);
      const newArr = arr.map((num) => Number(num));
      return newArr.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    }).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  };

  return (
    <TasksTotalBlock>
      <BasicButton onClick={handleCloneCountry} size={'sm'} text={'Clone'} variant={'white'}
                   title={'Duplicate the settings of this country for the new country'}/>
      {!!type &&
        <TasksTotalValueRow>
          <BasicContentSpan>Total {getTotalCurrentTypeName(type)}:</BasicContentSpan>
          <TasksTotalValue>{getTotalValue(country)}</TasksTotalValue>
        </TasksTotalValueRow>
      }
    </TasksTotalBlock>
  );
};

export default CountryTasksTotalBlock;