import React from 'react';
import {StyledCampaignForm} from './styled';
import TypeSelectorRow from './Components/TypeSelectorRow';
import ApplicationSelectorRow from './Components/ApplicationSelectorRow';
import DateSelectorRow from './Components/DateSelectorRow';

interface ICreateCampaignFormViewProps {
  handleSetOrderType: (newType: string | null, prevType: string | null) => void;
  handleSetOrderApp: (app: number) => void;
  handleSetDateStart: (date: Date | null) => void;
  handleSetDateStop: (date: Date | null) => void;
}

const CreateCampaignFormView: React.FC<ICreateCampaignFormViewProps> = ({
                                                                          handleSetOrderType,
                                                                          handleSetOrderApp,
                                                                          handleSetDateStart,
                                                                          handleSetDateStop
                                                                        }) => {

  return (
    <StyledCampaignForm>
      <TypeSelectorRow handleSetOrderType={handleSetOrderType}/>
      <ApplicationSelectorRow handleSetOrderApp={handleSetOrderApp}/>
      <DateSelectorRow
        handleSetDateStart={handleSetDateStart}
        handleSetDateStop={handleSetDateStop}
      />
    </StyledCampaignForm>
  );
};

export default CreateCampaignFormView;