import React from 'react';
import {StyledIndicator} from './styled';

interface IAppCardStatusIndicator {
  status: number;
}

const AppCardStatusIndicator = ({status}: IAppCardStatusIndicator) => {
  return (
    <StyledIndicator title={status === 2 ? 'There are running campaigns' : 'No campaigns running'}>
      {status === 1 &&
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
          <rect y="0.471191" width="20" height="20" rx="10" fill="url(#paint0_radial_1289_14896)"/>
          <defs>
            <radialGradient id="paint0_radial_1289_14896" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(5.77316 5.37381) rotate(53.2759) scale(17.3503)">
              <stop stopColor="white"/>
              <stop offset="1" stopColor="#A7A7A7"/>
            </radialGradient>
          </defs>
        </svg>}
      {status === 2 && <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
        <rect y="0.979492" width="20" height="20" rx="10" fill="url(#paint0_radial_143_3591)"/>
        <defs>
          <radialGradient id="paint0_radial_143_3591" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                          gradientTransform="translate(5.77316 5.88211) rotate(53.2759) scale(17.3503)">
            <stop stopColor="#73D27C"/>
            <stop offset="1" stopColor="#23952F"/>
          </radialGradient>
        </defs>
      </svg>}
    </StyledIndicator>
  );
};

export default AppCardStatusIndicator;