import React, {useState} from 'react';
import {BasicPageTitle, BasicPageWrapper} from '../../Theme/basicElements';
import SearchInput from '../../Components/SearchInput';
import {AppsCardsList} from "./Modules/AppsCardsList";

const MyAppsPage = () => {
  const [appSearchValue, setAppSearchValue] = useState<string | null>(null);

  const handleSetAppSearchValue = (value: string | null) => {
    setAppSearchValue(value);
  };

  return (
    <BasicPageWrapper>
      <BasicPageTitle>My apps</BasicPageTitle>
      <SearchInput getInputValue={handleSetAppSearchValue} placeholder={'App search'} size={'lg'}/>
      <AppsCardsList appSearchValue={appSearchValue}/>
    </BasicPageWrapper>
  );
};

export default MyAppsPage;