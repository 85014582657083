interface IDaysAttributeList {
  title: string,
  value: string
}

export const DAYS_ATTRIBUTES: IDaysAttributeList[] = [
  {
    title: '1-st',
    value: 'la1day'
  },
  {
    title: '2-nd',
    value: 'la2day'
  },
  {
    title: '3-rd',
    value: 'la3day'
  },
  {
    title: '4-th',
    value: 'la4day'
  },
  {
    title: '5-th',
    value: 'la5day'
  },
  {
    title: '6-th',
    value: 'la6day'
  },
  {
    title: '7-th',
    value: 'la7day'
  },
  {
    title: '8-th',
    value: 'la8day'
  },
  {
    title: '9-th',
    value: 'la9day'
  },
  {
    title: '10-th',
    value: 'la10day'
  },
  {
    title: '11-th',
    value: 'la11day'
  },
  {
    title: '12-th',
    value: 'la12day'
  },
  {
    title: '13-th',
    value: 'la13day'
  },
  {
    title: '14-th',
    value: 'la14day'
  },
]