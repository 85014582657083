import React from 'react';
import {BasicPageTitle} from '../../Theme/basicElements';
import OrdersStatusSelector from './Modules/OrdersStatusSelector';
import OrdersFiltersModule from './Modules/OrdersFiltersModule';
import OrdersTableModule from './Modules/OrdersTableModule';
import {OrdersPageWrapper} from './styled';

const MyOrdersPage = () => {
  return (
    <OrdersPageWrapper>
      <BasicPageTitle>My orders</BasicPageTitle>
      <OrdersFiltersModule/>
      <OrdersStatusSelector/>
      <OrdersTableModule/>
    </OrdersPageWrapper>
  );
};

export default MyOrdersPage;