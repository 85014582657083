import styled from 'styled-components';
import {IThemeProvider} from '../../../../../Theme/defaultTheme';

export const AttributesBlock = styled.div<IThemeProvider>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${props => props.theme.format.main_md_gap};
`;

export const AttributeRow = styled.div<IThemeProvider>`
  display: flex;
  gap: ${props => props.theme.format.main_lg_gap};
  align-items: flex-start;
`;

export const AttributeRowHeading = styled.div`
  display: flex;
  gap: ${props => props.theme.format.main_md_gap};

  width: 195px;
  justify-content: right;
  align-items: center;
`;

export const AttributeRowLabel = styled.div<IThemeProvider>`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const AttributeRowTitle = styled.span<IThemeProvider>`
  font-family: ${props => props.theme.font_style.font_family};
  font-weight: ${props => props.theme.font_style.font_weight.regular};
  font-size: ${props => props.theme.font_sizes.content};
  color: ${props => props.theme.colors.black};

  &[data-is-disabled='true'] {
    color: ${props => props.theme.colors.grey};
  }
`;

export const AttributeBody = styled.div``;

