import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { IClientData } from '../../Models/ApiModels';

interface IClientState {
    user: IClientData | null;
}

const initialState: IClientState = {
    user: null,
};

const clientDataSlice = createSlice({
  name: 'clientData',
  initialState,
  reducers: {
    setData: (state, action :PayloadAction<IClientData>) => {
      state.user = action.payload
    }
  }
});

export const clientDataActions = clientDataSlice.actions;
export const clientDataReducer = clientDataSlice.reducer;