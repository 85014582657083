import styled from "styled-components";
import {IThemeProvider} from "../../../../../../../Theme/defaultTheme";

export const TasksBlock = styled.div<IThemeProvider>`
  width: 100%;
  background-color: ${props => props.theme.colors.primary_light};
  box-sizing: border-box;
  padding: ${props => props.theme.format.main_lg_padding};

  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.format.main_md_gap};
  border-radius: ${props => props.theme.format.main_lg_border_radius};
`;