import React, {useCallback, useEffect, useState} from 'react';
import {
  AttributeBody,
  AttributeRow,
  AttributeRowHeading,
  AttributeRowLabel,
  AttributeRowTitle,
  AttributesBlock
} from './styled';
import HelpButton from '../../../Components/HelpButton';
import BasicCheckboxButton from '../../../../../UI/BasicCheckboxButton';
import DaysListAttributes from './Components/DaysListAttributes';
import DelayStartAttribute from './Components/DelayStartAttribute';
import {ATTRIBUTES_VALUES} from './Constants/AttributesValues';
import {useAppSelector} from '../../../../../Hooks/redux/useAppSelector';
import {useGetAppsQuery} from '../../../../../Store/APIs/Apps.api';
import {IAppItem} from '../../../../../Models/ApiModels';
import {Modal} from '@mui/material';
import DelayAttentionModal from './Components/DelayAttentionModal';
import {getDataFromStorage} from '../../../../../Utils/localStorageHelpers';
import {useActions} from '../../../../../Hooks/redux/useActions';

interface ICreateCampaignAttributesViewProps {
  handleSetAttributesValue: (value: number) => void,
  handleSetDelayStartValue: (timeString: string | null) => void
}

const CreateCampaignAttributesView: React.FC<ICreateCampaignAttributesViewProps> = ({
                                                                                      handleSetAttributesValue,
                                                                                      handleSetDelayStartValue
                                                                                    }) => {
  const [selectedCheckboxesValues, setSelectedCheckboxesValues] = useState<string[]>([]);
  const [selectedAppInfo, setSelectedAppInfo] = useState<IAppItem | null>(null);

  const [attentionIsViewed, setAttentionIsViewed] = useState(false);

  const {setDelayedStartEnable} = useActions();

  const {
    app,
    attributesValue,
    delayedStartValue,
    activeScenario,
    countriesList,
    delayedStartEnable
  } = useAppSelector(state => state.newOrderSlice);
  const {user} = useAppSelector(state => state.clientDataSlice);

  const [openDelayAttentionModal, setOpenDelayAttentionModal] = useState(false);

  const {data: appsList = []} = useGetAppsQuery({search: '', limit: 999, offset: 0});

  useEffect(() => {
    if (appsList.length) {
      const currentAppInfo = appsList.find(application => application.id === app);

      if (currentAppInfo) {
        setSelectedAppInfo(currentAppInfo);
      }
    }
  }, [app, appsList]);

  const handleToggleDaysRow = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleSetSelectedCheckboxesValue(event);
    if (!event.target.checked) {
      let newArr: string[] = [...selectedCheckboxesValues];
      const filteredArr = newArr.filter((param) => !param.includes('day'));
      setCheckboxesValue(filteredArr);
    }
  };

  const padZero = (value: number): string => {
    return value.toString().padStart(2, '0');
  };

  const handleSetTimeString = (time: Date | null) => {
    if (time) {
      const hours = time.getHours();
      const minutes = time.getMinutes();

      console.log(`${padZero(hours)}:${padZero(minutes)} +00:00`);
      handleSetDelayStartValue(`${padZero(hours)}:${padZero(minutes)} +00:00`);
    } else {
      handleSetDelayStartValue(null);
    }
  };

  const checkingCountriesCount = (checked: boolean) => {
    //функция которая проверяет кол-во стран и статус отключения показа модалки предупреждения
    const disableModal = getDataFromStorage('disable_delay_modal');

    if (countriesList.length > 1 && !disableModal && checked && !attentionIsViewed) {
      setOpenDelayAttentionModal(true);
    }
  };

  useEffect(() => {
    checkingCountriesCount(delayedStartEnable);
  }, [countriesList, checkingCountriesCount]);

  const handleToggleDelay = (event: React.ChangeEvent<HTMLInputElement>) => {
    checkingCountriesCount(event.target.checked);

    setDelayedStartEnable(event.target.checked);

    if (!event.target.checked) {
      handleSetDelayStartValue(null);
    }
  };

  const handleCloseModal = () => {
    setOpenDelayAttentionModal(false);

    setAttentionIsViewed(true);
  };

  const getCheckboxesAttributesArray = useCallback(() => {
    //функция, которая парсит входящее битовое число в компонент в список параметров.
    const list: string[] = [];

    for (let attribute in ATTRIBUTES_VALUES) {
      if ((attributesValue & ATTRIBUTES_VALUES[attribute]) !== 0) {
        list.push(attribute);
      }
    }
    return list;
  }, [attributesValue]);

  const setCheckboxesValue = useCallback((attributesList: string[]) => {
    //функция, переводящая список параметров в битовое значение
    const valuesArr = attributesList.map(attribute => ATTRIBUTES_VALUES[attribute]);

    handleSetAttributesValue(valuesArr.reduce((accumulator, currentValue) => accumulator + currentValue, 0));
  }, [handleSetAttributesValue]);

  const handleSetSelectedCheckboxesValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    //функция, управляющая списком выбранных чекбоксов и отправка их в родителя в виде числа
    const newArr: string[] = [...selectedCheckboxesValues];

    if (event.target.checked) {
      newArr.push(event.target.value);
    } else {
      newArr.splice(newArr.indexOf(event.target.value), 1);
    }

    setCheckboxesValue(newArr);
  };

  useEffect(() => {
    setSelectedCheckboxesValues(getCheckboxesAttributesArray());
  }, [attributesValue]);

  useEffect(() => {
    if (selectedAppInfo && selectedAppInfo.url.includes('apple')) {
      const newArr: string[] = [...selectedCheckboxesValues];

      newArr.some((attribute) => attribute === 'consoleVisible') && newArr.splice(newArr.indexOf('consoleVisible'), 1);

      setCheckboxesValue(newArr);
    }
  }, [selectedAppInfo?.url]);

  return (
    <>
      <AttributesBlock>
        <AttributeRow>
          <AttributeRowHeading>
            <AttributeRowLabel>
              <AttributeRowTitle>Console visibility</AttributeRowTitle>
              <HelpButton
                popupText={'Select this option if you want the installation information to be displayed in the Google Play console'}/>
            </AttributeRowLabel>
            <BasicCheckboxButton
              name={'attributes'}
              value={'consoleVisible'}
              checked={!!selectedCheckboxesValues.find(attribute => attribute === 'consoleVisible')}
              onChange={handleSetSelectedCheckboxesValue}
              isDisabled={activeScenario === 'extend' || !selectedAppInfo || selectedAppInfo.url.includes('apple')}
            />
          </AttributeRowHeading>
        </AttributeRow>

        <AttributeRow>
          <AttributeRowHeading>
            <AttributeRowLabel>
              <AttributeRowTitle>Launch app</AttributeRowTitle>
              <HelpButton
                popupText={
                  user?.cost_relaunch ?
                    `Select the option if you want your application to run after installation and in the following days. Each launch of the application adds ${user?.cost_relaunch}$ to the installation cost.` :
                    'Select the option if you want your application to run after installation and in the following days.'
                }/>
            </AttributeRowLabel>
            <BasicCheckboxButton
              name={'attributes'}
              value={'day'}
              checked={!!selectedCheckboxesValues.find(attribute => attribute === 'day')}
              onChange={handleToggleDaysRow}
              isDisabled={activeScenario === 'extend'}
            />
          </AttributeRowHeading>
          <AttributeRowTitle
            data-is-disabled={!selectedCheckboxesValues.find(attribute => attribute === 'day') || activeScenario === 'extend'}>Day</AttributeRowTitle>
          <AttributeBody>
            <DaysListAttributes
              handleSetSelectedCheckboxesValue={handleSetSelectedCheckboxesValue}
              isDisabled={!selectedCheckboxesValues.find(attribute => attribute === 'day') || activeScenario === 'extend'}
              selectedCheckboxesValues={selectedCheckboxesValues}
            />
          </AttributeBody>
        </AttributeRow>

        <AttributeRow>
          <AttributeRowHeading>
            <AttributeRowLabel>
              <AttributeRowTitle>Distribute</AttributeRowTitle>
              <HelpButton
                popupText={'When this option is selected, installations will occur evenly throughout the day. If this option is not selected, installations are made as quickly as possible.'}/>
            </AttributeRowLabel>
            <BasicCheckboxButton
              name={'attributes'}
              value={'distribute'}
              checked={!!selectedCheckboxesValues.find(attribute => attribute === 'distribute')}
              onChange={handleSetSelectedCheckboxesValue}
              isDisabled={activeScenario === 'extend'}
            />
          </AttributeRowHeading>
          <AttributeRowTitle data-is-disabled={!selectedCheckboxesValues.find(attribute => attribute === 'distribute')}>
            Throughout the day
          </AttributeRowTitle>
        </AttributeRow>

        <AttributeRow>
          <AttributeRowHeading>
            <AttributeRowLabel>
              <AttributeRowTitle>Delayed start</AttributeRowTitle>
              <HelpButton popupText={'Specify the exact time when you need to start the campaign.'}/>
            </AttributeRowLabel>
            <BasicCheckboxButton
              name={'attributes'}
              value={'consoleVisibility'}
              checked={delayedStartEnable || !!delayedStartValue}
              onChange={handleToggleDelay}
              isDisabled={activeScenario === 'extend'}
            />
          </AttributeRowHeading>
          <AttributeRowTitle data-is-disabled={!delayedStartEnable && !delayedStartValue}>Set time</AttributeRowTitle>
          <AttributeBody>
            <DelayStartAttribute
              isDisabled={(!delayedStartEnable && !delayedStartValue) || activeScenario === 'extend'}
              handleSetTimeString={handleSetTimeString}
            />
          </AttributeBody>
          <AttributeRowTitle data-is-disabled={!delayedStartEnable && !delayedStartValue}>UTC +0</AttributeRowTitle>
        </AttributeRow>

      </AttributesBlock>
      <Modal open={openDelayAttentionModal} onClose={handleCloseModal}>
        <DelayAttentionModal onClose={handleCloseModal}/>
      </Modal>
    </>
  );
};

export default CreateCampaignAttributesView;