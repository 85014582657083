import React from 'react';
import {TotalBlock, TotalItem, TotalValue, TotalValueSpan, TotalWrapper} from './styled';
import BasicButton from '../../../../../../../UI/BasicButton';
import {useAppSelector} from '../../../../../../../Hooks/redux/useAppSelector';
import {useGetAppsQuery} from '../../../../../../../Store/APIs/Apps.api';
import {
  getTotalCostValue,
  getTotalCurrentTypeName,
  getTotalKeywordsCountFromCountries
} from '../../../../../Helpers/CreateOrderHelpers';

interface ICountriesTotalBlockProps {
  handleAddNewCountry: () => void;
}

const CountriesTotalBlock: React.FC<ICountriesTotalBlockProps> = ({handleAddNewCountry}) => {
  const {countriesList, app, type, attributesValue} = useAppSelector(state => state.newOrderSlice);
  const {user} = useAppSelector(state => state.clientDataSlice);

  const {data: appsList = []} = useGetAppsQuery({offset: 0, search: '', limit: 999});

  return (
    <TotalWrapper>
      <BasicButton onClick={handleAddNewCountry} size={'md'} text={'Add country'} variant={'blue'}/>
      <TotalBlock>
        {!!(app && type && user && appsList) &&
          <TotalItem>
            <TotalValueSpan>Campaign price:</TotalValueSpan>
            <TotalValue>{getTotalCostValue(app, type, user, appsList, countriesList, attributesValue)?.toFixed(2)} $</TotalValue>
          </TotalItem>
        }
        {!!(countriesList.length && type) &&
          <TotalItem>
            <TotalValueSpan>Total {getTotalCurrentTypeName(type)}:</TotalValueSpan>
            <TotalValue>{getTotalKeywordsCountFromCountries(countriesList)}</TotalValue>
          </TotalItem>
        }
      </TotalBlock>
    </TotalWrapper>
  );
};

export default CountriesTotalBlock;