import React from 'react';
import {ProgressBlock, ProgressPrepared, ProgressValue} from './styled';

interface ProgressItemProps {
  days: string,
  status: number
}

const ProgressItem: React.FC<ProgressItemProps> = React.memo(({days, status}) => {

  const getPercentDays = (): number => {
    const [pastDays, totalDays] = days?.split('/');

    return (Number(pastDays) / Number(totalDays)) * 100;
  };

  return (
    <ProgressBlock $percent={getPercentDays()} data-status={status}>
      {status === 1 && <ProgressPrepared/>}
      {status === 3 && <ProgressValue>100%</ProgressValue>}
      {status !== 1 && status !== 3 && <ProgressValue>{getPercentDays()?.toFixed(0)}%</ProgressValue>}
    </ProgressBlock>
  );
});

export default ProgressItem;