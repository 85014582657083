import styled from 'styled-components';
import {IThemeProvider} from '../../../../../../../Theme/defaultTheme';

export const DelayStartBodyWrapper = styled.div<IThemeProvider>`
  display: flex;
  gap: ${props => props.theme.format.main_md_gap};
`;

export const TimeZoneSelector = styled.select<IThemeProvider>`
  background-color: transparent;
  border: 1px solid ${props => props.theme.colors.grey_semi};
  border-radius: 4px;
  padding: 4px 7px;

  font-family: ${props => props.theme.font_style.font_family};
  font-size: ${props => props.theme.font_sizes.small};
  color: ${props => props.theme.colors.black};
  width: 150px;

  &:disabled {
    border: 1px solid ${props => props.theme.colors.grey_lightest};
    color: ${props => props.theme.colors.grey_semi};
  }
`;

export const TimeSelectorBlock = styled.div`
  position: relative;
`;

export const TimeSelectorWarning = styled.span<IThemeProvider>`
  position: absolute;
  display: block;

  bottom: -20px;
  left: 0;
  width: max-content;

  font-family: ${props => props.theme.font_style.font_family};
  font-size: ${props => props.theme.font_sizes.small};
  color: ${props => props.theme.colors.secondary_red};
`;

export const TimeSelectorInput = styled.input<IThemeProvider>`
  background-color: transparent;

  border: 1px solid ${props => props.theme.colors.grey_semi};
  border-radius: 4px;
  padding: 4px 7px;
  width: 150px;

  font-family: ${props => props.theme.font_style.font_family};
  font-size: ${props => props.theme.font_sizes.small};
  color: ${props => props.theme.colors.black};

  &[data-in-error='true'] {
    border: 1px solid ${props => props.theme.colors.secondary_red};

    &::placeholder {
      color: ${props => props.theme.colors.secondary_red};
    }
  }

  &:disabled {
    border: 1px solid ${props => props.theme.colors.grey_lightest};
    color: ${props => props.theme.colors.grey};

    &::placeholder {
      color: ${props => props.theme.colors.grey_semi};
    }
  }
`;

