import styled from 'styled-components';
import {BasicModalTitle, BasicModalWrapper} from '../../../../Theme/modalsElements';
import {BasicContentSpan} from '../../../../Theme/basicElements';
import {IThemeProvider} from '../../../../Theme/defaultTheme';

export const ImportModal = styled(BasicModalWrapper)`
  min-width: 718px;
`;

export const ImportModalTitle = styled(BasicModalTitle)`

`;

export const ImportModalList = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 4px;

  margin: 0 0 16px;
  padding: 0 0 0 20px;
`;

export const ImportModalListSpan = styled(BasicContentSpan)<IThemeProvider>`
  color: ${props => props.theme.colors.grey};
`;

export const ImportModalAttention = styled(BasicContentSpan)<IThemeProvider>`
  color: ${props => props.theme.colors.secondary_red};
`;

export const ImportModalItem = styled.li`
  font-family: ${props => props.theme.font_style.font_family};
  font-size: ${props => props.theme.font_sizes.content};
  font-weight: ${props => props.theme.font_style.font_weight.regular};
  color: ${props => props.theme.colors.grey};
`;

export const ImportModalButtonsBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;

  position: relative;
`;

export const UploadedFileBlock = styled.div`
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  align-items: center;
  gap: 8px;
  width: max-content;

  padding-right: 44px;
`;

export const UploadedFileError = styled(BasicContentSpan)<IThemeProvider>`
  color: ${props => props.theme.colors.secondary_red};

  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
`;


export const ImportModalInput = styled.input`
  visibility: hidden;
  position: absolute;
`;

export const ImportModalInputLabel = styled.label<IThemeProvider>`
  position: relative;

  font-family: ${props => props.theme.font_style.font_family};
  font-size: ${props => props.theme.font_sizes.content};
  font-weight: ${props => props.theme.font_style.font_weight.regular};
  border-radius: ${props => props.theme.format.small_border_radius};
  cursor: pointer;
  padding: 12px 24px;
  line-height: 120%;
  width: max-content;
  height: min-content;

  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.secondary_btn};
  border: 0;

  &:hover {
    background-color: ${props => props.theme.colors.brand_blue};
  }
`;

