import styled from 'styled-components';
import {BasicModalWrapper} from '../../../../../../Theme/modalsElements';
import {BasicContentSpan} from '../../../../../../Theme/basicElements';
import {IThemeProvider} from '../../../../../../Theme/defaultTheme';

export const PaymentWalletModalWrapper = styled(BasicModalWrapper)`
  width: 992px;
  gap: 16px;
`;

export const PaymentWalletBlock = styled.div<IThemeProvider>`
  display: flex;
  gap: ${props => props.theme.format.main_lg_gap};

  padding: ${props => props.theme.format.main_lg_padding};
  background-color: ${props => props.theme.colors.primary_light};

  border-radius: ${props => props.theme.format.main_lg_border_radius};
  min-height: 230px;
  position: relative;
`;

export const PaymentInfoBlock = styled.div<IThemeProvider>`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.format.main_md_gap};
  justify-content: space-between;
  width: 100%;
`;

export const PaymentWalletInputBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const PaymentWalletLabelSpan = styled(BasicContentSpan)<IThemeProvider>`
  color: ${props => props.theme.colors.grey};
  text-align: left;
`;

export const PaymentWalletQRCode = styled.div`
  padding: 16px;
  border-radius: 12px;
  background-color: ${props => props.theme.colors.white};
`;

export const DepositDescription = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DepositDescriptionSpan = styled(BasicContentSpan)`
  color: ${props => props.theme.colors.grey};
`;

export const PaymentsWalletError = styled(BasicContentSpan)<IThemeProvider>`
  font-size: 20px;
  display: block;
  position: absolute;
  color: ${props => props.theme.colors.grey};
  top: 50%;
  left: 50%;

  transform: translateX(-50%);
`;