import React from 'react';
import {FAQContainer, FAQList} from './styled';
import {BasicPageTitle} from '../../../../Theme/basicElements';
import {FAQ_QUESTIONS} from '../../Constants/FAQQuestions';
import FaqItem from '../../Components/FaqItem';

const FAQModule = () => {
  return (
    <FAQContainer>
      <BasicPageTitle>F.A.Q.</BasicPageTitle>
      <FAQList>
        {FAQ_QUESTIONS.map((question) =>
          <FaqItem faqItem={question} key={question.question}/>
        )}
      </FAQList>
    </FAQContainer>
  );
};

export default FAQModule;