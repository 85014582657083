import React from 'react';
import {
  BasicOrderFormRow,
  BasicOrderFormRowLabel,
  BasicOrderFormRowTitle,
  PackageTypeBlock
} from '../../../../../Styles/newOrdersBasics';
import HelpButton from '../../../../../Components/HelpButton';
import TypeSelector from '../../../../../../../Components/Selectors/TypeSelector';
import {useAppSelector} from '../../../../../../../Hooks/redux/useAppSelector';
import BasicCheckboxButton from '../../../../../../../UI/BasicCheckboxButton';
import {BasicContentSpan, BasicRedAccentSpan} from '../../../../../../../Theme/basicElements';

interface ITypeSelectorRowProps {
  handleSetOrderType: (newType: string | null, prevType: string | null) => void;
}

const TypeSelectorRow: React.FC<ITypeSelectorRowProps> = ({handleSetOrderType}) => {
  const {type, activeScenario} = useAppSelector(state => state.newOrderSlice);

  const handleSetPackageType = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      handleSetOrderType(event.target.value, type);
    } else {
      handleSetOrderType('install', type);
    }
  };

  return (
    <BasicOrderFormRow>
      <BasicOrderFormRowLabel>
        <BasicOrderFormRowTitle>Type <BasicRedAccentSpan>*</BasicRedAccentSpan></BasicOrderFormRowTitle>
        <HelpButton popupText={'Select the campaign type'}/>
      </BasicOrderFormRowLabel>
      <TypeSelector
        handleSelectItem={handleSetOrderType}
        selectedType={type}
        placeholder={'Select type'}
        disabled={activeScenario === 'duplicate' || activeScenario === 'extend'}/>
      {type?.includes('install') &&
        <PackageTypeBlock>
          <BasicCheckboxButton
            name={'package'}
            value={'install_bp'}
            checked={type === 'install_bp'}
            onChange={handleSetPackageType}
            isDisabled={activeScenario === 'duplicate' || activeScenario === 'extend'}
          />
          <BasicContentSpan>by package</BasicContentSpan>
          <HelpButton popupText={'Use this option if you want to create a campaign with direct link installations'}/>
        </PackageTypeBlock>
      }
    </BasicOrderFormRow>
  );
};

export default TypeSelectorRow;