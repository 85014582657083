import TetherIcon from '../../../Images/svg/PaymentsIcons/Tether_Logo.png';

interface IPaymentMethod {
  currency: string;
  title: string;
  icon: string;
}

export const ALL_PAYMENT_METHODS_LIST: IPaymentMethod[] = [
  {
    currency: 'USDT',
    title: 'Tether (TRC20)',
    icon: TetherIcon
  },
];
