import React from 'react';
import {
  KeywordsTable,
  KeywordsTBody,
  KeywordsTd,
  KeywordsTdInput,
  KeywordsTh,
  KeywordsTHead,
  KeywordsTr
} from './styled';
import {ICountryModel, IKeyword} from '../../../../../Models/CountryModel';
import {
  checkLengthOfKeywords,
  getDatesListFromCountry,
  getKeysFromCountry
} from '../../../../../Helpers/CreateOrderHelpers';

interface ICountryTasksKeywordsTableProps {
  country: ICountryModel;
  handleSetCountryKeywords: (keywords: IKeyword) => void;
  orderType: string;
  autofill: boolean;
}

const CountryTasksKeywordsTable: React.FC<ICountryTasksKeywordsTableProps> = ({
                                                                                country,
                                                                                handleSetCountryKeywords,
                                                                                orderType,
                                                                                autofill
                                                                              }) => {

  const handleFocusInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.select();
  };

  const handleChangeTableInput = (e: React.ChangeEvent<HTMLInputElement>, date: string, keyword: string, dateIndex: number) => {
    //обработчик ввода значений в поля в таблице
    const regex = /^[0-9\b]+$/;
    const value = e.target.value;

    if (regex.test(value) || value === '') {
      let newCountry = JSON.parse(JSON.stringify(country));
      newCountry.keywords[date][keyword] = Number(e.target.value);
      if (dateIndex === 0 && autofill) {
        Object.keys(country.keywords).map((date) =>
          newCountry.keywords[date][keyword] = Number(e.target.value)
        );
      }
      handleSetCountryKeywords(newCountry.keywords);
    }
  };

  const getTableTitle = (orderType: string) => {
    const orderTitles: { [key: string]: string } = {
      rate: 'Rates',
      install: 'Keywords',
      'install_bp': ''
    };

    return orderTitles[orderType];
  };

  return (
    <KeywordsTable>
      <KeywordsTHead>
        <KeywordsTr>
          <KeywordsTh data-role={'title'}>{getTableTitle(orderType)}</KeywordsTh>
          {checkLengthOfKeywords(country) && getDatesListFromCountry(country).map(date =>
            <KeywordsTh key={date}>{date}</KeywordsTh>
          )}
        </KeywordsTr>
      </KeywordsTHead>
      <KeywordsTBody>
        {checkLengthOfKeywords(country) && getKeysFromCountry(country)?.map((keyword, rowIndex) =>
          <KeywordsTr key={keyword}>
            <KeywordsTd data-role={'title'}
                        title={keyword}>{keyword === '#install_by_package#' ? 'by package' : keyword}</KeywordsTd>
            {getDatesListFromCountry(country).map((date, dateIndex) =>
              <KeywordsTd key={date}>
                <KeywordsTdInput
                  min={0}
                  value={Object.values(country.keywords[date])[rowIndex]}
                  onFocus={handleFocusInputValue}
                  onChange={(e) => {
                    handleChangeTableInput(e, date, keyword, dateIndex);
                  }}
                />
              </KeywordsTd>
            )}
          </KeywordsTr>
        )}
      </KeywordsTBody>
    </KeywordsTable>
  );
};

export default CountryTasksKeywordsTable;