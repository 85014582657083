import React from 'react';
import {BasicModalWrapper} from '../../../../../../Theme/modalsElements';
import {DeleteModalBtnBlock, DeleteModalText, DeleteModalTitle} from './styled';
import BasicButton from '../../../../../../UI/BasicButton';

interface IDeleteAppModalProps {
  onDelete: () => void,
  onClose: () => void,
  appName: string
}

const DeleteAppModal = React.forwardRef(({
                                           onDelete,
                                           onClose,
                                           appName
                                         }: IDeleteAppModalProps, ref: React.ForwardedRef<HTMLDivElement>) => {
  return (
    <BasicModalWrapper ref={ref} tabIndex={-1}>
      <DeleteModalTitle>Attention</DeleteModalTitle>
      <DeleteModalText>Are you sure you want to delete <b>{appName}</b>?</DeleteModalText>
      <DeleteModalBtnBlock>
        <BasicButton onClick={onDelete} size={'md'} text={'Delete'} variant={'red'}/>
        <BasicButton onClick={onClose} size={'md'} text={'Cancel'} variant={'blue'}/>
      </DeleteModalBtnBlock>
    </BasicModalWrapper>
  );
});

export default DeleteAppModal;