import {ICampaignInfo} from '../../../Models/ApiModels';
import {getDatesBetween} from '../../../Utils/DateUtils';
import {ICountryModel} from '../Models/CountryModel';
import moment from 'moment';

interface CampaignKeyword {
  id: number;
  campaign_id: number;
  keyword: string;
  date: string;
  value: number;
  country: string;
  cancel: number;
}

function CountriesOfKeywords(keywords: CampaignKeyword[]): string[] {
  let Countries: string[] = [];
  for (const keyword of keywords) {
    Countries.push(keyword.country);
  }
  return Array.from(new Set(Countries));
}


export function ParseDuplicateKeywords(PrepareCountries: ICampaignInfo, dateStart: string, dateStop: string): ICountryModel[] {
  let NewCountries = [];
  for (const country of CountriesOfKeywords(PrepareCountries.keywords)) {
    let NewCountry: ICountryModel = {
      geo: country,
      keywords: {}
    };
    for (const newDate of getDatesBetween(dateStart, dateStop)) {
      NewCountry.keywords[newDate] = {};
      const keywords = PrepareCountries.keywords.filter(key => {
        return key.country === country && key.date === PrepareCountries.date_stop;
      });
      for (const key of keywords) {
        if (newDate === moment(dateStart).format('YYYY-MM-DD')) {
          NewCountry.keywords[newDate][key.keyword] = key.value;
        } else {
          NewCountry.keywords[newDate][key.keyword] = 0;
        }
      }
    }
    NewCountries.push(NewCountry);
  }
  return NewCountries;
}

function getUniqueKey(data: CampaignKeyword[]): string[] {
  const values: string[] = [];
  for (let i = 0; i < data.length; i++) {
    const value = data[i].keyword;
    if (!values.includes(value)) {
      values.push(value);
    }
  }
  return values;
}

export const getDateListFromCampaignKeywords = (keywords: CampaignKeyword[]) => {
  //ТОЛЬКО ДЛЯ ПАРСИНГА С СЕРВЕРА
  const newDateArray: string[] = [];
  keywords.forEach((keyword) => {
    if (!newDateArray.find((date) => date === keyword.date)) {
      newDateArray.push(keyword.date);
    }
  });
  return newDateArray;
};

export function ParseExtendKeywords(PrepareCountries: ICampaignInfo, dateStart: string, dateStop: string) {
  let NewCountries = [];
  const NewDateArray = getDatesBetween(dateStart, dateStop);
  const OldDateArray = getDateListFromCampaignKeywords(PrepareCountries.keywords);

  for (const country of CountriesOfKeywords(PrepareCountries.keywords)) {
    let NewCountry: ICountryModel = {
      geo: country,
      keywords: {}
    };
    for (let i = 0; i < NewDateArray.length; i++) {
      if (Object.keys(NewCountry.keywords).length <= (PrepareCountries.keywords.filter(keyword => keyword.country === country)).length) {
        NewCountry.keywords[NewDateArray[i]] = {};
        const keywords = PrepareCountries.keywords.filter(key => {
          return key.country === country;
        });
        for (const key of getUniqueKey(keywords)) {
          if (NewCountry.keywords[NewDateArray[i]]) {
            NewCountry.keywords[NewDateArray[i]][key] = <number>keywords.filter(keyword => keyword.date === OldDateArray[i]).find(item => item.keyword === key)?.value;
          }
        }
      }

    }

    NewCountries.push(NewCountry);
  }
  return NewCountries;
}