import styled from 'styled-components';
import {BasicSmallSpan} from '../../../../../../Theme/basicElements';
import {IThemeProvider} from '../../../../../../Theme/defaultTheme';

interface IStatusItem {
  'data-status': number;
}

export const StyledText = styled(BasicSmallSpan)<IThemeProvider & IStatusItem>`
  display: block;
  padding-left: 14px;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;

    width: 6px;
    height: 6px;

    border-radius: 50%;

    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &[data-status='1'] {
    color: ${props => props.theme.colors.secondary_btn};

    &:before {
      background-color: ${props => props.theme.colors.secondary_btn};
    }
  }

  &[data-status='2'] {
    color: ${props => props.theme.colors.secondary_green_c};

    &:before {
      background-color: ${props => props.theme.colors.secondary_green_c};
    }
  }

  &[data-status='3'] {
    color: ${props => props.theme.colors.brand_chambray};

    &:before {
      background-color: ${props => props.theme.colors.brand_chambray};
    }
  }

  &[data-status='4'] {
    color: ${props => props.theme.colors.secondary_yellow_a};

    &:before {
      background-color: ${props => props.theme.colors.secondary_yellow_a};
    }
  }

  &[data-status='5'] {
    color: ${props => props.theme.colors.secondary_red};

    &:before {
      background-color: ${props => props.theme.colors.secondary_red};
    }
  }

  &[data-status='6'] {
    color: ${props => props.theme.colors.secondary_blue_a};

    &:before {
      background-color: ${props => props.theme.colors.secondary_blue_a};
    }
  }

  &[data-status='7'] {
    color: ${props => props.theme.colors.secondary_pink};

    &:before {
      background-color: ${props => props.theme.colors.secondary_pink};
    }
  }
`;